import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { AudioService } from '@app/_services/audio.service';
import * as Tone from 'tone';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-metronome',
  templateUrl: './metronome.component.html',
  styleUrls: ['metronome.component.css']
})
export class MetronomeComponent implements OnInit {
  isPlaying: boolean = false;
  showMetronome: boolean = false;
  enableMetronome: boolean = false;


  minBPM: number = 30;
  maxBPM: number = 240;
  currentBPM: number = 60;
  metronomeChannel: Tone.Channel = undefined;
  metronomePlayer: Tone.Player = undefined;
  tapTime0: number = 0;
  tapTime1: number = 0;
  tapTime2: number = 0;
  tapTime3: number = 0;
  nextTick;

  constructor(private audioService: AudioService) {}

  ngOnInit() :void {}

  openMetronome(){
    this.audioService.startAudioContext();
    //prepare metronome
    this.metronomeChannel = new Tone.Channel().toDestination();
    this.metronomePlayer = new Tone.Player("/assets/sounds/tick1.mp3").connect(this.metronomeChannel);
    this.isPlaying=false;
    this.showMetronome=true;
  }

  closeMetronome(){
    clearTimeout(this.nextTick);
    this.showMetronome=false;
    this.metronomeChannel.dispose();
    this.metronomePlayer.dispose();
  }

  setMetronomeVolume(vol: number){
    this.metronomePlayer.volume.value=vol;
  }
  
  tick(){
    this.metronomePlayer.start(0);
    //blink
    let blinker = document.getElementById("metronome-blinker");
    blinker.classList.remove("rythm-blink")
    blinker.offsetWidth
    blinker.classList.add("rythm-blink")
    if(this.isPlaying)
      this.nextTick = setTimeout(()=>this.tick(),((60/this.currentBPM)*1000));
  }

  play(event: MouseEvent){
    this.isPlaying=true;
    this.tick();
  }

  stop(){
    this.isPlaying=false;
  }

  tapTempo(){
    this.tapTime3=this.tapTime2;
    this.tapTime2=this.tapTime1;
    this.tapTime1=this.tapTime0;
    this.tapTime0=(new Date().getTime());

    if((this.tapTime0 - this.tapTime1) > 2000) return;
    let avgMilis = (this.tapTime0 - this.tapTime3)/3;
    this.currentBPM = Math.round(60000/avgMilis);
    if(this.currentBPM < this.minBPM) this.currentBPM=this.minBPM;
    if(this.currentBPM > this.maxBPM) this.currentBPM=this.maxBPM;
    clearTimeout(this.nextTick);
    this.tick();
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    this.audioService.clean();
    this.metronomeChannel.dispose();
    this.metronomePlayer.dispose();
  }

}
