<div class="center-container">
  <h2 class="text-center">Artykuły</h2>
  <div *ngIf="userAccount" class="mb-8">
    <div *ngIf="!articleCreation" class="ch-btn ch-btn-filled ch-btn-sec" (click)="articleCreation=true">Create new</div>
    <div *ngIf="articleCreation">
      <input type="text" class="choiry-input" id="new-article-title" (input)="validationService.updateName('new-article-title');" value="" placeholder="New title" (keyup.enter)="createArticle()"/>
      <div class="ch-btn ch-btn-outline ch-btn-sec mx-2" (click)="articleCreation=false">Anuluj</div>
      <div class="ch-btn ch-btn-filled ch-btn-sec mx-2" (click)="createArticle()">Dodaj</div>
      <div class="choiry-input-error ml-4" id="validation-feedback-new-article-title"></div>
    </div>
  </div>
  <ul class="ch-list">
    <li *ngFor="let article of articles">
      <div class="flex ch-list-item">
        <div class="cursor-pointer flex-1 p-2" [routerLink]="['/article',article.id]"><strong>{{article.title}}</strong></div>
        <div class="flex-none p-2">
          <span *ngIf="article.published" class="icon icon-24">public</span>
          <span *ngIf="!article.published" class="icon icon-24">public_off</span>
        </div>
        <div class="flex-none p-2"[routerLink]="['/edit/article',article.id]"><span class="icon-btn icon-24">edit</span></div>
        <div class="flex-none p-2"(click)="deleteArticle(article.id)"><span class="icon-btn icon-24">delete</span></div>
      </div>
    </li>
  </ul>
</div>