import { Component,  OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthServerService } from '../_services';
import { AlertService } from '@app/_services';


@Component({
  selector: 'emailverify',
  template: '<h2>Weryfikacja konta...</h2>'
})
export class EmailVerifyComponent implements OnInit {
  constructor(private route: ActivatedRoute,
      private router: Router,
      private authServerService: AuthServerService,
      private alertService: AlertService
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.authServerService.reqEmailVerify(params['code']).subscribe(()=>{
        this.alertService.success("Twoje konto zostało poprawnie aktywowane. Teraz możesz się zalogować",{keepAfterRouteChange: true})
        this.router.navigate(['/login'])
      },()=> this.alertService.error("Nie można aktywować konta. Link do aktywacji ważny jest przez godzinę"));
    });
  }
}
