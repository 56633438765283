import { User } from '.';
import {Track} from './track';

export class Piece{
    id: number;
    title: string;
    ownerId: string;
    creationDate: number;
    notes: string;
    haveScore: boolean;
    scoreFileSize: number;
    sharePublicEnabled: boolean;
    commentBlockId: number;
    tracks: Track[];
    urlScore: any = null;             //for player only
    tempo: number;
    metronomeChannel: any;      //for player only
    metronomePlayer: any;       //for player only
    metronome: boolean;
    owner: User;
    ordinalNumber: number;      //for playlist ordering
    views: number;
}