import { APP_INITIALIZER, NgModule }      from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { ReactiveFormsModule }    from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent }  from './app.component';
import { routing }        from './app.routing';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PdfViewerModule } from 'ng2-pdf-viewer';


import { AlertComponent, AvatarComponent} from './_global';
import { CustomInterceptor, FileDragNDropDirective} from './_utility/';
import { HomeComponent } from './home';
//PLAYLIST
import { PlaylistComponent } from './playlist';
import { ListPlaylistComponent } from './list/playlist';
//USERGROUP
import { UserGroupComponent } from './usergroup';
import { ListUserGroupComponent } from './list/usergroup';
//BLOG
import { ArticleComponent } from './article';
import { ListArticleComponent } from './list/article';
import { EditArticleComponent } from './edit/article';
//PIECE
import { PieceComponent } from './piece';
import { ListPieceComponent } from './list/piece';
import { AppFooterComponent, AppMenuComponent, AppNavbarComponent } from "./layout";
import { ListSharingComponent } from './list/sharing';
//COMMENT
import { CommentBlockComponent } from './commentblock';
//SETTINGS
import { SettingsComponent } from './settings';

import { ClipboardModule } from 'ngx-clipboard';
import { CookiesComponent } from './cookies/cookies.component';
import { SharingModalComponent } from './modals/sharingmodal/sharingmodal.component';
import { PlayerComponent } from './player';
import { PurchaseNewComponent, PurchaseSummaryComponent, PurchaseStatusComponent, PurchaseAboutComponent } from './purchase';
import { RegisterComponent } from './authserver/register';
import { LoginComponent } from './authserver/login';
import { EmailVerifyComponent } from './authserver/emailverify/emailverify.component';
import { IntervalRecognitionComponent} from './excercises/intervalrecognition/intervalrecognition.component'
import { ScoreComponent } from './_global/score/score.component';
import { RythmicDictationComponent } from './excercises/rythmicdictation/rythmicdictation.component';
import { PasswordResetComponent } from './authserver/passwordreset';
import { RedirectComponent } from './authserver/redirect/redirect.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { PasswordForgottenComponent } from './authserver/passwordforgotten';
//TOOLS
import { PianoComponent } from './tools/piano/piano.component';
import { MetronomeComponent } from './tools/metronome/metronome.component';
import { AvatarModalComponent } from './modals/avatarmodal/avatarmodal.component';
import { MobileMenuModalComponent } from './modals/mobilemenu/mobilemenumodal.component';
import { NewsletterFormComponent, NewsletterSubscribeComponent, NewsletterUnsubscribeComponent } from './authserver/newsletter';
import { AppMenuNotificationComponent } from './layout/menunotification.component';
import { AppMenuUserAccountComponent } from './layout/menuuseraccount.component';
import { ClickOutsideDirective } from './_utility/clickoutside.directive';
import { AppDropdownFilterComponent } from './layout/dropdownfilter.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { ConfirmModalComponent } from './modals/confirmmodal/confirmmodal.component';
import { SubscriptionEndComponent } from './help';

@NgModule({
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonModule,
        routing,
        DragDropModule,
        PdfViewerModule,
        ClipboardModule,
        ImageCropperModule
    ],
    declarations: [
        AppComponent,
        AlertComponent,
        ScoreComponent,
        AvatarComponent,
        EmailVerifyComponent,
        PlayerComponent,
        RedirectComponent,
        //STATIC
        HomeComponent,
        //LAYOUT
        AppFooterComponent,
        AppNavbarComponent,
        AppMenuComponent,
        AppMenuNotificationComponent,
        AppMenuUserAccountComponent,
        AppDropdownFilterComponent,
        CookiesComponent,
        //MODALS
        SharingModalComponent,
        AvatarModalComponent,
        MobileMenuModalComponent,
        ConfirmModalComponent,
        //PLAYLIST
        PlaylistComponent,
        ListPlaylistComponent,
        //USERGROUP
        UserGroupComponent,
        ListUserGroupComponent,
        //ARTICLE
        ArticleComponent,
        ListArticleComponent,
        EditArticleComponent,
        //PIECE
        PieceComponent,
        ListPieceComponent,
        //SHARE
        ListSharingComponent,
        //COMMENT
        CommentBlockComponent,
        //SETTINGS
        SettingsComponent,
        PurchaseNewComponent,
        PurchaseSummaryComponent,
        PurchaseAboutComponent,
        RegisterComponent,
        PasswordResetComponent,
        PasswordForgottenComponent,
        LoginComponent,
        //SUBSCRIPTION
        SubscriptionComponent,
        //NEWSLETTER
        NewsletterFormComponent,
        NewsletterSubscribeComponent,
        NewsletterUnsubscribeComponent,
        //EXCERCISES
        IntervalRecognitionComponent,
        RythmicDictationComponent,
        //TOOLS
        PianoComponent,
        MetronomeComponent,
        //HELP
        SubscriptionEndComponent,
        //DIRECTIVES
        ClickOutsideDirective,
        FileDragNDropDirective
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})

export class AppModule {}