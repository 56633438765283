import {User} from './user';

export class UserGroup {
    id: number;
    name: string;
    description: string;
    ownerId: string;
    owner: User;
    creationDate: number;
    users: User[];
    haveAvatar: boolean;
    //generated
    url: string;

}