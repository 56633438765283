import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PieceService, FileUploadService, TrackService, AlertService, ValidationService } from '@app/_services';
import { FileUpload, Piece, Track } from '@app/_models';
import { DomSanitizer, SafeResourceUrl, SafeUrl, Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import * as Tone from 'tone';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { AudioService } from '@app/_services/audio.service';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';

declare var MediaRecorder: any;

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.less']
})
export class PlayerComponent implements OnInit {
  @Input() paramId: number;
  @Input() paramLinkKey: string | undefined;
  @Input() paramEmbedded: boolean;
  @Input() hideScore: boolean = false;
  @Input() hideComments: boolean = false;
  id: number;
  linkKey: string | undefined;
  private sub: any;
  userAccount: Account;
  isSubscriptionActive: boolean=false;
  isEditor: boolean = false;
  piece: Piece;
  showNotes: boolean = false;
  infiniteLoop: any;
  timelineZoomLevels: number[] = [5,10,25,50,100];  // pixels per second
  currentZoomLevel: number =3;
  timelineLength: number = 0; // in seconds
  metronomeEditing: boolean = false;
  titleEditing: boolean = false;
  notesEditing: boolean = false;
  isLoopToggled: boolean = false;
  currentScorePage: number = 1;
  scorePages: number = 0;
  isTrimmingTrackLeft: boolean = false;
  isTrimmingTrackRight: boolean = false;
  isDraggingTrack: boolean = false;
  movingTrack: Track;
  movingStartX: number;
  cantLoad: boolean = false;


  //TODO REPLACE BY CSS VARIABLES
  colors: any = {"primary": "#7274D4",
          "primary_content": "#FFFFFF",
          "secondary": "#FFC567",
          "secondary_content": "#2A2828",
          "neutral": "#646262",
          "neutral_content": "#FFFFFF",
          "grey_900": "#2A2828",
          "grey_50": "#F0EFEF"
          };
//COPIED FROM TAILWIND FOR USE IN CANVAS
  toneCompressor: Tone.Compressor;
  isRecording: boolean = false;
  mic : Tone.UserMedia;
  micMeter : any;
  micMeterValue : number;
  recorder : MediaRecorder;
  chunks : any = [];

  scoreUpload: FileUpload;
  trackUploads: FileUpload[] = [];

    constructor(private router: Router,
      private route: ActivatedRoute,
      private pieceService: PieceService,
      public trackService: TrackService,
      public validationService: ValidationService,
      private sanitizer: DomSanitizer,
      public fileUploadService: FileUploadService,
      private alertService: AlertService,
      private accountService: AccountService,
      private audioService: AudioService,
      private titleService: Title
      ) {
        this.accountService.account.subscribe(x => this.userAccount = x);
      }

    ngOnInit() {
        //this.audioService.startAudioContext();
        this.scoreUpload = new FileUpload;
        this.id = this.paramId;
        this.linkKey = this.paramLinkKey;
        this.toneCompressor = new Tone.Compressor(-30,3).toDestination();

        //record prepare
        const dest  = Tone.context.createMediaStreamDestination();
        this.mic = new Tone.UserMedia();
        this.micMeter = new Tone.Meter();
        this.recorder = new MediaRecorder(dest.stream);
        this.mic.connect(dest);
        this.mic.connect(this.micMeter);
        setInterval(() => {this.micMeterValue = 100 + (this.micMeter.getValue()*2); if(this.micMeterValue<0) this.micMeterValue=0;},50);
        this.recorder.ondataavailable = evt => this.chunks.push(evt.data);
        this.recorder.onstop = evt => {
          let blob = new Blob(this.chunks, { type: 'audio/ogg; codecs=opus' });
          this.uploadRecordedFile(new File([blob],"recorded"));
          this.chunks = [];
        };

        //load the details
        this.pieceService.getById(this.id,this.linkKey).subscribe(x => {
          this.piece = x;
          this.titleService.setTitle(this.piece.title);
          //prepare metronome
          this.piece.metronomeChannel = new Tone.Channel().connect(this.toneCompressor);
          this.piece.metronomePlayer = new Tone.Player("/assets/sounds/tick1.mp3").connect(this.piece.metronomeChannel);
          Tone.Transport.bpm.value = this.piece.tempo;
          
          for(let track of this.piece.tracks)
            this.loadTrackFile(track);
          this.loadScoreFile();
          if(!this.paramEmbedded && this.userAccount)
            this.isEditor=(this.piece.ownerId===this.userAccount.userId);
        },err => {this.cantLoad=true;});
        this.infiniteLoop = window.setInterval(() => this.updatePlayerHead(), 10);
    }

    prepareTick(){
      this.piece.metronomeChannel.mute = !this.piece.metronome;
      this.piece.metronomePlayer.unsync();
      let metronomeTime = Math.max(this.timelineLength,60);
      this.audioService.scheduleMetronome(this.piece.metronomePlayer,metronomeTime);
    }

    makePlayer(track: Track) {
      track.meter = new Tone.Meter();
			track.channel = new Tone.Channel().connect(this.toneCompressor);
      track.limiter = new Tone.Limiter(-12).connect(track.channel);
			track.player = new Tone.Player(track.url, () => {this.generateWaveform(track);});
			track.player.connect(track.limiter);
      track.player.connect(track.meter);
      setInterval(() => {track.meterValue = 100 + (track.meter.getValue()*2); if(track.meterValue<0) track.meterValue=0;},50);
      track.channel.volume.value = track.volumeDb;
      track.channel.solo = track.solo;
      track.channel.mute = track.mute;
      this.rescheduleTransport();
		}

    timelineScale(): number{
      return this.timelineZoomLevels[this.currentZoomLevel];
    }

    setScorePageNumber(pdf) { 
      this.scorePages = pdf.numPages; 
    }

    generateWaveform(track: Track){

      var canvas = document.createElement('canvas');
      var waveContext = canvas.getContext("2d");
      var canvasHeight = 96;
      var canvasWidth = Math.floor(track.player.buffer.duration*this.timelineScale());
      var values = track.player.buffer.getChannelData(0);
      var sampleStep= Math.floor(values.length/canvasWidth);
      var sampleSubstep= Math.floor(sampleStep/10);
      waveContext.canvas.width = canvasWidth;
      waveContext.canvas.height = canvasHeight;
      waveContext.strokeStyle = "black";
      waveContext.clearRect(0, 0, canvasWidth, canvasHeight);
      waveContext.beginPath();
      for (var i = 0, len = values.length; i < len-sampleStep; i+=sampleStep) {
        var avgVal =0;
        for (var j = 0;j<10;j++)
          avgVal+=Math.abs(values[i+j*sampleSubstep]);
        avgVal /=10;
        avgVal = Math.log10(avgVal+0.1)+1;  //funkcja nieliniowa
        waveContext.moveTo(i/sampleStep, -(avgVal*canvasHeight*0.95/2)+(canvasHeight/2));
        waveContext.lineTo(i/sampleStep, avgVal*canvasHeight*0.95/2+(canvasHeight/2));
      }
      waveContext.closePath();
      waveContext.stroke();
      canvas.style.marginRight = (Math.floor((-track.trimRight*this.timelineScale()))).toString()+"px";
      canvas.style.marginLeft = (Math.floor((-track.trimLeft*this.timelineScale()))).toString()+"px";
      document.getElementById("track-waveform-"+track.id).style.marginLeft = (Math.floor(track.offset*this.timelineScale())).toString()+"px";
      document.getElementById("track-waveform-content-"+track.id).innerHTML="";
      document.getElementById("track-waveform-content-"+track.id).appendChild(canvas);
      this.updateGrid();
    }


    /*data managing*/
    addTrack(id : number){
      this.trackService.getById(id,this.linkKey).subscribe(x => {this.loadTrackFile(x); this.piece.tracks.push(x);});
      this.trackUploads.forEach((trackUpload,index)=>{
        if(trackUpload.fileId==id) {this.trackUploads.splice(index,1);}});
    }
    
    recordTrack(){
      this.isRecording = !this.isRecording;
      if(this.isRecording){
        this.mic.open();
        this.play();
        this.recorder.start();
      } else {
        this.mic.close();
        this.recorder.stop();
        this.pause();
      }
    }
  
    /*UI handlers*/

    openSharingModal(id: string) {
      document.getElementById("piece-sharing").style.display = 'block';
      //document.body..    overflow: hidden;
    }

    dragTrack(e: any, track: Track) {
      if(!this.isEditor)
        return;
      this.movingStartX = e.clientX;
      this.movingTrack = track;
      this.isDraggingTrack = true;
    }

    trimTrackRight(e: any, track: Track){
      if(!this.isEditor)
        return;
      this.movingStartX = e.clientX;
      this.movingTrack = track;
      this.isTrimmingTrackRight = true;
    }

    trimTrackLeft(e: any, track: Track){
      if(!this.isEditor)
        return;
      this.movingStartX = e.clientX;
      this.movingTrack = track;
      this.isTrimmingTrackLeft = true;
    }

    trimDragTrackUpdate(e: any){
      if(this.isTrimmingTrackRight){
        var timeMargin=this.movingTrack.trimRight+((this.movingStartX -e.clientX)/this.timelineScale());
        if(timeMargin<0)
          timeMargin=0;
        if(this.movingTrack.player.buffer.duration-this.movingTrack.trimLeft-timeMargin<0.1)
          timeMargin=this.movingTrack.player.buffer.duration-this.movingTrack.trimLeft-0.1;
        document.getElementById("track-waveform-content-"+this.movingTrack.id).getElementsByTagName("canvas")[0].style.marginRight = (Math.floor((-timeMargin*this.timelineScale()))).toString()+"px";
      }
      if(this.isTrimmingTrackLeft){
        var minDrag = -this.movingTrack.offset;
        var maxDrag = this.movingTrack.player.buffer.duration-this.movingTrack.trimRight-this.movingTrack.trimLeft-0.1;
        var minTrim = -this.movingTrack.trimLeft;
        var maxTrim = this.movingTrack.player.buffer.duration-this.movingTrack.trimRight-this.movingTrack.trimLeft -0.1;
        var deltaTimeMouse = ((e.clientX - this.movingStartX)/this.timelineScale());

        if(deltaTimeMouse<Math.max(minTrim,minDrag))
          deltaTimeMouse =Math.max(minTrim,minDrag);
        if(deltaTimeMouse>Math.min(maxTrim,maxDrag))
          deltaTimeMouse =Math.min(maxTrim,maxDrag);

        var newTrim = this.movingTrack.trimLeft + deltaTimeMouse;
        var newOffset = this.movingTrack.offset + deltaTimeMouse;

        document.getElementById("track-waveform-content-"+this.movingTrack.id).getElementsByTagName("canvas")[0].style.marginLeft = (Math.floor((-newTrim*this.timelineScale()))).toString()+"px";
        document.getElementById("track-waveform-"+this.movingTrack.id).style.marginLeft = (Math.floor(newOffset*this.timelineScale())).toString()+"px";

      }
      if(this.isDraggingTrack){
        var offsetTime = this.movingTrack.offset + ((e.clientX - this.movingStartX)/this.timelineScale());
        if(offsetTime<0)
          offsetTime=0;
        if(this.movingTrack.player.buffer.duration-this.movingTrack.trimRight-this.movingTrack.trimLeft+offsetTime>this.timelineLength)
          offsetTime=this.timelineLength - (this.movingTrack.player.buffer.duration-this.movingTrack.trimRight-this.movingTrack.trimLeft);
        document.getElementById("track-waveform-"+this.movingTrack.id).style.marginLeft = (Math.floor(offsetTime*this.timelineScale())).toString()+"px";
      }
    }
    trimDragTrackMouseUp(e: any) {
      if(e.button==0){
        if(this.isTrimmingTrackRight){
          this.movingTrack.trimRight += (this.movingStartX -e.clientX)/this.timelineScale();
          if(this.movingTrack.trimRight<0)
            this.movingTrack.trimRight=0;
          if(this.movingTrack.player.buffer.duration-this.movingTrack.trimLeft-this.movingTrack.trimRight<0.1)
            this.movingTrack.trimRight=this.movingTrack.player.buffer.duration-this.movingTrack.trimLeft-0.1;
          this.updateGrid();
          this.updateTrack(this.movingTrack);
          this.rescheduleTransport();
          this.isTrimmingTrackRight=false;
        }
        if(this.isTrimmingTrackLeft){
          var minDrag = -this.movingTrack.offset;
          var maxDrag = this.movingTrack.player.buffer.duration-this.movingTrack.trimRight-this.movingTrack.trimLeft-0.1;
          var minTrim = -this.movingTrack.trimLeft;
          var maxTrim = this.movingTrack.player.buffer.duration-this.movingTrack.trimRight-this.movingTrack.trimLeft -0.1;
          var deltaTimeMouse = ((e.clientX - this.movingStartX)/this.timelineScale());

          if(deltaTimeMouse<Math.max(minTrim,minDrag))
            deltaTimeMouse =Math.max(minTrim,minDrag);
          if(deltaTimeMouse>Math.min(maxTrim,maxDrag))
            deltaTimeMouse =Math.min(maxTrim,maxDrag);

          this.movingTrack.trimLeft += deltaTimeMouse;
          this.movingTrack.offset += deltaTimeMouse;
            
          this.updateGrid();
          this.updateTrack(this.movingTrack);
          this.rescheduleTransport();
          this.isTrimmingTrackLeft=false;
        }
        if(this.isDraggingTrack){
          this.movingTrack.offset += (e.clientX - this.movingStartX)/this.timelineScale();
          if(this.movingTrack.offset<0)
            this.movingTrack.offset=0;
          if(this.movingTrack.player.buffer.duration-this.movingTrack.trimRight-this.movingTrack.trimLeft+this.movingTrack.offset>this.timelineLength)
            this.movingTrack.offset=this.timelineLength - (this.movingTrack.player.buffer.duration-this.movingTrack.trimRight-this.movingTrack.trimLeft);
          this.updateGrid();
          this.updateTrack(this.movingTrack);
          this.rescheduleTransport();
          this.isDraggingTrack=false;
        }
      }
    }

    updateGrid(){
      //TODO: upgrade
      //timemarkers
      //preset by zoomlevel
      var enable1sTime = false
      var enable5sTime = false;
      var enable15sTime = false;
      var enable30sTime = false;
      var enable1sMarks = false;
      var enable5sMarks = false;
      var enable05sMarks = false;

      switch(this.timelineScale()){
        case 5:
          enable1sMarks=true;
          enable5sMarks=true;
          enable30sTime=true;
          break;
        case 10:
          enable1sMarks=true;
          enable5sMarks=true;
          enable15sTime=true;
          break;
        case 25:
          enable1sMarks=true;
          enable5sMarks=true;
          enable5sTime=true;
          break;
        case 50:
          enable1sMarks=true;
          enable5sMarks=true;
          enable5sTime=true;
          break;
        case 100:
          enable05sMarks=true;
          enable1sMarks=true;
          enable5sMarks=true;
          enable1sTime=true;
          break;
      }

      document.getElementById("piece-editor-ruller").innerHTML="";
      var rullerCanvas = document.createElement('canvas');
      var rullerContext = rullerCanvas.getContext("2d");
      var rullerCanvasHeight = 36;
      var rullerCanvasWidth = document.getElementById("piece-editor-ruller").clientWidth;
      var endTime = 0
      for( let track of this.piece.tracks)
        var endTime = Math.max(endTime,track.player.buffer.duration+track.offset-track.trimLeft-track.trimRight);
      endTime = Math.ceil(endTime);
      this.timelineLength = endTime+5;  //update global
      rullerContext.canvas.width = rullerCanvasWidth;
      rullerContext.canvas.height = rullerCanvasHeight;
      rullerContext.strokeStyle = this.colors.grey_50;
      rullerContext.lineWidth = 1;
      rullerContext.clearRect(0, 0, rullerCanvasWidth, rullerCanvasHeight);
      rullerContext.beginPath();
      
      //ruller markers
      //resolution 0.5s
      for (var i = 0; i < endTime*2; i+=1) {
        var x = (i/2)*this.timelineScale();
        if(i % (5*2)==0 && enable5sMarks){
          rullerContext.moveTo(x,0);
          rullerContext.lineTo(x,rullerCanvasHeight/2);  
        } else if(i % (1*2)==0 && enable1sMarks){
          rullerContext.moveTo(x,0);
          rullerContext.lineTo(x,rullerCanvasHeight/3);  
        } else if(enable05sMarks){
          rullerContext.moveTo(x,0);
          rullerContext.lineTo(x,rullerCanvasHeight/8);  
        }

        //timelabels
        if((i % (30*2)==0 && enable30sTime) || (i % (15*2)==0 && enable15sTime) || (i % (5*2)==0 && enable5sTime) || (i % (1*2)==0 && enable1sTime)){
          var m = Math.floor((i/2) / 60);
          var s = (i/2) % 60;
          var timeDiv = document.createElement('div');
          timeDiv.innerHTML=String(m).padStart(2, '0')+":"+String(s).padStart(2, '0');
          timeDiv.setAttribute("style","left:"+x+"px; top: 12px; position: absolute; color: "+this.colors.grey_50+"; width:max-content;font-family: monospace;");
          document.getElementById("piece-editor-ruller").appendChild(timeDiv);
        }
      }
      rullerContext.closePath();
      rullerContext.stroke();
      document.getElementById("piece-editor-ruller").appendChild(rullerCanvas);
      
      //grid
      document.getElementById("piece-editor-timeline-scroll").setAttribute("style","width: "+(endTime+5)*this.timelineScale()+"px;");
      var gridCanvas = <HTMLCanvasElement> document.getElementById("piece-editor-timeline-scroll-grid");
      var gridContext = gridCanvas.getContext("2d");
      var gridCanvasHeight = document.getElementById("piece-editor-timeline-scroll").clientHeight;
      var gridCanvasWidth = document.getElementById("piece-editor-timeline-scroll").clientWidth;
      gridContext.canvas.width = gridCanvasWidth;
      gridContext.canvas.height = gridCanvasHeight;
      gridContext.strokeStyle = this.colors.grey_50;
      gridContext.lineWidth = 1;
      gridContext.clearRect(0, 0, gridCanvasWidth, gridCanvasHeight);
      
      for(var time=0 ; time < this.timelineLength ; time += Tone.Time("4n").toSeconds()){
        var x = time * (this.timelineScale());
        //line
        gridContext.beginPath();
        gridContext.moveTo(x,0);
        gridContext.lineTo(x,gridCanvasHeight);
        gridContext.closePath();
        gridContext.stroke();
      }

      this.drawPlayerHead();
    }

    drawPlayerHead() {
      //playerhead
      var canvas = <HTMLCanvasElement> document.getElementById("piece-editor-timeline-scroll-head");
      var gridContext = canvas.getContext("2d");
      var canvasHeight = document.getElementById("piece-editor-timeline-scroll").clientHeight;
      var canvasWidth = 20;
      gridContext.canvas.width = canvasWidth;
      gridContext.canvas.height = canvasHeight;
      gridContext.strokeStyle = this.colors.secondary;
      gridContext.lineWidth = 1;
      gridContext.clearRect(0, 0, canvasWidth, canvasHeight);
      //line
      gridContext.beginPath();
      gridContext.moveTo(10,0);
      gridContext.lineTo(10,canvasHeight);
      gridContext.closePath();
      gridContext.stroke();
      //triangle
      gridContext.fillStyle = this.colors.secondary;
      gridContext.beginPath();
      gridContext.moveTo(0,0);
      gridContext.lineTo(10,5);
      gridContext.lineTo(20,0);
      gridContext.closePath();
      gridContext.fill();
    }

    updatePlayerHead(){
      var element = document.getElementById("piece-editor-timeline-scroll-head");
      if(element)
        element.setAttribute("style","left:" + ((Tone.Transport.seconds * this.timelineScale())-10) + "px;");
    }

    seek(event){
      if(event.buttons==1 && event.button==0){
        var time = (event.clientX - document.getElementById("piece-editor-ruller").getBoundingClientRect().left)/this.timelineScale();
        if(time < this.timelineLength-5.5){
          if(this.isRecording)
            this.recordTrack();
          Tone.Transport.seconds=time;
        }
      }
    }

    /*sound functions*/
    rescheduleTransport(){
      Tone.Transport.cancel();
      this.prepareTick();
      for(let track of this.piece.tracks){
        if(!track.loadingAudio){
          track.player.unsync();
          track.player.sync().start(track.offset,track.trimLeft,track.player.buffer.duration-track.trimRight-track.trimLeft);
        }
      }
      Tone.Transport.schedule(() => {
        if(this.isLoopToggled) {
          this.replay();
        } else {
          this.pause();
          this.replay();
        }
      }, (this.timelineLength-5));
    }

    play(){
      this.audioService.startAudioContext();
      this.rescheduleTransport();
      this.audioService.startTransport();
    }
    replay(){
      if(this.isRecording)
        this.recordTrack();
      if(this.isPlaying()) {
        Tone.Transport.stop();
        this.play()
      } else {
        Tone.Transport.stop();
      }
    }
    pause(){
      if(this.isRecording)
        this.recordTrack();
      Tone.Transport.pause();
    }

    isPlaying(): boolean{
      return Tone.Transport.state == "started";
    }

    // @HostListener('document:keydown.space', ['$event'])
    // onSpace(event: KeyboardEvent){
    //   if(this.isPlaying()) this.pause();
    //   else this.play();
    //   event.preventDefault()
    // }

    onScroll(e){
      if(e.ctrlKey){
        var delta = -Math.round(Math.abs(e.deltaY)/e.deltaY)
        this.currentZoomLevel = Math.min(Math.max(this.currentZoomLevel+delta,0),4)
        this.updateGrid()
        for(let track of this.piece.tracks)
          this.generateWaveform(track);
        e.preventDefault()
      }
    }

    /*backend synchronization*/

  
    uploadTrackFile(event: any){
      var i = this.trackUploads.push(new FileUpload) - 1;
      this.fileUploadService.uploadFile(this.trackUploads[i],`${environment.apiUrl}/audio-tr/upload/${this.piece.id}`,event.target.files.item(0),(seqId) =>{
        let trackUploadIndex = this.trackUploads.findIndex((fu)=>{return fu.seqId==seqId}); 
        if(this.trackUploads[trackUploadIndex].fileId != null)
          this.addTrack(this.trackUploads[trackUploadIndex].fileId);
        else
          this.trackUploads.splice(trackUploadIndex,1);
      });
    }

    uploadRecordedFile(file: File){
      var i = this.trackUploads.push(new FileUpload) - 1;
      this.fileUploadService.uploadFile(this.trackUploads[i],`${environment.apiUrl}/audio-tr/upload/${this.piece.id}`,file,(seqId) =>{
        let trackUploadIndex = this.trackUploads.findIndex((fu)=>{return fu.seqId==seqId}); 
        if(this.trackUploads[trackUploadIndex].fileId != null)
          this.addTrack(this.trackUploads[trackUploadIndex].fileId);
        else
          this.trackUploads.splice(trackUploadIndex,1);
      });
    }

    uploadScoreFile(event: any){
      this.fileUploadService.uploadFile(this.scoreUpload,`${environment.apiUrl}/piece/score/upload/${this.piece.id}`,event.target.files.item(0),(seqId) =>{
        let trackUploadIndex = this.trackUploads.findIndex((fu)=>{return fu.seqId==seqId}); 
        if(this.trackUploads[trackUploadIndex].fileId != null){
          this.piece.haveScore=true; 
          this.loadScoreFile();
        } else
          this.trackUploads.splice(trackUploadIndex,1);
      });
    }

    loadTrackFile(track: Track){
      track.loadingAudio=true;
      this.trackService.download(track.id,this.linkKey).subscribe(
        data =>  {track.url = URL.createObjectURL(data); this.makePlayer(track); track.loadingAudio=false;},
        error => console.error(error)
      );
    }

    sortedTracks(){
      return this.piece.tracks.sort((a, b) => a.ordinalNumber > b.ordinalNumber ? 1 : a.ordinalNumber === b.ordinalNumber ? 0 : -1);
    }

    moveTrackUp(track: Track){
      if(track.ordinalNumber <= 1) return;
      this.piece.tracks.find((t) => track.ordinalNumber-1 == t.ordinalNumber).ordinalNumber+=1
      track.ordinalNumber-=1;
      this.updateTrack(track);
    }
    moveTrackDown(track: Track){
      if(track.ordinalNumber >= this.piece.tracks.length) return;
      this.piece.tracks.find((t) => track.ordinalNumber+1 == t.ordinalNumber).ordinalNumber-=1
      track.ordinalNumber+=1;
      this.updateTrack(track);
    }

    updateTrackOrdinalNumbers(track: Track, newOrdinalNumber: number){
      let tr = this.piece.tracks.find((e) => e.ordinalNumber==newOrdinalNumber);
      if(tr != undefined)
        this.updateTrackOrdinalNumbers(tr,newOrdinalNumber+1);
      track.ordinalNumber = newOrdinalNumber;
    }


    changeTrackTitle(track: Track){
      if(!this.isEditor)
        return;
      track.titleEditing = !track.titleEditing;
      if(track.titleEditing == true){
        setTimeout(() => {
          var input = document.getElementById("track-title-input-"+track.id);
          input.focus();
        },0)
      } else {
          var input = <HTMLInputElement>document.getElementById("track-title-input-"+track.id);
          if(this.validationService.validateTrackLabel(input.value)){
            track.name=input.value;
            this.updateTrack(track);
          } else{
            input.value=track.name;
            this.validationService.updateTrackLabel("track-title-input-"+track.id);
          }
      }
    }

    updateTrackVolume(track: Track, volStr: string){
      var mutehold = track.channel.mute;
      var vol:number = Number.parseInt(volStr);
      track.channel.volume.value = vol;
      track.volumeDb = vol;
      track.channel.mute = mutehold;
    }

    submitTrackVolume(track: Track, volStr: string){
      var mutehold = track.channel.mute;
      var vol:number = Number.parseInt(volStr);
      track.channel.volume.value = vol;
      track.volumeDb = vol;
      track.channel.mute = mutehold;
      this.updateTrack(track);
    }

    updateTrack(track: Track){
      if(!this.isEditor)
        return;
      this.trackService.update(track).subscribe();
    }
    

    deleteTrack(track: Track){
      track.player.unsync()
      track.channel.disconnect()
      track.player.dispose()
      track.channel.dispose()
      let oldOrdinalNumber = track.ordinalNumber;
      this.piece.tracks.splice(this.piece.tracks.findIndex((tr) => tr.id==track.id),1);
      this.trackService.delete(track.id).subscribe(x => {
        this.piece.tracks.filter((t)=> t.ordinalNumber>oldOrdinalNumber).forEach((t)=> t.ordinalNumber-=1)
      });
    }

    downloadTrackFile(track: Track){
      var link = document.createElement('a');
      link.href = track.url;
      link.download = track.name+".mp3";
      link.click();
    }

    loadScoreFile(){
      if(this.piece.haveScore){
        this.pieceService.downloadScore(this.id,this.linkKey).subscribe(
        data =>  {this.piece.urlScore = URL.createObjectURL(data);},
        error => console.error(error)
        );
      }
    }

    deleteScore(){
      this.pieceService.deleteScore(this.id).subscribe(x => {this.piece.haveScore=false; this.piece.urlScore=null; this.scorePages=0;});
    }

    changeBPM(inputName: string){
      if(!this.isEditor)
        return;
      this.metronomeEditing = !this.metronomeEditing;
      if(this.metronomeEditing == true){
        setTimeout(() => {
          var input = document.getElementById(inputName);
          input.focus();
        },0)
      } else {
          var input = <HTMLInputElement>document.getElementById(inputName);
          if(this.validationService.validateBPM(input.value)){
            this.piece.tempo=Number(input.value);
            Tone.Transport.bpm.value=this.piece.tempo;
            this.prepareTick();
            this.updateGrid();
            this.updatePiece();
          }else{
            input.value=String(this.piece.tempo);
            this.validationService.updateBPM(inputName);
          }
      }
    }

    changeMetronome(){
      this.piece.metronomeChannel.mute = !this.piece.metronome;
      if(!this.isEditor)
        return;
      this.updatePiece();
    }

    changePieceTitle(inputName: string){
      if(!this.isEditor)
        if(this.paramEmbedded){
          if(this.linkKey !== undefined)
            this.router.navigate([`/piece/link/${this.id}/${this.linkKey}`]);
          else
            this.router.navigate([`/piece/${this.id}`]);
        }
        else
          return;
      this.titleEditing = !this.titleEditing;
      if(this.titleEditing == true){
        setTimeout(() => {
          var input = document.getElementById(inputName);
          input.focus();
        },0)
      } else {
          var input = <HTMLInputElement>document.getElementById(inputName);
          if(this.validationService.validateName(input.value)){
            this.piece.title=input.value;
            this.updatePiece();
          } else{
            input.value=String(this.piece.title);
            this.validationService.updateName(inputName);
          }
      }
    }

    changePieceNotes(){
      if(!this.isEditor)
        return;
      this.notesEditing = !this.notesEditing;
      if(this.notesEditing == true){
        setTimeout(() => {
          var input = document.getElementById("piece-notes-input");
          input.focus();
        },0)
      } else {
          var input = <HTMLInputElement>document.getElementById("piece-notes-input");
          if(this.validationService.validateDescription(input.value)){
            this.piece.notes=input.value;
            this.updatePiece()
          } else {
            this.alertService.error("Cannot save this piece description. Content invalid!");
            this.notesEditing = !this.notesEditing;
          }
      }
    }

    updatePiece(){
      if(!this.isEditor)
        return;
      this.pieceService.update(this.piece).subscribe();
    }

    blurInput($event){
      ($event.target as HTMLElement).blur();
    }


    @HostListener('unloaded')
    ngOnDestroy() {
      this.audioService.clean();
      for(let track of this.piece.tracks){
        if(track.player != undefined) track.player.unsync();
        if(track.meter != undefined)track.meter.dispose();
        if(track.player != undefined)track.player.dispose();
        if(track.channel != undefined)track.channel.dispose();
      }
      window.clearInterval(this.infiniteLoop);
    }
    

}
