import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Share } from '@app/_models/share';

@Injectable({ providedIn: 'root' })
export class SharingService {
    constructor(private http: HttpClient) { }

    getAllPieceShare() {
        return this.http.get<Share[]>(`${environment.apiUrl}/share/piece/all`);
    }

    getAllPlaylistShare() {
        return this.http.get<Share[]>(`${environment.apiUrl}/share/playlist/all`);
    }

    getPieceShareById(id: number) {
        return this.http.get<Share>(`${environment.apiUrl}/share/pieceshare/${id}`);
    }

    getPlaylistShareById(id: number) {
        return this.http.get<Share>(`${environment.apiUrl}/share/playlistshare/${id}`);
    }

    getPieceSharesByObjectId(id: number) {
        return this.http.get<Share[]>(`${environment.apiUrl}/share/piece/${id}`);
    }

    getPlaylistSharesByObjectId(id: number) {
        return this.http.get<Share[]>(`${environment.apiUrl}/share/playlist/${id}`);
    }

    createPieceShare(share: Share) : Observable<any> {
        return this.http.post(`${environment.apiUrl}/share/piece/create`, share);
    }

    createPlaylistShare(share :Share) : Observable<any> {
        return this.http.post(`${environment.apiUrl}/share/playlist/create`, share);
    }

    updatePieceShare(share: Share) {
        return this.http.post(`${environment.apiUrl}/share/piece/update`, share);
    }

    updatePlaylistShare(share: Share) {
        return this.http.post(`${environment.apiUrl}/share/playlist/update`, share);
    }

    deletePieceShare(id: number) {
        return this.http.delete(`${environment.apiUrl}/share/piece/delete/${id}`);
    }

    deletePlaylistShare(id: number) {
        return this.http.delete(`${environment.apiUrl}/share/playlist/delete/${id}`);
    }
}