import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ScoreComponent } from '@app/_global/score/score.component';
import { AudioService } from '@app/_services/audio.service';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { AppMenuComponent } from '@app/layout';
import { onErrorResumeNext } from 'rxjs';
import * as Tone from 'tone';

@Component({
  standalone: true,
  imports: [CommonModule,AppMenuComponent,ScoreComponent],
  selector: 'app-rythmicdictation',
  templateUrl: './rythmicdictation.component.html',
  styleUrls: ['./rythmicdictation.component.css']
})
export class RythmicDictationComponent implements OnInit {
    userAccount: Account;
    showOptions = false;
    /*
    * ready
    * playing
    * answer
    * feedback-wrong
    * feedback-right
    */
    fsm = "ready";
    rythmsCount = 0;
    correctAnswers = 0;
    level=1;
    rythm =[];
    guessedRythm = [];
    guessedRythmText = '';
    length = 32;
    metronomeChannel;
    metronomePlayer;
    rythmChannel;
    rythmPlayer;
    tempo = 60;

    @ViewChild("score") score: ScoreComponent;

    constructor(private audioService: AudioService,
                private accountService: AccountService) {
            this.accountService.account.subscribe(x => this.userAccount = x);
          }

    ngOnInit() {
        Tone.Transport.bpm.value = this.tempo;
        this.metronomePlayer = new Tone.Player("/assets/sounds/tick1.mp3").toDestination();
        this.rythmPlayer = new Tone.Player("/assets/sounds/tick2.mp3").toDestination();
        this.changeState("ready");
    }

    start(){
        this.changeState("playing");
    }

    stop(){
        this.changeState("ready");
    }

    noteClick(value){
        if(this.fsm=="answer" || this.fsm=="playing") {
            let guessedLength=0
            for(let i=0;i<this.guessedRythm.length;i++)
                guessedLength +=this.guessedRythm[i];
            if(guessedLength+value <= this.length)
                this.guessedRythm.push(value);
            this.renderGuess();
            if(guessedLength+value == this.length)
                this.checkGuess()
        }
    }

    stepback(){
        if(this.guessedRythm.length>0)
            this.guessedRythm.pop()
        this.renderGuess();
    }

    changeState(newState){
        if(newState=="ready"){
            this.clearStatistics();
        }
        if(newState=="playing"){
            this.rythm = [];
            this.guessedRythm= [];
            this.guessedRythmText = '';
            this.score.reset();
            this.rythmsCount+=1;
            this.generateRythm()
            this.playRythm()
        }
        if(newState=="feedback-wrong"){
            this.drawRythm(this.rythm);
            this.delay(5000).then(()=>this.changeState("playing"))
        }
        if(newState=="feedback-right"){
            this.correctAnswers+=1;
            this.drawRythm(this.rythm);
            this.delay(5000).then(()=>this.changeState("playing"))
        }
        this.fsm=newState;
    }

    generateRythm(){
        let maxSubdivision;
        let minSubdivision;
        let allowedValues;
        if(this.level==1){
            maxSubdivision=16
            minSubdivision=8
            allowedValues=[16,8,4,2]
        } else if(this.level==2){
            maxSubdivision=8
            minSubdivision=2
            allowedValues=[8,4,2]
        } else if(this.level==3){
            maxSubdivision=8
            minSubdivision=1
            allowedValues=[4,2,1]
        }

        /*
            16 cała nuta
            8 półnuta
            4 ćwierćnuta
            2 ósemka
            1 szestnastka
        */

        //todo fix accents

        let allowedSubdivisions = [16,8,4,2,1].filter((value)=> (value<=maxSubdivision)).filter((value) =>(value>=minSubdivision));
        let rythmLength=0;
        while(rythmLength<this.length){
            let subdivision = allowedSubdivisions[this.randomInt(0,allowedSubdivisions.length-1)]
            if(subdivision + rythmLength >this.length) continue;
            let value
            do{
                value = allowedValues[this.randomInt(0,allowedValues.length-1)]
            } while(value>subdivision)
            for(let i=0;i<(Math.round(subdivision/value));i++)
                this.rythm.push(value)
            rythmLength += subdivision
        }
    }

    checkGuess(){
        for(let i=0;i<this.rythm.length;i++)
            if(this.rythm[i]!=this.guessedRythm[i]){
                this.changeState("feedback-wrong")
                return
            }
        this.changeState("feedback-right")
    }

    clearStatistics(){
        this.correctAnswers = 0;
        this.rythmsCount = 0;
    }

    setLevel(level){
        if(this.fsm=='ready'){
            this.level=level;
        }
    }

    playRythm(){
        this.changeState("playing");
        this.audioService.startAudioContext();
        Tone.Transport.bpm.value=60;
        let rythmLength = Tone.Time("4n").toSeconds()*(Math.round(this.length/4)+4);
        this.audioService.scheduleMetronome(this.metronomePlayer,rythmLength);
        this.audioService.scheduleRythm(this.rythm,this.rythmPlayer,Tone.Time("1n").toSeconds());
        this.delay(rythmLength*1000).then(()=>this.changeState("answer"));
        this.audioService.restartTransport();
    }

    drawRythm(rythm){
        let spacing = Math.round(600/rythm.length);
        for(let i=0;i<rythm.length;i++){
            if(rythm[i]==16)
                this.score.drawNote(i*spacing,65,"whole");
            else if(rythm[i]==8)
                this.score.drawNote(i*spacing,65,"half");
            else if(rythm[i]==4)
                this.score.drawNote(i*spacing,65,"quarter");
            else if(rythm[i]==2)
                this.score.drawNote(i*spacing,65,"eight");
            else if(rythm[i]==1)
                this.score.drawNote(i*spacing,65,"sixteenth");
        }
    }

    renderGuess(){
        this.guessedRythmText = '';
        for(let i=0;i<this.guessedRythm.length;i++){
            if(this.guessedRythm[i]==16)
                this.guessedRythmText += '\u{1D15D}\u{A0}\u{A0}';
            else if(this.guessedRythm[i]==8)
                this.guessedRythmText += '\u{1D15E}\u{A0}\u{A0}';
            else if(this.guessedRythm[i]==4)
                this.guessedRythmText += '\u{1D15F}\u{A0}\u{A0}';
            else if(this.guessedRythm[i]==2)
                this.guessedRythmText += '\u{1D160}\u{A0}\u{A0}';
            else if(this.guessedRythm[i]==1)
                this.guessedRythmText += '\u{1D161}\u{A0}\u{A0}';
        }
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    randomInt(min,max){
        return Math.floor(Math.random() * (max+1-min))+min;
    }
    
    @HostListener('unloaded')
    ngOnDestroy() {
        this.audioService.clean();
    }

}