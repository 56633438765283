import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Share, UserGroup, User } from '@app/_models';
import { SharingService, UserGroupService } from '@app/_services';
import { Account } from '@app/authserver/_models';
import { AccountService, AuthServerService } from '@app/authserver/_services';
import { environment } from '@environments/environment';
import { ClipboardService } from 'ngx-clipboard';

@Component({ 
    selector: 'sharing-modal', 
    templateUrl: 'sharingmodal.component.html', 
    styleUrls: ['sharingmodal.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class SharingModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() objectId: string;
    @Input() type: string;
    @Input() isPublic: boolean;
    @Input() title: string = "Udostępnianie";
    @Output() onPublicShare: EventEmitter<any> = new EventEmitter();
    userAccount: Account;
    private element: any;
    
    shares: Share[];
    userShares: Share[];
    groupShares: Share[];
    linkShares: Share[];

    groups: UserGroup[];
    filteredGroups: UserGroup[];
    users: User[] =[];
    filteredUsers: User[] =[];
    
    lastUserListUpdate: number;
    isThrottled: boolean = false;
    showSearchResults: boolean = false;
    showLinkShare: boolean=true;
    sharingLink: string;
    domain: string;

    constructor(
        private el: ElementRef,
        private sharingService : SharingService,
        private userGroupService : UserGroupService,
        private clipboardService : ClipboardService,
        private authServerService: AuthServerService,
        private accountService: AccountService) {
            this.accountService.account.subscribe(x => this.userAccount = x);
            this.element = el.nativeElement;
            this.domain = environment.domain;
    }

    ngOnInit(): void {
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }
        if (!this.objectId) {
            console.error('modal must have an objectId');
            return;
        }
        if (!this.type) {
            console.error('modal must have an type');
            return;
        }
        if(this.type=="piece"){
            this.sharingService.getPieceSharesByObjectId(Number.parseInt(this.objectId)).subscribe(x => { this.shares = x; this.updateShareLists();});
            this.sharingLink = environment.domain + "piece/link/"+this.objectId+"/";
        }
        if(this.type=="playlist"){
            this.sharingService.getPlaylistSharesByObjectId(Number.parseInt(this.objectId)).subscribe(x => { this.shares = x; this.updateShareLists();});
            this.sharingLink = environment.domain + "playlist/link/"+this.objectId+"/"
        }        //todo: generate if needed + 
        this.userGroupService.getUserBelongAll().subscribe(x => { this.groups = x; });
        
        document.body.appendChild(this.element);
        this.element.addEventListener('click', el => {
            if (el.target.className === 'sharing-modal-background') {
                this.close();
            }
        });

        this.lastUserListUpdate = (new Date()).getTime();
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.element.remove();
    }

    //SHARE MANAGEMENT
    updateShareLists(){
        this.userShares = this.shares.filter((sh) => sh.userId!=null);
        this.groupShares = this.shares.filter((sh) => sh.userGroupId!=null);
        this.linkShares = this.shares.filter((sh) => sh.shareLinkEnabled==true);
        this.updateSearchResults();
    }

    addShare(share: Share){
        this.shares.push(share);
        this.updateShareLists();
    }

    removeShare(id: number){
        this.shares.forEach((share,index) => {
            if(share.id==id)
                this.shares.splice(index,1);
        })
        this.updateShareLists();
    }

    deleteShare(id: number){
        if(this.type=="piece")
            this.sharingService.deletePieceShare(id).subscribe(()=>{this.removeShare(id)});
        if(this.type=="playlist")
            this.sharingService.deletePlaylistShare(id).subscribe(()=>{this.removeShare(id)});
    }

    createShare(userId=null,userGroupId=null,shareLinkEnabled=false){
        var share = new Share;
        share.objectId = Number.parseInt(this.objectId);
        share.shareLinkEnabled=shareLinkEnabled;
        share.userGroupId=userGroupId;
        share.userId=userId;

        if(this.type=="piece")
            this.sharingService.createPieceShare(share).subscribe(s => {this.addShare(s);});
        if(this.type=="playlist")
            this.sharingService.createPlaylistShare(share).subscribe(s => {this.addShare(s);});
    }

    copyLinkShare(){
        if(this.linkShares.length==0)
            this.createShare(null,null,true);
        if(this.linkShares.length>0)
            this.copyToClipboard(this.sharingLink+this.linkShares[0].linkKey);
    }

    //SEARCH MANAGEMENT
    updateSearchResults(){
        var currentDate = new Date();
        var timebalance = this.lastUserListUpdate+2000-currentDate.getTime();
        if(this.isThrottled)
            return;
        this.isThrottled=true;
        if(timebalance<0)
            timebalance=0;
        setTimeout(() =>{
            var query = (<HTMLInputElement>document.getElementById("sharingmodal-search-input")).value;
            if(query.length>1)
                this.authServerService.reqSearchUser(query).subscribe(x => { this.users = x; this.subtractUserSets(); this.isThrottled=false; this.lastUserListUpdate = currentDate.getTime();});
            else {
                this.isThrottled=false;
                this.users=[];
            }
        }, timebalance)
        this.filterGroupSets();
        this.subtractUserSets();
    }

    hideSearchResults(){
        setTimeout(() =>{this.showSearchResults=false;},500);
    }

    subtractUserSets(){
        if(this.users){
            this.filteredUsers = this.users.filter(x => {var dup=0; this.userShares.forEach(y => {if(x.id==y.userId) dup+=1;}); return dup==0;});
            this.filteredUsers = this.filteredUsers.filter(x => x.id != this.userAccount.userId)
        }
    }

    filterGroupSets(){
        var query = (<HTMLInputElement>document.getElementById("sharingmodal-search-input")).value.toLowerCase();
        if(this.groups){
            this.filteredGroups = this.groups.filter(x => {
                var dup=0; 
                this.groupShares.forEach(y => {
                    if(x.id==y.userGroupId) 
                        dup+=1;
                    }); 
                if(x.name.length>0 && query.length>1 && !x.name.toLowerCase().includes(query)) 
                    return false; 
                return dup==0;
            });
        } 
        else {
            this.filteredGroups=[];
        }
    }

    //MODAL MANAGEMENT
    close(): void {
        this.element.style.display = 'none';
    }

    copyToClipboard(text: string){
        this.clipboardService.copy(text);
    }

}