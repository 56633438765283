import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot, CanActivate
} from '@angular/router';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  currentUser: Account;
  constructor(
    protected readonly router: Router,
    protected readonly accountService: AccountService
  ) {
    accountService.account.subscribe(a => this.currentUser=a);
   }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.currentUser) {
      return true;
    }

    // not logged in and not optional so redirect to login page with the return url
    if(route.data.optional){
      return true;
    }
    this.accountService.login();
    return false;
}
}