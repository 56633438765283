import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { ExerciseLesson } from '@app/_models/exerciselesson';

@Injectable({ providedIn: 'root' })
export class ExerciseService {
    constructor(private http: HttpClient) { }

    getExerciseLessons(exerciseName: string) {
        return this.http.get<ExerciseLesson[]>(`${environment.apiUrl}/exercise/${exerciseName}/lessons`);
    }

    updateLessonProgress(exerciseLesson: ExerciseLesson) {
        return this.http.post(`${environment.apiUrl}/exercise/progress/update`,exerciseLesson);
    }

}