import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from './authserver/_models';
import { AccountService } from './authserver/_services';


@Component({ selector: 'app', templateUrl: 'app.component.html' })
export class AppComponent implements OnInit{
    userAccount: Account;

    constructor(
        private router: Router,
        private accountService: AccountService)
        {
            this.accountService.account.subscribe(x => this.userAccount = x);
        }

    ngOnInit(){}

    @HostListener('window:resize', ['$event'])
    @HostListener('window:load', ['$event'])
    autoHeight(){
        let height = window.innerHeight;
        let hh = document.getElementById("main-header").clientHeight;
        let ha = document.getElementById("main-alert").clientHeight;
        let hf = document.getElementById("main-footer").clientHeight;
        document.getElementById("main-content").style.minHeight = (height - hf - hh - ha)+"px";
    }
}