import { CommonModule } from '@angular/common';
import {  Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserGroup } from '@app/_models';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { AvatarModalComponent } from '@app/modals/avatarmodal/avatarmodal.component';
import { environment } from '@environments/environment';

@Component({
  standalone: true,
  imports: [CommonModule,AvatarModalComponent],
  selector: 'avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.less']
})
export class AvatarComponent implements OnInit {
  @Input() type: string; // user-avatar,usergroup-avatar
  @Input() showEditBadge: boolean=false;
  @Input() size: string='normal'; //small,normal,full

  displayedUrl: string;
  userAccount: Account;

  //user specific
  @Input() showCreationTooltip: boolean=false;
  @Input() tooltipDate : number;
  @Input() avatarUrl : string;
  @Input() userId : string;
  @Input() firstName : string;
  @Input() lastName : string;

  createdByString: string;
  creationDateString: string;
  background: string;
  showPlaceholder: boolean = false;
  placeholderLetters: string;

  //usergroup specific
  @Input() userGroup: UserGroup;

    constructor( private accountService: AccountService) {
      this.accountService.account.subscribe(x => {this.userAccount = x;});
    }

    ngOnInit() {
      if(this.type=='user-avatar'){
        if(this.avatarUrl === undefined || this.avatarUrl == null || this.avatarUrl == "")
        {
          this.showPlaceholder=true;
          if(this.firstName != undefined && this.firstName.length>0 && this.lastName != undefined && this.lastName.length>0)
          this.placeholderLetters=this.firstName[0]+this.lastName[0];
          this.background = this.stringToColour(this.userId);
        } else 
          this.displayedUrl= this.avatarUrl;
        if(this.showCreationTooltip){
          this.createdByString= this.firstName+ " "+ this.lastName;
          this.creationDateString=new Date(this.tooltipDate).toLocaleTimeString() + " " + new Date(this.tooltipDate).toLocaleDateString();
        }
      } else if(this.type=='usergroup-avatar'){
        if(!this.userGroup.haveAvatar){
          this.showPlaceholder=true;
        } else {
          this.displayedUrl= `${environment.apiUrl}/usergroup/avatar/download/${this.userGroup.id}`;
        }
      }
    }

    stringToColour(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
          hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = '#';
      for (var i = 0; i < 3; i++) {
          var value = (hash >> (i * 8)) & 0xFF;
          colour += ('00' + value.toString(16)).substr(-2);
      }
      return colour;
    }

    updateAvatar(url: string){
      if(this.type=='usergroup-avatar' && url == null){
        this.userGroup.haveAvatar=false;
        this.showPlaceholder=true;
        return
      }
      if(this.type=='user-avatar' && url == null){
        this.avatarUrl='';
        this.showPlaceholder=true;
        return
      }
      this.showPlaceholder=false;
      let timeParam = Math.floor(new Date().getTime()/1000); //prevents cache in case of component reloading
      this.avatarUrl=url;
      this.displayedUrl=this.avatarUrl+"?"+ timeParam;
    }

    openModal(id: string) {
      document.getElementById(id).style.display = 'block';
      //document.body..    overflow: hidden;
    }
}
