import { Component,  OnInit} from '@angular/core';


@Component({
  selector: 'app-cookies-warn',
  templateUrl: './cookies.component.html'
})
export class CookiesComponent implements OnInit {
  showCookiesWarn: boolean = true;
  constructor() {}

  ngOnInit() {
    if(this.getCookie("cookies_accept")=="accept_v1"){
      this.showCookiesWarn = false;
    }
  }

  setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }
  getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
  }

  onAccept(){
    this.setCookie("cookies_accept","accept_v1",365);
    this.showCookiesWarn = false;
  }

}
