<div *ngIf="showPiano" class="fixed h-[328px] w-full bottom-0 z-[200]">
    <div class="mx-auto h-16 py-2 px-10 w-32 bg-primary rounded-t-lg"><span class="mx-auto icon-btn icon icon-48" (click)="closePiano()">piano_off</span></div>
    <div class="h-[272px] w-full bg-ch-blue-500 overflow-x-scroll flex flex-nowrap" id="piano-keys">
        <div *ngFor="let item of [].constructor(keysNum); let i = index" class="h-full relative">
            <div *ngIf="keyToNote(i+minNote)==0 || keyToNote(i+minNote)==2 || keyToNote(i+minNote)==4 || keyToNote(i+minNote)==5 || keyToNote(i+minNote)==7 || keyToNote(i+minNote)==9 || keyToNote(i+minNote)==11" 
                (mouseover)="play($event,i+minNote)" (mousedown)="play($event,i+minNote)" (touchstart)="playtouch(i+minNote)"
                class="h-full w-16 border rounded-b-md border-ch-grey-900 bg-ch-blue-100 hover:bg-blue-200 cursor-pointer">
                <div class="absolute bottom-0 w-16 h-16 text-center font-title noselect">{{keyToName(i+minNote)}}</div>
            </div>
            <div *ngIf="keyToNote(i+minNote)==1 || keyToNote(i+minNote)==6" (mouseover)="play($event,i+minNote)" (mousedown)="play($event,i+minNote)" (touchstart)="playtouch(i+minNote)"
                class="absolute w-12 h-[160px] top-0 left-[-32px] bg-ch-grey-900 rounded-b-lg hover:bg-gray-800 cursor-pointer z-[210]"></div>
            <div *ngIf="keyToNote(i+minNote)==3 || keyToNote(i+minNote)==10" (mouseover)="play($event,i+minNote)" (mousedown)="play($event,i+minNote)" (touchstart)="playtouch(i+minNote)"
                class="absolute w-12 h-[160px] top-0 left-[-16px] bg-ch-grey-900 rounded-b-lg hover:bg-gray-800 cursor-pointer z-[210]"></div>
            <div *ngIf="keyToNote(i+minNote)==8" (mouseover)="play($event,i+minNote)" (mousedown)="play($event,i+minNote)" (touchstart)="playtouch(i+minNote)"
                class="absolute w-12 h-[160px] top-0 left-[-24px] bg-ch-grey-900 rounded-b-lg hover:bg-gray-800 cursor-pointer z-[210]"></div>
        </div>
    </div>
</div>

<div *ngIf="!showPiano" class="fixed h-16 w-16 p-2 top-32 right-0 z-[200] bg-primary rounded-l-lg"><span class="mx-auto icon-btn icon icon-48" (click)="openPiano()">piano</span></div>