<!-- CONTENT CONTAINERS -->
<div class="center-container relative">
    <div class="flex flex-col gap-[80px] lg:gap-[160px]">
    <!-- HEADER MOBILE/TABLET-->
    <div class="lg:hidden p-0 md:pr-[140px] flex flex-col gap-6 relative">
        <div class="absolute top-0 left-0 right-0 bottom-0 ch-bg-header"></div>
        <div class="flex-auto z-10">
            <img class="h-full" src="/assets/images/ilu-header.svg">
        </div>
        <div class="z-10 flex-auto flex flex-col gap-4">
            <h1 class="pb-0">Twoja baza utworów</h1>
            <div>
                <p class="mb-8 font-title">Udostępniaj swojemu zespołowi repertuar utworów do przesłuchania. Utwory możesz składać z wielu ścieżek audio i dołączać do nich notatki.</p>
                <p>Dzięki odtwarzaczowi wielościeżkowemu proces nauki linii melodycznych, a co najważniejsze harmonii będzie dużo prostszy.</p>
            </div>
            <div class="flex flex-col md:flex-row gap-4 lg:gap-12">
                <a class="ch-btn ch-btn-filled ch-btn-sec flex-sh" [routerLink]="'/piece/112'">Sprawdź przykładowy utwór</a>
            </div>
        </div>
    </div>
    <!-- HEADER -->
    <div>
    <div class="hidden lg:flex items-center gap-[155px] relative">
        <div class="absolute top-0 left-[-1024px] right-[-1024px] bottom-[-152px] ch-bg-header"></div>
        <div class="z-10 flex-auto flex flex-col gap-8">
            <h1 class="pb-0">Twoja baza utworów</h1>
            <div>
                <p class="mb-8 font-title">Udostępniaj swojemu zespołowi repertuar utworów do przesłuchania. Utwory możesz składać z wielu ścieżek audio i dołączać do nich notatki.</p>
                <p>Dzięki odtwarzaczowi wielościeżkowemu proces nauki linii melodycznych, a co najważniejsze harmonii będzie dużo prostszy.</p>
            </div>
            <div class="flex flex-col md:flex-row gap-4 lg:gap-12">
                <a class="ch-btn ch-btn-filled ch-btn-sec flex-sh" [routerLink]="'/piece/112'">Sprawdź przykładowy utwór</a>
            </div>
        </div>
        <div class="flex-auto lg:flex-none z-10 order-1 md:order-2">
            <img src="/assets/images/ilu-header.svg" style="min-width: 344px;">
        </div>
    </div>
    <!-- ROADMAP -->
    <div class="mt-14 grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        <div class="relative">
            <div class="left-0 right-0 w-20 h-20 p-4 absolute mx-auto bg-primary rounded-full">
                <span class="icon-48 text-primary-content">login</span>
            </div>
            <div class="bg-white mt-10 py-10 md:mt-4 md:pt-[88px] md:pb-6 lg:mt-10 lg:py-10 rounded-lg"
            style="box-shadow: 0px 20px 40px 0px rgba(185, 194, 242, 0.10);">
                <h4 class="w-full text-center pb-2">krok 01</h4>
                <div class="w-full text-center font-body">Zarejestruj się</div>
            </div>
        </div>
        <div class="relative">
            <div class="left-0 right-0 w-20 h-20 p-4 absolute mx-auto bg-primary rounded-full">
                <span class="icon-48 text-primary-content">play_arrow</span>
            </div>
            <div class="bg-white mt-10 py-10 md:mt-4 md:pt-[88px] md:pb-6 lg:mt-10 lg:py-10 rounded-lg"
                style="box-shadow: 0px 20px 40px 0px rgba(185, 194, 242, 0.10);">
                <h4 class="w-full text-center pb-2">krok 02</h4>
                <div class="w-full text-center font-body">Nagraj utwory</div>
            </div>
        </div>
        <div class="relative">
            <div class="left-0 right-0 w-20 h-20 p-4 absolute mx-auto bg-primary rounded-full">
                <span class="icon-48 text-primary-content">music_note</span>
            </div>
            <div class="bg-white mt-10 py-10 md:mt-4 md:pt-[88px] md:pb-6 lg:mt-10 lg:py-10 rounded-lg"
            style="box-shadow: 0px 20px 40px 0px rgba(185, 194, 242, 0.10);">
                <h4 class="w-full text-center pb-2">krok 03</h4>
                <div class="w-full text-center font-body">Dodaj nuty i notatki</div>
            </div>
        </div>
        <div class="relative">
            <div class="left-0 right-0 w-20 h-20 p-4 absolute mx-auto bg-primary rounded-full">
                <span class="icon-48 text-primary-content">share</span>
            </div>
            <div class="bg-white mt-10 py-10 md:mt-4 md:pt-[88px] md:pb-6 lg:mt-10 lg:py-10 rounded-lg"
            style="box-shadow: 0px 20px 40px 0px rgba(185, 194, 242, 0.10);">
                <h4 class="w-full text-center pb-2">krok 04</h4>
                <div class="w-full text-center font-body">Udostępnij nagranie</div>
            </div>
        </div>
    </div>
    </div>
    
    <!-- LIBRARY -->
    <div class="flex flex-col gap-16 lg:gap-[88px] z-[20]">
        <div class="mx-auto w-[104px] h-0 border-[0.5px] border-primary"></div>
        <div class="w-full text-center">
            <h1 class="pb-4">Poznaj odtwarzacz</h1>
            <p>Sprawdź możliwości odtwarzacza wielościeżkowego, w którym po zalogowaniu możesz tworzyć swoje utwory w przeglądarce.</p>
        </div>

        <div class="flex flex-col gap-8">
            <div class="mx-auto py-4 flex w-[600px] text-center">
                <div class="choiry-v-radio-l flex-1" [ngClass]="selectedPiece==111 ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectedPiece=111;">Can you feel the love tonight</div>
                <div class="choiry-v-radio-r flex-1" [ngClass]="selectedPiece==112 ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectedPiece=112;">Come again!</div>
            </div>
    
            <app-player *ngIf="selectedPiece==111" [paramId]="111" [paramEmbedded]="true" [hideScore]="true" [hideComments]="true"></app-player>
            <app-player *ngIf="selectedPiece==112" [paramId]="112" [paramEmbedded]="true" [hideScore]="true" [hideComments]="true"></app-player>
        </div>

        <!-- <div class="flex flex-col md:flex-row gap-8 text-white">
                <a [routerLink]="'/piece/111'" class="rounded-lg overflow-hidden relative w-full h-72" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 47.98%, rgba(0, 0, 0, 0.50) 100%), url('assets/images/canyoufeel.png'), var(--ch-primary) 50%; 
                    background-position: center; background-size: contain; background-repeat: no-repeat;">
                <div class="absolute left-8 right-8 bottom-10">
                    <div class="flex h-6 mb-6">
                        <span class="icon-24 text-white">favorite</span>
                        <div>4.8</div>
                    </div>
                    <div class="font-boldbody">
                        Can you feel the love tonight
                    </div>
                </div>
            </a>
            <a [routerLink]="'/piece/112'" class="rounded-lg overflow-hidden relative w-full h-72" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 47.98%, rgba(0, 0, 0, 0.50) 100%), url('assets/images/comeagain.png'), var(--ch-primary) 50%; 
                    background-position: center; background-size: contain; background-repeat: no-repeat;">
                <div class="absolute left-8 right-8 bottom-10">
                    <div class="flex h-6 mb-6">
                        <span class="icon-24 text-white">favorite</span>
                        <div class="mr-6">5.0</div>
                    </div>
                    <div class="font-boldbody">
                        Come again!
                    </div>
                </div>
            </a>  
            <a href="https://choiry.io/piece/link/34/0LxPCjcAzG9NQ1tasMg3" class="rounded-lg overflow-hidden relative w-full h-72" style="background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 47.98%, rgba(0, 0, 0, 0.50) 100%), url('assets/images/otherdemo.png'), var(--ch-primary) 50%; 
                    background-position: center; background-size: contain; background-repeat: no-repeat;">
                <div class="absolute left-8 right-8 bottom-10">
                    <div class="flex h-6 mb-6">
                        <span class="icon-24 text-white">favorite</span>
                        <div class="mr-6">4.2</div>
                    </div>
                    <div class="font-boldbody">
                        Ave Verum- Franz Liszt
                    </div>
                </div>
            </a>
        </div> -->
        <div class="text-center"><a class="ch-btn ch-btn-filled ch-btn-sec" [routerLink]="'/list/piece'" disabled="true">Stwórz nowy utwór</a></div>
    </div>
    <!-- FEATURES -->
    <div class="flex flex-col gap-16 lg:gap-[88px] z-[10]">
        <div class="mx-auto w-[104px] h-0 border-[0.5px] border-primary"></div>
        <h1 class="w-full text-center">Wielofunkcyjne narzędzie dla Twojego chóru</h1>
        <div class="flex flex-col gap-8">
            <div class="flex gap-6 flex-col md:flex-row">
                <div class="ch-card rounded-[25px] p-8 gap-8 flex flex-col md:flex-row items-center flex-1">
                    <div class="flex-none w-16 h-16 p-3 bg-primary rounded-full">
                        <span class="icon-40 text-primary-content">login</span>
                    </div>
                    <div class="flex-1">
                        <h4 class="pb-2">Rejestracja za darmo</h4>
                        <div>Założenie darmowego konta zajmie Ci minutę, a w przyszłości bez problemu będziesz mógł rozszerzyć jego możliwości wykupując wyższy plan.</div>
                    </div>
                </div>
                <div class="ch-card rounded-[25px] p-8 gap-8 flex-1 items-center flex flex-col md:flex-row">
                    <div class="flex-none w-16 h-16 p-3 bg-primary rounded-full">
                        <span class="icon-40 text-primary-content">share</span>
                    </div>
                    <div class="flex-1">
                        <h4 class="pb-2">Udostępnianie</h4>
                        <div>Udostępniaj stworzone utwory na kilka sposobów: użytkownikom, poprzez prywatny link lub publicznie poprzez konto premium.</div>
                    </div>
                </div>
            </div>
            <div class="flex gap-6 flex-col md:flex-row">
                <div class="ch-card rounded-[25px] p-8 gap-8 flex flex-col md:flex-row items-center flex-1">
                    <div class="flex-none w-16 h-16 p-3 bg-primary rounded-full">
                        <span class="icon-40 text-primary-content">piano</span>
                    </div>
                    <div class="flex-1">
                        <h4 class="pb-2">Nagrywaj online</h4>
                        <div>Nagrywaj ścieżki audio bezpośrednio w przeglądarce. Dodaj nuty, notatki i komentarze.</div>
                    </div>
                </div>
                <div class="ch-card rounded-[25px] p-8 gap-8 flex-1 items-center flex flex-col md:flex-row">
                    <div class="flex-none w-16 h-16 p-3 bg-primary rounded-full">
                        <span class="icon-40 text-primary-content">headphones</span>
                    </div>
                    <div class="flex-1">
                        <h4 class="pb-2">Kształcenie słuchu</h4>
                        <div>Skorzystaj z narzędzi, które nauczą Cię jak rozpoznać interwał i wyczulą Twoje ucho na rytm. </div>
                    </div>
                </div>
            </div>
            <div class="flex gap-6 flex-col md:flex-row">
                <div class="ch-card rounded-[25px] p-8 gap-8 flex flex-col md:flex-row items-center flex-1">
                    <div class="flex-none w-16 h-16 p-3 bg-primary rounded-full">
                        <span class="icon-40 text-primary-content">playlist_play</span>
                    </div>
                    <div class="flex-1">
                        <h4 class="pb-2">Playlisty</h4>
                        <div>Zorganizuj utwory w playlisty. Dzięki temu Twój zespół nie pomyli repertuaru.</div>
                    </div>
                </div>
                <div class="ch-card rounded-[25px] p-8 gap-8 flex-1 items-center flex flex-col md:flex-row">
                    <div class="flex-none w-16 h-16 p-3 bg-primary rounded-full">
                        <span class="icon-40 text-primary-content">music_note</span>
                    </div>
                    <div class="flex-1">
                        <h4 class="pb-2">Baza wiedzy</h4>
                        <div>Poszerz swoje kompetencje w dziedzinie teorii. Przeprowadź profesjonalną rozśpiewkę.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- EAR TRAINING-->
    <div class="mx-auto w-[104px] h-0 border-[0.5px] border-primary"></div>
    <div class="flex flex-col gap-6 z-10">
        <h1 class="w-full text-center">Skorzystaj z narzędzi do kształcenia słuchu</h1>
        <div class="flex gap-6 flex-col md:flex-row">
            <a class="flex-1 flex flex-col gap-8 items-center rounded-lg text-white hover:text-secondary hover:mb-1 hover:-mt-1 cursor-pointer relative" routerLink="/excercises/intervalrecognition">
                <div class="absolute z-[-1] top-[35%] bottom-0 left-0 right-0 rounded-xl bg-primary overflow-hidden">
                    <svg class="ml-[-65px] mt-[-248px] opacity-50" width="800" height="800" viewBox="0 0 1065 592" fill="none" xmlns="http://www.w3.org/2000/svg" 
                    style="stroke: var(--ch-blue-50)"stroke-width="1.70972" stroke-miterlimit="10" preserveAspectRatio="none">
                        <use href="/assets/images/plain_svg/group96.svg#group96"/>
                    </svg>
                </div>
                    <img class="w-[80%] md:w-[290px] lg:w-[534px]" src="/assets/images/intervalrecognition.svg"/>
                <div class="w-full pb-10 px-8 flex items-center justify-between">
                    <h4 class="flex-1 p-0">Rozpoznawanie interwałów</h4>
                    <span class="flex-none icon-48 w-12 h-12 -rotate-90 ">expand_circle_down</span>
                </div>
            </a>
            <a class="flex-1 flex flex-col gap-8 items-center rounded-lg hover:text-primary hover:mb-1 hover:-mt-1 cursor-pointer relative" routerLink="/excercises/rythmicdictation">
                <div class="absolute z-[-1] top-[35%] bottom-0 left-0 right-0 rounded-xl bg-secondary overflow-hidden">
                    <svg class="ml-[-65px] mt-[-160px] opacity-50" width="800" height="800" viewBox="0 0 1065 592" fill="none" xmlns="http://www.w3.org/2000/svg" 
                    style="stroke: var(--ch-blue-50)"stroke-width="1.70972" stroke-miterlimit="10" preserveAspectRatio="none">
                        <use href="/assets/images/plain_svg/group96.svg#group96"/>
                    </svg>
                </div>
                    <img class="w-[80%] md:w-[290px] lg:w-[534px]" src="/assets/images/rythmicdictation.svg"/>
                <div class="w-full pb-10 px-8 flex items-center justify-between">
                    <h4 class="flex-1 p-0">Dyktando rytmiczne</h4>
                    <span class="flex-none icon-48 w-12 h-12 -rotate-90">expand_circle_down</span>
                </div>
            
            </a>
        </div>
    </div>

    <!-- PLANS -->
    <div class="flex flex-col gap-16 lg:gap-[88px] z-10">
        <div class="mx-auto w-[104px] h-0 border-[0.5px] border-primary"></div>
        <h1 class="w-full text-center">Załóż konto i zyskaj więcej</h1>
        <div class="flex flex-col md:flex-row gap-6">
            <!-- <div class="flex-1 rounded-lg ch-card px-9 py-12" style="box-shadow: 0px 20px 40px 0px #B9C2F226;">
                <img class="h-32 w-auto mx-auto mb-8" src="/assets/images/plain_svg/5.svg"/>
                <h3 class="text-center w-full text-primary">Bez konta</h3>
                <div class="font-body">
                    <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Używanie zasobów udostępnionych przez link i publicznego</p></div>
                    <div class="flex w-full gap-4"><span class="flex-none icon-24">check</span><p class="flex-1">Dostęp do narzędzi kształcenia słuchu i bazy wiedzy</p></div>
                </div>
            </div>
            <div class="flex-1 rounded-lg ch-card px-9 py-12" style="box-shadow: 0px 20px 40px 0px rgba(216, 217, 217, 0.30);">
                <img class="h-32 w-auto mx-auto mb-8" src="/assets/images/plain_svg/6.svg"/>
                <h3 class="text-center w-full text-primary">Darmowe konto</h3>
                <div class="font-body">
                        <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Tworzenie playlist</p></div>
                        <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Tworzenie własnych utworów z limitem do 30</p></div>
                        <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Zapis postępu/wyników w narzędziach od kształcenia słuchu</p></div>
                        <div class="flex w-full gap-4"><span class="flex-none icon-24">check</span><p class="flex-1">Udostępnianie utworów poprzez link lub grupę użytkowników</p></div>
                </div>
            </div>
            <div class="flex-1 rounded-lg ch-card px-9 py-12" style="box-shadow: 0px 20px 40px 0px #B9C2F226;">
                <img class="h-32 w-auto mx-auto mb-8" src="/assets/images/plain_svg/7.svg"/>
                <h3 class="text-center w-full text-primary">Konto premium</h3>
                <div class="font-body mb-14">
                    <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Udostępnianie publiczne</p></div>
                    <div class="flex w-full gap-4"><span class="flex-none icon-24">check</span><p class="flex-1">Brak limitu na tworzenie utworów</p></div>
                </div>
            </div> -->

            <div class="flex-1 rounded-lg ch-card px-9 py-12" style="box-shadow: 0px 20px 40px 0px #B9C2F226;">
                <img class="h-32 w-auto mx-auto mb-8" src="/assets/images/plain_svg/5.svg"/>
                <div class="text-center font-label text-ch-grey-600">FREE</div>
                <h3 class="text-center w-full text-primary">Konto Darmowe</h3>
                <div class="font-body">
                    <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Dostępna przestrzeń:<br><span class="font-boldbody">50MB</span><span class="font-label text-ch-grey-700"> (~30 min nagrań)</span></p></div>
                    <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Tworzenie własnych playlist, utworów</p></div>
                    <div class="flex w-full gap-4"><span class="flex-none icon-24">check</span><p class="flex-1">Udostępnianie utworów i playlist poprzez link lub grupę użytkowników</p></div>
                    <!-- <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Dostęp do narzędzi kształcenia słuchu i bazy wiedzy</p></div> -->
                    <!-- <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Zapis postępu/wyników w narzędziach od kształcenia słuchu</p></div> -->
                </div>
            </div>
            <div class="flex-1 rounded-lg ch-card px-9 py-12" style="box-shadow: 0px 20px 40px 0px rgba(216, 217, 217, 0.30);">
                <img class="h-32 w-auto mx-auto mb-8" src="/assets/images/plain_svg/6.svg"/>
                <div class="text-center font-label text-ch-grey-600">STANDARD</div>
                <h3 class="text-center w-full text-primary">Konto Standardowe</h3>
                <div class="font-body">
                        <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Dostępna przestrzeń:<br><span class="font-boldbody">500MB</span><span class="font-label text-ch-grey-700"> (~300 min nagrań)</span></p></div>
                        <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Udostępnianie publiczne</p></div>
                </div>
            </div>
            <div class="flex-1 rounded-lg ch-card px-9 py-12" style="box-shadow: 0px 20px 40px 0px #B9C2F226;">
                <img class="h-32 w-auto mx-auto mb-8" src="/assets/images/plain_svg/7.svg"/>
                <div class="text-center font-label text-ch-grey-600">PRO</div>
                <h3 class="text-center w-full text-primary">Konto Profesjonalne</h3>
                <div class="font-body mb-14">
                    <div class="flex w-full gap-4 mb-8"><span class="flex-none icon-24">check</span><p class="flex-1">Dostępna przestrzeń:<br><span class="font-boldbody">5GB</span><span class="font-label text-ch-grey-700"> (~3000 min nagrań)</span></p></div>
                    <!-- <div class="flex w-full gap-4"><span class="flex-none icon-24">check</span><p class="flex-1">Możliwość kolaboracji przy edycji utworu z innymi użytkownikami</p></div> -->
                </div>
            </div>
        </div>
        <div class="text-center"><a class="ch-btn ch-btn-filled ch-btn-sec" routerLink="/register">Załóż konto za darmo</a></div>
    </div>
    </div>
</div>