<div *ngIf="!userAccount" class="center-container">
    <div class="relative mx-auto w-full md:max-w-[612px] rounded-xl px-[24px] py-[32px] md:px-[106px] md:py-[64px] flex flex-col gap-[96px] items-center overflow-hidden" style="box-shadow: 0px 30px 60px 0px rgba(114, 116, 212, 0.08);">
        <svg class="absolute z-[-5] left-[11px] top-[203px] md:left-[149px] md:top-[247px] w-[530px] h-[420px] md:w-[530px] md:h-[454px]" viewBox="0 0 612 491" fill="none" xmlns="http://www.w3.org/2000/svg">
            <use href="/assets/images/plain_svg/symbol.svg#symbol"/>
        </svg>
        <div *ngIf="fsm==='form' || fsm==='pending'" class="flex flex-col gap-[40px] md:gap-[56px] items-center">
            <div class="flex flex-col gap-6 items-center">
                <h1 class="text-primary pb-0 text-center">Ustaw&nbsp;nowe&nbsp;hasło</h1>
            </div>

            <div class="flex flex-col gap-10 items-center">
                <div class="flex flex-col gap-2">
                    <input type="password" id="resetpass-password-input" class="choiry-input" #password required placeholder="Hasło" 
                        (input)="passwordValidity=validationService.validatePassword(password.value,passwordrepeat.value,true)" />
                    <div *ngIf="passwordValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="passwordValidity"></p>
                    </div>
                </div>
                <input type="password" id="resetpass-password-repeat-input" class="choiry-input" #passwordrepeat required placeholder="Powtórz hasło" 
                    (input)="passwordValidity=validationService.validatePassword(password.value,passwordrepeat.value,true)" />
            </div>

            <div *ngIf="fsm==='form'" class="ch-btn ch-btn-filled ch-btn-sec" (click)="resetPassword()">Ustaw&nbsp;hasło</div>
            <div *ngIf="fsm==='pending'"><i class="icon icon-48 animate-spin">progress_activity</i></div>
        </div>
        <div *ngIf="fsm=='success'">
            <h1 class="text-center text-primary">Twoje hasło zostało zmienione</h1>
        </div>
        <div *ngIf="fsm=='error'">
            <h1 class="text-center text-primary">Nie mogliśmy zmienić Twojego hasła.</h1>
            <p>Być może link do zmiany hasła wygasł?</p>
        </div>
    </div>
</div>