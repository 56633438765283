<div class="w-full flex gap-6">
    <app-menu></app-menu>
    <div class="flex-1">
        <div class="bg-white pb-[56px] flex flex-col gap-12 rounded-b-xl">
        <div class="h-16 bg-ch-grey-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
            <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1"><div class="icon icon-24">arrow_left_alt</div></div>
            <div class="font-boldbody">Płatność</div>
            <div class="w-8 h-8"></div>
        </div>
        <div class="flex flex-col gap-12">
            <div class="flex flex-col gap-12 px-12 items-center">
                <div id="subscription-info" class="font-title"></div>
                <div class="flex flex-col items-center gap-4">
                    <div class="font-boldbody">Wykorzystana przestrzeń</div>
                    <div class="bg-ch-grey-50 rounded-full h-3 w-[200px]">
                        <div class="bg-secondary rounded-full h-3 " [style]="'width:'+(userAccount.usedSpace/maxStorage)*200+'px;'"></div>
                    </div>
                    <div class="font-boldbody">{{usedStorageDescription}}</div>
                </div>
                <!--<p>Read about subscription plans <a href="#">here</a></p>-->
                <div class="flex flex-col gap-8">
                    <div class="font-title">Aktywuj subskrypcję kodem</div>
                    <div class="flex gap-10">
                        <input type="text" class="choiry-input w-[400px]" #subscriptioncode  (input)="checkCode(subscriptioncode.value)" (keyup.enter)="activateSubscriptionCode(subscriptioncode.value)" placeholder="Wprowadź kod"/>
                        <div class="ch-btn ch-btn-outline ch-btn-sec" (click)="activateSubscriptionCode(subscriptioncode.value);">Wykorzystaj&nbsp;kod</div> 
                    </div>
                    <div *ngIf="checkedCode && isCodeValid && !enteredCode.used" class="w-52 bg-success rounded-lg text-success-content text-center p-1"><p class="font-boldbody">Kod poprawny!</p><br><b>{{enteredCode.subscriptionPeriod/86400000}} dni</b><br><p class="font-label">Ważny do: {{validUntilString}}</p></div>
                    <div *ngIf="checkedCode && isCodeValid && enteredCode.used && enteredCode.usedByUserId==userAccount.userId" class="w-52 bg-info rounded-lg text-info-content text-center p-1"><p class="font-boldbody">Wykorzystałeś już ten kod!</p></div>
                    <div *ngIf="checkedCode && isCodeValid && enteredCode.used && enteredCode.usedByUserId=='0'" class="w-52 bg-info rounded-lg text-info-content text-center p-1"><p class="font-boldbody">Kod został już wykorzystany!</p></div>
                    <div *ngIf="checkedCode && !isCodeValid" class="w-52 bg-error rounded-lg text-error-content text-center p-1"><p class="font-boldbody">Niepoprawny kod!</p></div>
                </div>
                <div><a class="ch-btn ch-btn-filled ch-btn-sec" [routerLink]="'/purchase/new'">Dokonaj zakupu subskrypcji</a></div>
            </div>
            </div>
        </div>
    </div>
</div>