<div *ngIf="!userAccount" class="center-container">
    <div class="relative mx-auto w-full md:max-w-[612px] rounded-xl px-[24px] py-[32px] md:px-[106px] md:py-[64px] flex flex-col gap-[96px] items-center overflow-hidden" style="box-shadow: 0px 30px 60px 0px rgba(114, 116, 212, 0.08);">
            <svg class="absolute z-[-5] left-[11px] top-[203px] md:left-[149px] md:top-[247px] w-[530px] h-[420px] md:w-[530px] md:h-[454px]" viewBox="0 0 612 491" fill="none" xmlns="http://www.w3.org/2000/svg">
                <use href="/assets/images/plain_svg/symbol.svg#symbol"/>
            </svg>
        <div class="flex flex-col gap-[40px] md:gap-[56px] items-center w-full">
            <h1 class="text-primary pb-0">Zaloguj się</h1>
            <div class="flex flex-col gap-10 w-full">
                <input type="text" id="login-email-input" class="mx-auto choiry-input w-full"
                required placeholder="E-mail"  />
                <input type="password" id="login-password-input" class="mx-auto choiry-input w-full"
                required placeholder="Hasło" (keyup.enter)="authorize()" />
            </div>
        </div>
        <div class="flex flex-col gap-2 items-center">
            <div class="text-center">Nie posiadasz jeszcze konta? <a href="/register" class="font-bold text-primary">Zarejestruj&nbsp;się</a></div>
            <div class="text-center">Zapomniałeś hasła? <a href="/passwordforgotten" class="font-bold text-primary">Zresetuj&nbsp;hasło</a></div>
        </div>
        <div class="ch-btn ch-btn-filled ch-btn-sec" (click)="authorize()">Zaloguj się</div>

         <div class="flex flex-col gap-[56px] items-center">
            <div class="flex items-center gap-8">
                <div class="bg-ch-grey-100 h-[1px] w-[79px] md:w-[157px]"></div>
                <div class="text-ch-grey-500 flex-none">lub</div>
                <div class="bg-ch-grey-100 h-[1px] w-[79px] md:w-[157px]"></div>
            </div>
            <div class="flex flex-col gap-2 items-center">
                <button class="py-[10px] px-3 flex gap-[10px] items-center h-10 bg-[#F2F2F2] rounded-full hover:opacity-90" (click)="login('google')">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 48 48" xmlns:xlink="http://www.w3.org/1999/xlink" style="display: block;">
                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                    </svg>
                    <div class="text-[#1F1F1F] font-roboto">Sign in with Google</div>
                </button>
                
                <!-- <div class="ch-btn ch-btn-outline ch-btn-sec" (click)="login('google')">Zaloguj się z Google</div> -->
                <!-- <div class="ch-btn ch-btn-outline ch-btn-sec" (click)="login('facebook')">Zaloguj się z Facebook</div> -->
            </div>
        </div>

        </div>
</div>