import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Playlist, Track } from '@app/_models';
import { map, Observable } from 'rxjs';
import { AccountService } from '@app/authserver/_services';

@Injectable({ providedIn: 'root' })
export class TrackService {
    constructor(private http: HttpClient,private accountService: AccountService) { }

    getById(id: number,linkKey: string) {
        if(linkKey===undefined)
            return this.http.get<Track>(`${environment.apiUrl}/audio-tr/${id}`);
        else
            return this.http.get<Track>(`${environment.apiUrl}/audio-tr/link/${id}/${linkKey}`);
    }

    update(track: Track) {
        var update = new Track();
        update.id = track.id;
        update.mute = track.channel.mute;
        update.name = track.name;
        update.offset = track.offset;
        update.solo = track.channel.solo;
        update.volumeDb = track.volumeDb;
        update.trimLeft = track.trimLeft;
        update.trimRight = track.trimRight;
        update.ordinalNumber = track.ordinalNumber;
        return this.http.post(`${environment.apiUrl}/audio-tr/update`, update);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/audio-tr/delete/${id}`).pipe(map(() => {this.accountService.getSelfAccountFromApi().subscribe()}));
    }

    download(id: number,linkKey: string): Observable<Blob> {
        if(linkKey===undefined)
            return this.http.get(`${environment.apiUrl}/audio-tr/download/${id}`, { responseType: 'blob' });
        else
            return this.http.get(`${environment.apiUrl}/audio-tr/download/link/${id}/${linkKey}`, { responseType: 'blob' });
        }
}