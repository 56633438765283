import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { LoginForm } from '../_models/loginForm';
import { RegisterForm } from '../_models/registerForm';
import { ResetPasswordForm } from '../_models/resetpasswordForm';
import { Account, ChangePasswordForm } from '../_models';
import { User } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class AuthServerService {
    constructor(private http: HttpClient,) {}


    reqRegister(mrpRegisterForm: RegisterForm) :Observable<any> {
        return this.http.post(`${environment.authUrl}/register`,mrpRegisterForm);
    }

    reqEmailVerify(code: string) :Observable<any> {
        return this.http.get(`${environment.authUrl}/emailverify/${code}`);
    }

    reqResetPassword(resetPasswordForm: ResetPasswordForm) : Observable<any>{
        return this.http.post(`${environment.authUrl}/resetpass`,resetPasswordForm);
    }

    reqSendPasswordResetLink(email: string) :Observable<any> {
        return this.http.post(`${environment.authUrl}/sendpassresetlink`,email);
    }

    reqIdpLogin(idProvider) :Observable<any> {
        return this.http.get(`${environment.authUrl}/oauth2/${idProvider}/login`);
    }

    reqAuthorize(loginForm: LoginForm) :Observable<any> {
        return this.http.post(`${environment.authUrl}/oauth2/authorize`,loginForm);
    }

    reqToken(code: string, idProvider: string){
        return this.http.post(`${environment.authUrl}/oauth2/${idProvider}/token`,code);
    }

    reqRefresh(refresh_token: string){
        return this.http.post(`${environment.authUrl}/oauth2/refresh`,refresh_token);
    }

    reqLogout(sid){
        return this.http.post(`${environment.authUrl}/logout`,sid);
    }

    reqNewsletterSub(code: string) : Observable<any>{
        return this.http.get(`${environment.authUrl}/newsletter/subscribe/${code}`);
    }

    reqNewsletterUnsub(code: string) : Observable<any>{
        return this.http.get(`${environment.authUrl}/newsletter/unsubscribe/${code}`);
    }

    reqSendNewsletterSubLink(email: string) : Observable<any>{
        return this.http.post(`${environment.authUrl}/newsletter/sendsubscriptionlink`,email);
    }

    reqGetStatement(name: string): Observable<any>{
        return this.http.get(`${environment.authUrl}/statement/${name}`);
    }

    reqDeleteUser() :Observable<any> {
        return this.http.delete(`${environment.authUrl}/deleteuser`);
    }

    reqChangePassword(form: ChangePasswordForm) :Observable<any> {
        return this.http.post(`${environment.authUrl}/changepass`,form);
    }  

    reqSearchUser(query: string){
        return this.http.get<User[]>(`${environment.authUrl}/details/search/${query}`);
    }

    reqUpdateUser(firstName,lastName,avatarUrl){
        let account: Account;
        account.firstName = firstName;
        account.lastName = lastName;
        account.avatarUrl = avatarUrl;
        return this.http.post(`${environment.authUrl}/updateuser`,account);
    }

    reqDeleteAvatar() :Observable<any> {
        return this.http.delete(`${environment.authUrl}/deleteavatar`);
    }
}