import { Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { Router } from '@angular/router';
import { AccountService } from '@app/authserver/_services';
import { Account } from '@app/authserver/_models';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html'
})
export class AppNavbarComponent implements OnInit {
  userAccount: Account;
  showMenu: boolean = false;
  scrollTop: boolean = true;

  constructor(
      private router: Router,
      private accountService: AccountService
  ) {
    this.accountService.account.subscribe(x => {this.userAccount = x;});
  }

  ngOnInit() :void {
  }

  openModal(id: string) {
    document.getElementById(id).style.display = 'block';
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
      this.scrollTop = (window.scrollY < 10);
  }

  closemenu(event){
      this.showMenu=false;
  }

  login() {
    this.accountService.login();
  }

  logout() {
    this.accountService.logout();
    this.router.navigate(['/']);
  }

}
