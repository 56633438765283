import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProgressComponent } from '@app/_global/progress/progress.component';
import { ScoreComponent } from '@app/_global/score/score.component';
import { ExerciseLesson } from '@app/_models/exerciselesson';
import { AlertService } from '@app/_services';
import { AudioService } from '@app/_services/audio.service';
import { ExerciseService } from '@app/_services/exercise.service';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { AppMenuComponent } from '@app/layout';
import * as Tone from 'tone';

@Component({
  standalone: true,
  imports: [CommonModule,AppMenuComponent,ProgressComponent,ScoreComponent],
  selector: 'app-intervalrecognition',
  templateUrl: './intervalrecognition.component.html',
  styleUrls: ['./intervalrecognition.component.css']
})
export class IntervalRecognitionComponent implements OnInit,OnDestroy {
    userAccount: Account;
    lessons : ExerciseLesson[];
    lessonsFiltered: ExerciseLesson[];
    selectedLesson :ExerciseLesson = null;
    showOptions = false;
    allowedIntervals = [];
    lessonTypeFilter = 'melodic';
    /*
    * ready
    * playing
    * answer
    * feedback-wrong
    * feedback-right
    */
    fsm = "ready";
    firstNote = 0;
    secondNote = 0;
    lessonProgress;
    lessonLength;
    correctAnswers;
    sampler: Tone.Sampler;

    @ViewChild("score") score: ScoreComponent;

    constructor(private audioService: AudioService,
                private accountService: AccountService,
                private exerciseService: ExerciseService,
                private alertService: AlertService) {
        this.accountService.account.subscribe(x => this.userAccount = x);
        this.exerciseService.getExerciseLessons("interval_recognition").subscribe(
            (res)=>{
            this.lessons = res;
            this.changeState("ready");
            this.updateLessonFilter('melodic')
        });
      }

    ngOnInit() {
        this.sampler = new Tone.Sampler({urls: {A3: "A1.mp3",A4: "A2.mp3",},baseUrl: "https://tonejs.github.io/audio/casio/",}).toDestination();
    }

    updateLessonFilter(filter: string){
        this.lessonTypeFilter = filter;
        this.lessonsFiltered= this.lessons.filter((l)=>{return JSON.parse(l.data).type==this.lessonTypeFilter})
    }

    selectLesson(lesson: ExerciseLesson){
        if(this.fsm=='lesson-result')
            this.changeState('ready');
        if(this.fsm=="ready"){
            if(lesson==null){
                this.selectedLesson=null;
                this.allowedIntervals = [];
                this.updateAllowedIntervals();
            } else{
                this.selectedLesson = lesson;
                var lessonData = JSON.parse(lesson.data);
                this.allowedIntervals = lessonData.allowedIntervals;
                this.updateAllowedIntervals();
            }
        }
        else{
            this.alertService.info("Zatrzymaj obecną lekcję, aby ją zmienić")
        }
    }

    start(){
        this.changeState("playing");
    }

    stop(){
        this.changeState("ready");
    }

    intervalClick(interval){
        if(this.fsm=="ready"){
            if(this.selectedLesson!=null)
                this.selectLesson(null);
            if(this.allowedIntervals.includes(interval))
                this.allowedIntervals.splice(this.allowedIntervals.indexOf(interval),1);
            else
                this.allowedIntervals.push(interval);
            this.updateAllowedIntervals();
        } 
        else if(this.fsm=="answer") {
            if(!this.allowedIntervals.includes(interval))
                return;
            if(interval == Math.abs(this.firstNote-this.secondNote))
                this.changeState("feedback-right");
            else
                this.changeState("feedback-wrong");
        }
    }

    changeState(newState){
        if(newState=="ready"){
            this.clearStatistics();
            this.updateAllowedIntervals();
        }
        if(newState=="playing"){
            this.score.reset();
            this.lessonProgress+=1;
            this.generateInterval()
            this.playInterval()
        }
        if(newState=="feedback-wrong"){
            this.score.drawNote(120,this.firstNote,"whole");
            this.score.drawNote(220,this.secondNote,"whole");
            if(this.lessonProgress==this.lessonLength)
                this.delay(1000).then(()=>this.changeState("lesson-result"))
            else
                this.delay(1000).then(()=>this.changeState("playing"))
        }
        if(newState=="feedback-right"){
            this.correctAnswers+=1;
            this.score.drawNote(120,this.firstNote,"whole");
            this.score.drawNote(220,this.secondNote,"whole");
            if(this.lessonProgress==this.lessonLength)
                this.delay(1000).then(()=>this.changeState("lesson-result"))
            else
                this.delay(1000).then(()=>this.changeState("playing"))
        }
        if(newState=="lesson-result"){
            if(this.selectedLesson!=null){
                if(this.selectedLesson.bestScore<this.correctAnswers){
                    var oldBestScore = this.selectedLesson.bestScore;
                    this.selectedLesson.bestScore = this.correctAnswers;
                    this.exerciseService.updateLessonProgress(this.selectedLesson)
                    .subscribe(()=>{},()=>{this.selectedLesson.bestScore=oldBestScore;});
                }
            }
        }
        this.fsm=newState;
    }

    updateAllowedIntervals(){
        for(let i=1;i<=12;i++){
            let elem = document.getElementById("interval-"+String(i));
            elem.classList.remove("interval-allowed")
            if(this.allowedIntervals.includes(i)){
                elem.classList.add("interval-allowed")
            }
        }
    }

    generateInterval(){
        let interval = this.allowedIntervals[Math.floor(Math.random() * (this.allowedIntervals.length))]
        //C4=60
        if(Math.random()>0.5){
            this.firstNote = Math.floor(Math.random()*(13)+60);
            this.secondNote = this.firstNote + interval;
        }
        else {
            this.firstNote = Math.floor(Math.random()*(13)+72);
            this.secondNote = this.firstNote - interval;
        }
    }

    clearStatistics(){
        this.lessonProgress = 0;
        this.correctAnswers = 0;
        this.lessonLength = 100;
    }

    playInterval(){
        //play melodic interval
        this.changeState("repeat");
        this.audioService.startAudioContext();
        Tone.Transport.cancel();
        Tone.Transport.schedule(() => this.sampler.triggerAttackRelease(Tone.Frequency(this.firstNote,"midi").toFrequency(), 0.9),0.0);
        Tone.Transport.schedule(() => this.sampler.triggerAttackRelease(Tone.Frequency(this.secondNote,"midi").toFrequency(), 0.9),1.0);
        this.delay(2000).then(() => this.changeState("answer"));
        this.audioService.restartTransport()
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    @HostListener('unloaded')
    ngOnDestroy() {
      this.audioService.clean();
      this.sampler.dispose();
    }

}