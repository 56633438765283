import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

@Component({ 
    selector: 'mobilemenu-modal', 
    templateUrl: 'mobilemenumodal.component.html', 
    styleUrls: ['mobilemenumodal.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class MobileMenuModalComponent implements OnInit, OnDestroy {
    environment = environment;
    private element: any;
    @Input() id: string;

    constructor(
        private el: ElementRef,
        private router: Router) {
            this.element = el.nativeElement;
            router.events.subscribe(()=>this.close());
    }

    ngOnInit(): void {
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }
       
        document.body.appendChild(this.element);

    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.element.remove();
    }

    // close modal
    close(): void {
        this.element.style.display = 'none';
    }
}