import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Article } from '@app/_models';
import { BlogService, AlertService } from '@app/_services';
import { ValidationService } from '@app/_services/validation.service';

@Component({ templateUrl: 'editarticle.component.html' })
export class EditArticleComponent implements OnInit {
    id: number;
    article: Article;
    sub: any;

    constructor(
        private route: ActivatedRoute,
        private blogService: BlogService,
        private alertService: AlertService,
        public validationService: ValidationService
    ) {  }


    ngOnInit() {
        this.sub=this.route.params.subscribe(params => {
            this.id = +params['id']; // (+) converts string 'id' to a number
            this.blogService.getArticleById(this.id).subscribe(x => { this.article = x;});
          })
    }
    ngOnDestroy() {
        this.sub.unsubscribe();
      }
    
    updateArticle(){
        var update = new Article;
        update.id = this.article.id;
        update.description=(<HTMLInputElement>document.getElementById("article-description-input")).value;
        update.title=(<HTMLInputElement> document.getElementById("article-title-input")).value;
        update.tags=(<HTMLInputElement> document.getElementById("article-tags-input")).value;
        update.body=(<HTMLInputElement> document.getElementById("article-body-input")).value;
        update.published=(<HTMLInputElement> document.getElementById("article-published-input")).checked;
        if(this.validationService.validateName(update.title)&&this.validationService.validateDescription(update.description)){
            this.blogService.updateArticle(update).subscribe(() => this.alertService.success("Article description updated"));
        } else {
            this.alertService.error("Check validity of input data!");
        }
    }
}