<div *ngIf="showMetronome" class="sm:hidden lg:fixed h-[300px] w-64 top-52 right-0 z-[200]">
    <div class="flex h-full w-full">
        <div class="flex-none h-16 p-2 w-16 bg-primary rounded-l-lg"><span class="mx-auto icon-btn icon icon-48" (click)="closeMetronome()">timer_off</span></div>
        <div class="h-full w-48 bg-ch-blue-500 rounded-bl-lg py-4 px-2 flex flex-col gap-2 items-center">
            <div class="w-full flex flex-none items-center" >
                <span *ngIf="!isPlaying" class="flex-none icon-btn icon icon-48 text-secondary" (click)="play()">play_arrow</span>
                <span *ngIf="isPlaying" class="flex-none icon-btn icon icon-48 text-secondary" (click)="stop()">stop</span>
                <div class="font-title flex-1 text-center">{{currentBPM}} BPM</div>
            </div>
            <div class="flex-none w-32 h-32 bg-ch-blue-50 rounded-full opacity-30" id="metronome-blinker"></div>
            <div>
                <input class="w-full vol-slider" type="range" #metronomevolume (input)="setMetronomeVolume(metronomevolume.value);" (change)="setMetronomeVolume(metronomevolume.value)" min="-48" max="12" value="0">
            </div>
            <div class="flex-none w-full ch-btn ch-btn-filled ch-btn-sec" (click)="tapTempo()">TAP</div>
        </div>
    </div>
</div>

<div *ngIf="!showMetronome" class="sm:hidden lg:fixed h-16 w-16 p-2 top-52 right-0 z-[200] bg-primary rounded-l-lg"><span class="mx-auto icon-btn icon icon-48" (click)="openMetronome()">timer</span></div>