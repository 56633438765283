import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProgressComponent } from '@app/_global/progress/progress.component';
import { Track } from '@app/_models';
import { TrackService } from '@app/_services';
import { environment } from '@environments/environment';
import * as Tone from 'tone';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'player-audiotrack',
  templateUrl: './audiotrack.component.html',
  styleUrls: ['./audiotrack.component.less']
})
export class AudioTrackComponent implements OnChanges{
  environment = environment;
  @Input() isEditor:boolean;
  @Input() track:Track;
  @Input() timelineScale:number;
  @Input() timelineLength:number;
  @Input() isLoading:boolean;

  @Output() onTrackUpdate = new EventEmitter<void>();
  @Output() onTrackLoad = new EventEmitter<void>();

  isTrimmingTrackLeft: boolean = false;
  isTrimmingTrackRight: boolean = false;
  isDraggingTrack: boolean = false;
  movingStartX: number;

  constructor( private trackService: TrackService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.isLoading)
      if(changes.isLoading.previousValue != false && changes.isLoading.currentValue == false)
        this.generateWaveform();
    if(changes.timelineScale && !changes.timelineScale.firstChange)
      if(changes.timelineScale.previousValue != changes.timelineScale.currentValue)
        this.generateWaveform()
  }

  generateWaveform(){
    var canvas = document.createElement('canvas');
    var waveContext = canvas.getContext("2d");
    var canvasHeight = 96;
    var canvasWidth = Math.floor(this.track.player.buffer.duration*this.timelineScale);
    var values = this.track.player.buffer.getChannelData(0);
    var sampleStep= Math.floor(values.length/canvasWidth);
    var sampleSubstep= Math.floor(sampleStep/10);
    waveContext.canvas.width = canvasWidth;
    waveContext.canvas.height = canvasHeight;
    waveContext.strokeStyle = "black";
    waveContext.clearRect(0, 0, canvasWidth, canvasHeight);
    waveContext.beginPath();
    for (var i = 0, len = values.length; i < len-sampleStep; i+=sampleStep) {
      var avgVal =0;
      for (var j = 0;j<10;j++)
        avgVal+=Math.abs(values[i+j*sampleSubstep]);
      avgVal /=10;
      avgVal = Math.log10(avgVal+0.1)+1;  //funkcja nieliniowa
      waveContext.moveTo(i/sampleStep, -(avgVal*canvasHeight*0.95/2)+(canvasHeight/2));
      waveContext.lineTo(i/sampleStep, avgVal*canvasHeight*0.95/2+(canvasHeight/2));
    }
    waveContext.closePath();
    waveContext.stroke();
    canvas.style.marginRight = (Math.floor((-this.track.trimRight*this.timelineScale))).toString()+"px";
    canvas.style.marginLeft = (Math.floor((-this.track.trimLeft*this.timelineScale))).toString()+"px";
    document.getElementById("track-waveform-"+this.track.id).style.marginLeft = (Math.floor(this.track.offset*this.timelineScale)).toString()+"px";
    document.getElementById("track-waveform-content-"+this.track.id).innerHTML="";
    document.getElementById("track-waveform-content-"+this.track.id).appendChild(canvas);
  }

  dragTrack(e: any, track: Track) {
    if(!this.isEditor)
      return;
    this.movingStartX = e.clientX;
    this.isDraggingTrack = true;
  }

  trimTrackRight(e: any, track: Track){
    if(!this.isEditor)
      return;
    this.movingStartX = e.clientX;
    this.isTrimmingTrackRight = true;
  }

  trimTrackLeft(e: any, track: Track){
    if(!this.isEditor)
      return;
    this.movingStartX = e.clientX;
    this.isTrimmingTrackLeft = true;
  }

  @HostListener('document:mousemove',['$event'])
  trimDragTrackUpdate(e: any){
    if(this.isTrimmingTrackRight){
      var timeMargin=this.track.trimRight+((this.movingStartX -e.clientX)/this.timelineScale);
      if(timeMargin<0)
        timeMargin=0;
      if(this.track.player.buffer.duration-this.track.trimLeft-timeMargin<0.1)
        timeMargin=this.track.player.buffer.duration-this.track.trimLeft-0.1;
      document.getElementById("track-waveform-content-"+this.track.id).getElementsByTagName("canvas")[0].style.marginRight = (Math.floor((-timeMargin*this.timelineScale))).toString()+"px";

      if(e.buttons==0){
        this.track.trimRight = timeMargin;
        this.updateTrack(this.track);
        this.onTrackUpdate.emit();
        this.isTrimmingTrackRight=false;
      }
      return false;
    }
    if(this.isTrimmingTrackLeft){
      var minDrag = -this.track.offset;
      var maxDrag = this.track.player.buffer.duration-this.track.trimRight-this.track.trimLeft-0.1;
      var minTrim = -this.track.trimLeft;
      var maxTrim = this.track.player.buffer.duration-this.track.trimRight-this.track.trimLeft -0.1;
      var deltaTimeMouse = ((e.clientX - this.movingStartX)/this.timelineScale);

      if(deltaTimeMouse<Math.max(minTrim,minDrag))
        deltaTimeMouse =Math.max(minTrim,minDrag);
      if(deltaTimeMouse>Math.min(maxTrim,maxDrag))
        deltaTimeMouse =Math.min(maxTrim,maxDrag);

      var newTrim = this.track.trimLeft + deltaTimeMouse;
      var newOffset = this.track.offset + deltaTimeMouse;

      document.getElementById("track-waveform-content-"+this.track.id).getElementsByTagName("canvas")[0].style.marginLeft = (Math.floor((-newTrim*this.timelineScale))).toString()+"px";
      document.getElementById("track-waveform-"+this.track.id).style.marginLeft = (Math.floor(newOffset*this.timelineScale)).toString()+"px";

      if(e.buttons==0){
        this.track.trimLeft = newTrim;
        this.track.offset = newOffset;
        this.updateTrack(this.track);
        this.onTrackUpdate.emit();
        this.isTrimmingTrackLeft=false;
      }
      return false;
    }
    if(this.isDraggingTrack){
      var offsetTime = this.track.offset + ((e.clientX - this.movingStartX)/this.timelineScale);
      if(offsetTime<0)
        offsetTime=0;
      if(this.track.player.buffer.duration-this.track.trimRight-this.track.trimLeft+offsetTime>this.timelineLength+5)
        offsetTime=this.timelineLength+5 - (this.track.player.buffer.duration-this.track.trimRight-this.track.trimLeft);
      document.getElementById("track-waveform-"+this.track.id).style.marginLeft = (Math.floor(offsetTime*this.timelineScale)).toString()+"px";

      if(e.buttons==0){
        this.track.offset = offsetTime
    
        this.updateTrack(this.track);
        this.onTrackUpdate.emit();
        this.isDraggingTrack=false;
      }
      return false;
    }
    return true;
  }

  updateTrack(track: Track){
    if(!this.isEditor)
      return;
    this.trackService.update(track).subscribe(()=>this.onTrackUpdate.emit());
  }
}
