import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubscriptionCodeService {
    constructor(private http: HttpClient) { }

    activateSubscriptionCode(code: string) : Observable<any> {
        return this.http.post(`${environment.apiUrl}/subscriptioncode/activate`, code);
    }

    checkSubscriptionCode(code: string) : Observable<any> {
        return this.http.post(`${environment.apiUrl}/subscriptioncode/check`, code);
    }
}