import { Component, OnInit } from '@angular/core';
import { AlertService, PurchaseService, ValidationService } from '@app/_services';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';

@Component({
  selector: 'app-purchase-about',
  templateUrl: './purchaseabout.component.html',
})
export class PurchaseAboutComponent implements OnInit {
    userAccount: Account;

    constructor(
      private alertService: AlertService,
      private accountService: AccountService,
      private purchaseService: PurchaseService,
      public validationService: ValidationService) { 
        this.accountService.account.subscribe(x => this.userAccount = x);
      }

    ngOnInit() {
      document.getElementById("subscription-info").innerHTML=this.accountService.subscriptionInfo();
    }

}
