export class SubscriptionCode {
    id: number;
    code: string;
    used: boolean;
    creationDate: number;
    validUntilDate: number;
    activationDate: number;
    usedByUserId: string;
    standardPlanPeriod: number;
    proPlanPeriod: number;
}