<div class="sharing-modal flex flex-col gap-9">
    <div class="flex items-center justify-between">
        <h3 class="p-0">{{title}}</h3>
        <div class="flex items-center gap-2">
            <span class="font-label">Dostęp publiczny</span> <label class="switch-small"><input #publictoggle type="checkbox" [checked]="isPublic" (change)="onPublicShare.emit(publictoggle.checked);"><span class="slider slider-small"></span></label>
        </div>
    </div>
    <div class="flex flex-col gap-6">
        <div class="w-full">
            <input type="text" class="w-full search-input font-label relative" id="sharingmodal-search-input" placeholder="Dodaj użytkowników lub grupy" (focus)="showSearchResults=true;" (focusout)="hideSearchResults();" (input)="updateSearchResults()"/><br>
            <ul *ngIf="showSearchResults" class="absolute z-20 p-4 flex flex-col gap-4 left-10 right-10 bg-white rounded-md shadow-lg">
            <li *ngFor="let group of filteredGroups" class="hover:bg-blue-50 cursor-pointer" (click)="createShare(null,group.id,false)">
                <div class="h-[34px] flex gap-4 items-center">
                    <usergroup-avatar size="small" [avatarUrl]="group.avatarUrl" [usergroupId]="group.id" [usergroupName]="group.name"></usergroup-avatar>
                    <div class="font-label !font-bold text-ch-grey-800">{{group.name}}</div>
                </div>
            </li>
            <li *ngFor="let user of filteredUsers" class="h-[34px] hover:bg-blue-50 cursor-pointer">
                <div (click)="createShare(user.id,null,false)">
                    <div class="flex gap-4 items-center">
                        <avatar type="user-avatar" size="small" [avatarUrl]="user.avatarUrl" [userId]="user.id" [firstName]="user.firstName" [lastName]="user.lastName"></avatar>
                        <div class="flex flex-col gap-1">
                            <div class="font-label !font-bold text-ch-grey-800">{{user.firstName}} {{user.lastName}}</div>
                            <div class="font-label text-ch-grey-600">{{user.username}}</div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        </div>
        <div class="flex flex-col gap-4">
            <div class="font-boldbody">Osoby z dostępem</div>
            <ul class="flex flex-col gap-4">
                <li>
                    <div class="flex justify-between items-center">
                        <div class="flex gap-4 items-center h-[48px]">
                            <avatar type="user-avatar" size="normal" [avatarUrl]="userAccount.avatarUrl" [userId]="userAccount.userId" [firstName]="userAccount.firstName" [lastName]="userAccount.lastName"></avatar>
                            <div class="flex flex-col gap-1">
                                <div class="font-label !font-bold text-ch-grey-800">{{userAccount.firstName}} {{userAccount.lastName}}</div>
                                <div class="font-label text-ch-grey-600">{{userAccount.email}}</div>
                            </div>
                        </div>
                        <div class="font-body">właściciel</div>
                    </div>
                </li>
                <li *ngFor="let share of userShares">
                    <div class="flex justify-between items-center">
                        <div class="flex gap-4 items-center h-[48px]">
                            <avatar type="user-avatar" size="normal" [avatarUrl]="share.user.avatarUrl" [userId]="share.userId" [firstName]="share.user.firstName" [lastName]="share.user.lastName"></avatar>
                            <div class="flex flex-col gap-1">
                                <div class="font-label !font-bold text-ch-grey-800">{{share.user.firstName}} {{share.user.lastName}}</div>
                                <div class="font-label text-ch-grey-600">{{share.user.username}}</div>
                            </div>
                        </div>
                        <div><span class="icon-btn icon-24 text-primary" (click)="deleteShare(share.id)">delete</span></div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="flex flex-col gap-4">
            <div class="font-boldbody">Grupy z dostępem</div>
            <ul class="flex flex-col gap-4">
                <li *ngFor="let share of groupShares">
                    <div class="flex justify-between items-center">
                        <div class="h-[48px] flex gap-4 items-center">
                            <avatar type="usergroup-avatar" size="normal" [userGroup]="share.userGroup"></avatar>
                            <div class="font-label !font-bold text-ch-grey-800">{{share.userGroup.name}}</div>
                        </div>
                        <div><span class="icon-btn icon-24 text-primary" (click)="deleteShare(share.id)">delete</span></div>
                    </div>
                </li>
            </ul>
        </div>
        <div *ngIf="linkShares && linkShares.length>0" class="flex flex-col gap-4">
            <div class="font-boldbody">Udostępniony link</div>
            <ul class="flex flex-col gap-4">
                <li *ngFor="let share of linkShares">
                    <div class="flex justify-between items-center">
                        <div class="h-[48px] flex gap-4 items-center">
                            <div class="icon icon-24">link</div>
                            <div class="font-label !font-bold text-ch-grey-800 flex items-center"><a href="{{sharingLink}}{{share.linkKey}}">{{sharingLink}}{{share.linkKey}}</a> <span class="icon-btn icon-20 ml-2" (click)="copyToClipboard(sharingLink+share.linkKey)">content_copy</span></div>
                        </div>
                        <div><span class="icon-btn icon-24 text-primary" (click)="deleteShare(share.id)">delete</span></div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="flex justify-between">
        <div class="flex-none ch-btn ch-btn-outline ch-btn-sec !pl-5 !pr-6" (click)="copyLinkShare()"><div class="flex items-center gap-2"><div class="icon icon-24 text-secondary">link</div><div>Kopiuj link</div></div></div>
        <div class="flex-none ch-btn ch-btn-filled ch-btn-sec" (click)="close()">Gotowe</div>
    </div>
</div>
<div class="sharing-modal-background"></div>