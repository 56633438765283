<div class="w-full flex gap-6">
    <app-menu></app-menu>
    <div class="flex-1">
        <div class="bg-white pb-[56px] flex flex-col gap-12 rounded-b-xl">
            <div class="h-16 bg-ch-grey-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
                <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1">
                    <div class="icon icon-24">arrow_left_alt</div>
                </div>
                <div class="font-boldbody">Ustawienia</div>
                <div class="w-8 h-8"></div>
            </div>
            <div class="flex flex-col items-center gap-[96px]">
                <div class="flex flex-col gap-4 items-center">
                    <div id="avatar-container">
                        <avatar *ngIf="showAvatar" type="user-avatar" [size]="'full'" [avatarUrl]="userAccount.avatarUrl"
                            [userId]="userAccount.userId" [firstName]="userAccount.firstName"
                            [lastName]="userAccount.lastName" [showEditBadge]="true"></avatar>
                    </div>
                    <div class="font-title">{{userAccount.firstName}} {{userAccount.lastName}}</div>
                </div>
                <div class="flex flex-col gap-8 items-center">
                    <div class="font-headline">Zmień swoje dane</div>
                    <div class="flex flex-col gap-10 items-center">
                        <div>
                            <input (input)="validationService.validateFirstName('first-name',false);" type="text"
                                class="choiry-input w-[400px]" id="first-name" value="{{userAccount.firstName}}"
                                placeholder="Imię*" />
                            <div class="inline-block" id="validation-feedback-first-name"></div>
                        </div>
                        <div>
                            <input (input)="validationService.validateLastName('last-name',false);" type="text"
                                class="choiry-input w-[400px]" id="last-name" value="{{userAccount.lastName}}"
                                placeholder="Nazwisko*" />
                            <div class="inline-block" id="validation-feedback-last-name"></div>
                        </div>
                        <div class="ch-btn ch-btn-filled ch-btn-sec whitespace-nowrap" (click)="updateFullName()">Zapisz zmiany
                        </div>
                    </div>
                </div>
                <div *ngIf="userAccount.idProvider==='native'" class="flex flex-col gap-8 items-center">
                    <div class="font-headline">Zmień hasło</div>
                    <div class="flex flex-col gap-10 items-center">
                        <div>
                            <input type="password" class="choiry-input w-[400px]" id="current-password"
                                placeholder="Aktualne hasło*" />
                        </div>
                        <div>
                            <input type="password" class="choiry-input w-[400px]" id="new-password"
                                placeholder="Nowe hasło*" />
                            <div style="display: inline-block;" id="validation-feedback-new-password"></div>
                        </div>
                        <div>
                            <input type="password" class="choiry-input w-[400px]" id="new-password-repeat"
                                placeholder="Powtórz nowe hasło*" />
                            <div style="display: inline-block;" id="validation-feedback-new-password-repeat"></div>
                        </div>
                        <div class="ch-btn ch-btn-filled ch-btn-sec whitespace-nowrap" (click)="changePassword()">Zapisz zmiany
                        </div>
                    </div>
                </div>
                <!-- <div class="mb-10 flex flex-col gap-2">
                    <input type="password" id="register-password-input" class="choiry-input flex-1 mb-10"
                    #password required placeholder="Hasło" 
                    (input)="passwordValidity=validationService.validatePassword(password.value,passwordrepeat.value,true)" />
                    <input type="password" id="password-repeat-input" class="choiry-input flex-1"
                    #passwordrepeat required placeholder="Powtórz hasło" 
                    (input)="passwordValidity=validationService.validatePassword(password.value,passwordrepeat.value,true)" />
                    <div class="flex gap-2 h-6 text-[#FFD862] items-center">
                        <span *ngIf="passwordValidity!=''" class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="passwordValidity"></p>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>