import { Component, OnInit } from '@angular/core';
import { AlertService, ValidationService } from '@app/_services';
import { AuthServerService } from '@app/authserver/_services';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class AppFooterComponent implements OnInit {
  environment = environment;
  emailFsm = "form";
  emailValidity = "";
  showMenu: boolean = false;
  scrollTop: boolean = true;

  constructor(
      private authServerService: AuthServerService,
      public validationService: ValidationService,
      private alertService: AlertService
      ) {
  }

  ngOnInit() {
  }

  newsletterSubscribe(){
      let email = <HTMLInputElement>document.getElementById("home-newsletter-input");
      this.emailValidity = this.validationService.validateEmail(email.value,true)
      if(this.emailValidity!='' || this.emailFsm!='form'){
          return;
        }
      this.emailFsm = "sent"
      this.authServerService.reqSendNewsletterSubLink(email.value).subscribe( response => {
          this.emailFsm = "success"
      },err =>{
          this.emailFsm = "error"
      });
  }

}
