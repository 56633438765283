import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { PieceService, AlertService, ValidationService, PlaylistService } from '@app/_services';
import { Piece, Playlist } from '@app/_models';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { ConfirmModalComponent } from '@app/modals/confirmmodal/confirmmodal.component';

@Component({
  selector: 'app-listpiece',
  templateUrl: './listpiece.component.html',
})
export class ListPieceComponent implements OnInit {
  @ViewChild('confirmationModalContainer', {read: ViewContainerRef}) confirmationModalContainer : ViewContainerRef;
  userAccount: Account;
  canCreatePiece: boolean = false;
  filter: string;
  pieces: Piece[];
  pieceCreation: boolean = false;
  showSubscriptionMonit: boolean = false;
  showPlaylistMgmt: number = null;
  ownedPlaylists: Playlist[];

constructor(
    private route: ActivatedRoute,
    private router: Router,
    private alertService: AlertService,
    private pieceService: PieceService,
    private playlistService: PlaylistService,
    private accountService: AccountService,
    public validationService: ValidationService
    ) {
      this.accountService.account.subscribe(x => this.userAccount = x);
    }

ngOnInit() {
  this.playlistService.getUserOwnedAll().subscribe(x=> {this.ownedPlaylists=x;
    //TODO: PREPARE ENDPOINT
    for (let playlist of this.ownedPlaylists){
      this.playlistService.getById(playlist.id,undefined).subscribe(x => playlist.pieces = x.pieces);
    }
  })
  this.route.queryParams.subscribe(params => {
    this.filter = params['filter'] || 'owned';
    
    if(this.filter === 'owned'){
      this.pieceService.getUserOwnedAll().pipe(first()).subscribe(x => { this.pieces = x; });
    } else if(this.filter === 'shared'){
      this.pieceService.getUserSharedAll().pipe(first()).subscribe(x => { this.pieces = x; });
    }
  });
}


createPiece(){  
  this.pieceCreation=false;
  var title: string = (<HTMLInputElement>document.getElementById('new-piece-title')).value;
  if(this.validationService.validateName(title)){
    this.pieceService.create(title).subscribe(x => {
      this.accountService.getSelfAccountFromApi().subscribe(() =>{
        this.pieceService.getById(x.id,undefined).subscribe(y => {
          this.pieces.push(y); 
        });
      });
    });
  }
}

confirmDeletePiece(piece: Piece){
  this.confirmationModalContainer.clear();
  const componentRef = this.confirmationModalContainer.createComponent(ConfirmModalComponent);
  componentRef.instance.confirmType = 'piece-delete';
  componentRef.instance.title = 'Czy na pewno chcesz usunąć utwór '+piece.title+'?';
  componentRef.instance.action.subscribe(data => {
    if(data==='confirm'){
      this.pieceService.delete(piece.id)
       .pipe(first())
       .subscribe(
           data => {
              this.pieces.forEach((p,index)=>{
              if(p.id==piece.id) {
                this.pieces.splice(index,1);
                this.accountService.getSelfAccountFromApi().subscribe(() =>{});
                this.alertService.success("Usunięto utwór "+piece.title)
              }
            });
           },
           error => {
               this.alertService.error(error);
           });
    } else if (data ==='cancel'){
      //do nothing
    }
    componentRef.destroy();
  });
}

  openPlaylistMgmt(id){
    this.showPlaylistMgmt=id;
  }
  closePlaylistMgmt(id){
    if( this.showPlaylistMgmt!=id)
      return;
    else 
      this.showPlaylistMgmt=null;
  }

  doesPlaylistIncludesPiece(playlist,piece){
    return playlist.pieces.find(p => {return p.id == piece.id}) != undefined;
  }

  addPieceToPlaylist(piece,playlist){
    this.playlistService.addPiece(playlist.id,piece.id,playlist.pieces.length+1).subscribe(()=>playlist.pieces.push(piece))
  }
  removePieceFromPlaylist(piece,playlist){
    this.playlistService.removePiece(playlist.id,piece.id).subscribe(
      ()=> {
        playlist.pieces.forEach((p,index)=>{
        if(p.id==piece.id)
          playlist.pieces.splice(index,1);
      })});
  }

  setFilter(filter: string){
    var queryParams: Params = { filter: filter };
    this.router.navigate([], {relativeTo: this.route, queryParams: queryParams, queryParamsHandling: 'merge', });
  }

}
