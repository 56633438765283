import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home';
import { SettingsComponent } from './settings';
import { PlaylistComponent } from './playlist';
import { UserGroupComponent } from './usergroup';
import { ListPlaylistComponent } from './list/playlist';
import { ListUserGroupComponent } from './list/usergroup';
import { ListArticleComponent } from './list/article';
import { ListPieceComponent } from './list/piece';
import { ListSharingComponent } from './list/sharing';
import { PieceComponent } from './piece';
import { EditArticleComponent } from './edit/article';
import { AuthGuard } from './_utility/auth.guard';
import { StatementComponent } from './authserver/statement';
import { PurchaseNewComponent, PurchaseAboutComponent, PurchaseSummaryComponent } from './purchase';
import { RegisterComponent } from './authserver/register';
import { PasswordResetComponent } from './authserver/passwordreset';
import { PasswordForgottenComponent } from './authserver/passwordforgotten';
import { LoginComponent } from './authserver/login';
import { RedirectComponent } from './authserver/redirect/redirect.component';
import { EmailVerifyComponent } from './authserver/emailverify/emailverify.component';
import { IntervalRecognitionComponent } from './excercises/intervalrecognition/intervalrecognition.component';
import { RythmicDictationComponent } from './excercises/rythmicdictation/rythmicdictation.component';
import { NewsletterFormComponent, NewsletterSubscribeComponent,NewsletterUnsubscribeComponent } from './authserver/newsletter';
import { ArticleComponent } from './article';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SubscriptionEndComponent } from './help';
import { ScoreReadingComponent } from './excercises/scorereading/scorereading.component';

const appRoutes: Routes = [
    //AUTHSERVER
    { path: 'register', component: RegisterComponent, title: "Rejestracja" },
    { path: 'login', component: LoginComponent, title: "Logowanie"},
    { path: 'passwordforgotten', component: PasswordForgottenComponent, title: "Odzyskiwanie hasła"},
    { path: 'passwordreset/:code', component: PasswordResetComponent, title: "Reset hasła"},
    { path: 'emailverify/:code', component: EmailVerifyComponent, title: "Weryfikacja email" },
    { path: 'statement/:name', component: StatementComponent, title: "Dokument" },
    { path: 'redirect/:idp', component: RedirectComponent, title: "Przekierowanie"},
    { path: 'newsletter/form', component: NewsletterFormComponent, title: "Newsletter"},
    { path: 'newsletter/subscribe/:code', component: NewsletterSubscribeComponent, title: "Newsletter"},
    { path: 'newsletter/unsubscribe/:code', component: NewsletterUnsubscribeComponent, title: "Newsletter"},


    //NOACCOUNT
    { path: '', component: HomeComponent, title: "Choiry" },
    { path: 'playlist/link/:id/:key', component: PlaylistComponent},
    { path: 'piece/link/:id/:key', component: PieceComponent },
    { path: 'excercises/intervalrecognition', component: IntervalRecognitionComponent, title: "Rozpoznawanie interwałów" },
    { path: 'excercises/rythmicdictation', component: RythmicDictationComponent, title: "Dyktando rytmiczne" },
    { path: 'excercises/scorereading', component: ScoreReadingComponent, title: "Czytanie nut" },
    { path: 'help/subscriptionend', component: SubscriptionEndComponent },
    //NEEDSACCOUNT
    { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] , title: "Ustawienia"},
    { path: 'subscription', component: SubscriptionComponent, canActivate: [AuthGuard] , title: "Subskrypcja"},
    //PURCHASE
    { path: 'purchase/new', component: PurchaseNewComponent, canActivate: [AuthGuard], data: { roles: ['user'], optional: false}  },
    { path: 'purchase/summary/:id', component: PurchaseSummaryComponent, canActivate: [AuthGuard], data: { roles: ['user'], optional: false}  },
    { path: 'purchase/about', component: PurchaseAboutComponent, canActivate: [AuthGuard], data: { roles: ['user'], optional: false}  },
    //PLAYLIST
    { path: 'playlist/:id', component: PlaylistComponent, canActivate: [AuthGuard], data: { optional: true } },
    { path: 'list/playlist', component: ListPlaylistComponent, canActivate: [AuthGuard] , title: "Playlisty" },
    //PIECE
    { path: 'piece/:id', component: PieceComponent, canActivate: [AuthGuard], data: { optional: true } },
    { path: 'list/piece', component: ListPieceComponent, canActivate: [AuthGuard] , title: "Utwory"},
    //GROUP
    { path: 'usergroup/:id', component: UserGroupComponent, canActivate: [AuthGuard] },
    { path: 'list/usergroup', component: ListUserGroupComponent, canActivate: [AuthGuard] , data: {roles: ['admin']}, title: "Grupy"},
    //ARTICLE
    { path: 'article/:id', component: ArticleComponent, canActivate: [AuthGuard] },
    { path: 'list/article', component: ListArticleComponent, canActivate: [AuthGuard] , title: "Artykuły"},
    { path: 'edit/article/:id', component: EditArticleComponent, canActivate: [AuthGuard] , title: "Edycja Artykułu"},
    //SHARING
    { path: 'list/sharing', component: ListSharingComponent, canActivate: [AuthGuard] , title: "Udostępnienia"},

    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);