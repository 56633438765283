import { Injectable } from '@angular/core';
import * as Tone from 'tone';


@Injectable({ providedIn: 'root' })
export class AudioService {
    constructor() { }

    async startAudioContext(){
        if(Tone.context.state == 'suspended'){
            await Tone.context.resume()
        } else if(Tone.context.state != 'running'){
            await Tone.start();
        }
    }

    startTransport(){
        Tone.loaded().then(() => Tone.Transport.start());
    }

    restartTransport(){
        Tone.Transport.stop();
        this.startTransport();
    }

    scheduleMetronome(metronomePlayer: Tone.Player,until: number){
        metronomePlayer.unsync();

        for(let timeCursor=0 ; timeCursor < until ;timeCursor+=Tone.Time("4n").toSeconds()){
            metronomePlayer.sync().start(timeCursor);
        }        
    }

    scheduleRythm(rythm: number[],rythmPlayer: Tone.Player,startAt: number=0){
        rythmPlayer.unsync();

        let timeCursor = startAt;
        let sixteenthLength=((Tone.Time("4n").toSeconds())/4);
        for(let i=0;i<rythm.length;i++){
          rythmPlayer.sync().start(timeCursor);
          timeCursor += rythm[i]*sixteenthLength;
        }
    }

    clean(){
        Tone.Transport.stop();
        Tone.Transport.cancel();
    }

    destroyAudioContext(){
        Tone.Transport.dispose();
    }

}