import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterModule } from '@angular/router';
import { Account } from './authserver/_models';
import { AccountService } from './authserver/_services';
import { CommonModule } from '@angular/common';
import { AppFooterComponent, AppNavbarComponent } from './layout';
import { CookiesComponent } from './cookies/cookies.component';
import { PianoComponent } from './tools/piano/piano.component';
import { MetronomeComponent } from './tools/metronome/metronome.component';
import { AlertComponent } from './_global';


@Component({
    standalone: true,
    imports: [
        CommonModule,
        RouterModule,
        AppNavbarComponent,
        AppFooterComponent,
        CookiesComponent,
        PianoComponent,
        MetronomeComponent,
        AlertComponent
    ],
    selector: 'app', 
    templateUrl: 'app.component.html' 
})
export class AppComponent implements OnInit{
    userAccount: Account;
    toggleFullScreen:boolean=false;

    constructor(
        private router: Router,
        private accountService: AccountService)
        {
            router.events.subscribe((event)=>{
                if(event instanceof NavigationStart){
                    if(event.url.match(/^\/piece\/.*/g)!=null)
                        this.toggleFullScreen=true;
                    else
                        this.toggleFullScreen=false;
                }
            })
            this.accountService.account.subscribe(x => this.userAccount = x);
        }

    ngOnInit(){}

    @HostListener('window:resize', ['$event'])
    @HostListener('window:load', ['$event'])
    autoHeight(){
        let height = window.innerHeight;
        let hh = document.getElementById("main-header").clientHeight;
        let ha = document.getElementById("main-alert").clientHeight;
        let hf = document.getElementById("main-footer").clientHeight;
        document.getElementById("main-content").style.minHeight = (height - hf - hh - ha)+"px";
    }
}