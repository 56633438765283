<div class="w-16 lg:w-48">
  <div class="bg-ch-grey-900 h-8"></div>
  <div *ngFor="let track of tracks" class="h-24 flex relative min-w-0">
    <ng-container *ngIf="!track.loadingAudio">
      <!-- DESKTOP -->
      <div class="hidden w-[188px] h-24 bg-ch-blue-50 border-b-2 border-ch-grey-900 lg:flex flex-col">
        <div class="flex-1 flex flex-col w-full justify-center items-center border-b-2">
          <div class="w-full flex-1 flex items-center justify-center relative">
            <div class="w-8 flex-none flex flex-col">
              <div *ngIf="isEditor" (click)="confirmDeleteTrack(track)" class="icon-btn icon-24 text-red-700">delete</div>
              <div *ngIf="isEditor" (click)="downloadTrackFile(track)" class="icon-btn icon-24">download</div>
            </div>
            <div [hidden]="track.titleEditing" [ngClass]="isEditor ? 'cursor-pointer' : ''" (click)="changeTrackTitle(track)" class="flex-1 overflow-hidden text-center text-ellipsis whitespace-nowrap"><b>{{track.name}}</b></div>
            <div [hidden]="!track.titleEditing" class="flex-1">
              <input class="choiry-input" (input)="validationService.updateTrackLabel('track-title-input-'+track.id)" type="text" (blur)="changeTrackTitle(track)" (keyup.enter)="blurInput($event)" id="track-title-input-{{track.id}}" value="{{track.name}}" style="width: 128px;"/>
            </div>
            <div class="w-8 flex-none flex flex-col">
              <div *ngIf="isEditor" (click)="moveTrackUp(track)" class="icon-btn icon-24">keyboard_arrow_up</div>
              <div *ngIf="isEditor" (click)="moveTrackDown(track)" class="icon-btn icon-24">keyboard_arrow_down</div>
            </div>
            <div class="w3-text w3-tag w3-round-large" id="validation-feedback-track-title-input-{{track.id}}" style="position:absolute; left: 0px; top: 50px; z-index: 3"></div>
          </div>
        </div>
        <div class="w-full flex-none h-8 flex items-center justify-center">
          <div class="flex-none flex items-center justify-center w-8 h-full border-r-2 font-boldbody cursor-pointer hover:opacity-70" 
            [ngClass]="track.channel.solo?'bg-green-100':''" 
            (click)="track.channel.solo = !track.channel.solo; updateTrack(track);">S
          </div>
          <div class="flex-none flex items-center justify-center w-8 h-full border-r-2 font-boldbody cursor-pointer hover:opacity-70" 
            [ngClass]="track.channel.mute?'bg-ch-red-100':''" 
            (click)="track.channel.mute = !track.channel.mute; updateTrack(track);">M
          </div>
          <div class="flex-1 h-full relative">
            <input class="w-full vol-slider" type="range" #volumeselector (input)="updateTrackVolume(track,volumeselector.value);" (change)="submitTrackVolume(track,volumeselector.value)" min="-48" max="12" [value]="track.volumeDb">
            <div class="absolute bottom-2 right-2 font-label text-ch-grey-500 pointer-events-none" [ngClass]="track.volumeDb>0? 'text-red-700' : ''">{{track.volumeDb}} dB</div>
          </div>
        </div>
      </div>
      <!-- MOBILE (ONLY VIEW)-->
      <div *ngIf="!track.enlargeControls" (click)="track.enlargeControls=true;" class="lg:hidden cursor-pointer w-[60px] h-24 bg-ch-blue-50 border-b-2 border-ch-grey-900 flex items-center justify-center relative">
        <div class="absolute bg-ch-yellow-100 bottom-0 left-0 right-0" [style.top]="(100-((100/64)*(track.volumeDb+48)))+'%'"></div>
        <div class="absolute right-1 top-1 left-1 overflow-hidden text-ellipsis font-label"><b>{{track.name}}</b></div>
        <div class="absolute bottom-1 left-1 font-boldbody" [ngClass]="track.channel.solo?'text-green-300':'text-ch-grey-900'">S</div>
        <div class="absolute bottom-1 right-1 font-boldbody" [ngClass]="track.channel.mute?'text-ch-red-300':'text-ch-grey-900'">M</div>
        <div class="z-10 font-label text-ch-grey-500" [ngClass]="track.volumeDb>0? 'text-red-700' : ''">{{track.volumeDb}} dB</div>
      </div>
      <!-- MOBILE (EDIT) -->
      <div *ngIf="track.enlargeControls" [style.width]="playerWidth+'px'" (clickOutside)="track.enlargeControls=false;" [delayClickOutsideInit]="true" class="lg:hidden flex-none overflow-visible z-50 h-24 bg-ch-blue-50 border-b-2 border-ch-grey-900 flex items-center justify-center relative opacity-95">
        <div class="w-full flex-none h-full flex items-center justify-center">
          <div class="flex-none flex items-center justify-center w-16 h-full border-r-2 font-boldbody cursor-pointer hover:opacity-70" 
            [ngClass]="track.channel.solo?'bg-green-100':''" 
            (click)="track.channel.solo = !track.channel.solo; updateTrack(track);">S
          </div>
          <div class="flex-none flex items-center justify-center w-16 h-full border-r-2 font-boldbody cursor-pointer hover:opacity-70" 
            [ngClass]="track.channel.mute?'bg-ch-red-100':''" 
            (click)="track.channel.mute = !track.channel.mute; updateTrack(track);">M
          </div>
          <div class="flex-1 h-full relative">
            <input class="w-full vol-slider" type="range" #volumeselector (input)="updateTrackVolume(track,volumeselector.value);" (change)="submitTrackVolume(track,volumeselector.value)" min="-48" max="12" [value]="track.volumeDb">
            <div class="absolute bottom-2 right-2 font-label text-ch-grey-500 pointer-events-none">{{track.volumeDb}} dB</div>
          </div>
        </div>
      </div>

      <!-- VU METER -->
      <div class="h-24 bg-ch-grey-50 w-1 relative">
        <div [ngStyle]="{'height': track.meterValue+ '%'}" class="absolute bottom-0 left-0 bg-secondary w-1"></div>
      </div>
    </ng-container>
    <ng-container *ngIf="track.loadingAudio">
      <div class="h-full w-[60px] lg:w-[188px] flex items-center justify-center bg-ch-blue-50 border-b-2 border-ch-grey-900">
        <div class="py-4 icon-48 animate-spin">progress_activity</div>
      </div>
    </ng-container>
  </div>
  <div *ngFor="let trackUpload of trackUploads" class="h-24 bg-ch-blue-50">
    <div class="h-full w-[60px] lg:w-[188px] flex items-center justify-center border-b-2 border-ch-grey-900">
      <div class="py-4 icon-48 animate-spin">progress_activity</div>
    </div>
  </div>
  <!-- NEW TRACK -->
  <div *ngIf="isEditor" class="h-24 flex">
    <div class="w-[60px] lg:w-[188px] flex flex-col items-center h-24 bg-ch-blue-50">
      <label for="fileUpload" class="flex-1 w-full cursor-pointer hover:bg-ch-yellow-50 flex justify-center items-center gap-2">
        <div class="hidden lg:block font-boldbody">Prześlij ścieżkę</div>
        <span class="icon-24">cloud_upload</span>
      </label>
      <!--[disabled]="trackUpload.inProgress"--><input class="hidden" id="fileUpload" type="file" (change)="uploadTrack.emit($event)">
    
      <!-- migrate to separate component? -->
      <div *ngIf="havePermissions" class="flex-1 w-full cursor-pointer hover:bg-ch-yellow-50 flex justify-center items-center gap-2" (click)="recBtn.emit()">
        <ng-container *ngIf="isRecording">
          <div class="hidden lg:block text-red-700 font-boldbody">Zatrzymaj nagranie</div>
          <span class="icon-24 blink">stop_circle</span>
        </ng-container>
        <ng-container *ngIf="!isRecording">
          <div class="hidden lg:block font-boldbody">Nagraj ścieżkę</div>
          <span class="icon-24">mic</span>
        </ng-container>
      </div>
      <div *ngIf="!havePermissions" class="relative flex-1 w-full cursor-pointer">
        <div class="absolute z-10 w-full h-full bg-ch-grey-900 hover:bg-ch-grey-500 opacity-90 flex flex-col justify-center items-center text-white" (click)="grantPermission()">
          <div class="flex justify-center items-center gap-2">
            <div class="hidden lg:block font-boldbody">Nagrywanie</div>
            <span class="icon-24">lock</span>
          </div>
          <div class="hidden lg:block font-bold text-red-700 text-xs">
            Udostępnij mikrofon
          </div>
        </div>
        <div class="absolute z-0 w-full h-full flex justify-center items-center gap-2">
          <div class="hidden lg:block font-boldbody">Nagraj ścieżkę</div>
          <span class="icon-24">mic</span>
        </div>
      </div>
    </div>
    <!-- VU METER -->
    <div class="h-24 bg-ch-grey-50 w-1 relative">
      <div [ngStyle]="{'height': micMeterValue+ '%'}" class="absolute bottom-0 left-0 bg-red-700 w-1"></div>
    </div>
  </div>
</div>
<ng-container #confirmationModalContainer></ng-container>