<div class="flex gap-6">
  <app-menu *ngIf="userAccount"></app-menu>
  <div class="flex-1 overflow-hidden">
    <div class="bg-white pb-[56px] flex flex-col rounded-b-xl">
      <div class="h-16 bg-ch-yellow-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
        <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1"><div class="icon icon-24">arrow_left_alt</div></div>
        <div class="font-boldbody">Dyktando rytmiczne</div>
        <div class="min-w-8 h-8 flex">
          <div *ngIf="fsm=='ready'" class="text-primary icon-btn icon-32" (click)="start()">play_arrow</div>    
          <div *ngIf="fsm=='answer'" class="text-primary icon-btn icon-32" (click)="repeat()">repeat</div>    
          <div *ngIf="fsm=='answer'" class="text-primary icon-btn icon-32" (click)="stop()">stop</div>    
        </div>
      </div>
      <div class="flex">
        <div class="w-72 bg-ch-blue-50">
          <ng-container *ngFor="let lesson of lessons">
            <div [ngClass]="lesson==selectedLesson ? 'font-boldbody bg-ch-blue-100' : ''" class="flex w-full items-center pl-4 pr-2 text-small py-1 gap-1 cursor-pointer hover:bg-ch-yellow-50" 
            (click)="selectLesson(lesson)">
              <div class="flex-1">{{lesson.lessonName}}</div>
              <div *ngIf="userAccount" class="font-label">{{lesson.bestScore || 'X'}} / 100</div>
              <div *ngIf="lesson.difficulty==1" class="icon-24">signal_cellular_alt_1_bar</div>
              <div *ngIf="lesson.difficulty==2" class="icon-24">signal_cellular_alt_2_bar</div>
              <div *ngIf="lesson.difficulty==3" class="icon-24">signal_cellular_alt</div>
            </div>
          </ng-container>
        </div>
        <div class="flex-1">
          <div class="text-center font-title md:hidden p-4">Przepraszamy dyktando rytmiczne nie jest jeszcze przystosowane do małych ekranów.</div>
          <div class="hidden md:block">
            <!--HEADER CONTROLLER-->
            <div class="font-title w-full text-center my-8">Poprawne odpowiedzi: {{correctAnswers}}</div>
            <div class="w-[620px] h-32 my-8 mx-auto"><score-render #score [width]="620" [drawGrid]="false" ></score-render></div>
            <div class="w-full h-16 text-center text-2xl">
              <div *ngIf="fsm=='playing'"class="w-80 flex bg-ch-blue-50 mx-auto">
                <div id="metronome-0" class="flex-1"></div>
                <div id="metronome-1" class="flex-1"></div>
                <div id="metronome-2" class="flex-1"></div>
                <div id="metronome-3" class="flex-1"></div>
              </div>
              <div *ngIf="fsm=='feedback-right'" class="text-success-content font-bold">Poprawnie</div>
              <div *ngIf="fsm=='feedback-wrong'" class="text-error-content font-bold">Źle</div>
              <div *ngIf="fsm=='ready'" class="w-full">Wybierz lekcję i kliknij play</div>
              <div *ngIf="fsm=='answer'" class="w-full">Kliknij na kolejne wartości rytmiczne które usłyszałeś</div>
              <div *ngIf="fsm=='lesson-result'" class="w-full">Twój wynik to {{correctAnswers}}</div>
            </div>
            <div class="w-full h-16 text-center text-2xl">
              <div class="w-full text-4xl"><i *ngIf="guessedRythm.length>0" class="fa fa-remove hover:text-red-700 cursor-pointer" (click)="stepback()"></i>{{guessedRythmText}}</div>
            </div>
            <div class="grid grid-cols-5 justify-items-center w-full my-8">
              <div>
                <div class="interval-btn" id="note-16" (click)="noteClick(16)">{{'&#x1D15D;'}}</div>
              </div>
              <div>
                <div class="interval-btn" id="note-8" (click)="noteClick(8)">{{'&#x1D15E;'}}</div>
              </div>
              <div>
                <div class="interval-btn" id="note-4" (click)="noteClick(4)">{{'&#x1D15F;'}}</div>
              </div>
              <div>
                <div class="interval-btn" id="note-2" (click)="noteClick(2)">{{'&#x1D160;'}}</div>
              </div>
              <div>
                <div class="interval-btn" id="note-1" (click)="noteClick(1)">{{'&#x1D161;'}}</div>
              </div>
            </div>
            <div class="px-8"><ch-progress [percent]="lessonProgress" [description]="'Pytanie '+lessonProgress+ ' z '+lessonLength" class="w-full"></ch-progress></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>