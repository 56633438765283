import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Playlist } from '@app/_models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PlaylistService {
    constructor(private http: HttpClient) { }

    getUserOwnedAll() {
        return this.http.get<Playlist[]>(`${environment.apiUrl}/playlist/owned/all`);
    }

    getUserSharedAll() {
        return this.http.get<Playlist[]>(`${environment.apiUrl}/playlist/shared/all`);
    }

    getUserGroupSharedAll(id: number) {
        return this.http.get<Playlist[]>(`${environment.apiUrl}/playlist/shared/group/${id}`);
    }

    getById(id: number,linkKey: string) {
        if(linkKey===undefined)
            return this.http.get<Playlist>(`${environment.apiUrl}/playlist/${id}`);
        else
            return this.http.get<Playlist>(`${environment.apiUrl}/playlist/link/${id}/${linkKey}`);
    }

    create(title: string) : Observable<any> {
        return this.http.post(`${environment.apiUrl}/playlist/create`, title);
    }

    update(playlist: Playlist) {
        return this.http.post(`${environment.apiUrl}/playlist/update`, playlist);
    }

    addPiece(playlistId: number,pieceId: number,ordinalNumber: number) {
        return this.http.post(`${environment.apiUrl}/playlist/addpiece/${playlistId}/${pieceId}`,ordinalNumber);
    }

    reorderPiece(playlistId: number,pieceId: number,ordinalNumber: number) {
        return this.http.post(`${environment.apiUrl}/playlist/reorderpiece/${playlistId}/${pieceId}`,ordinalNumber);
    }

    removePiece(playlistId: number,pieceId: number) {
        return this.http.get(`${environment.apiUrl}/playlist/removepiece/${playlistId}/${pieceId}`);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/playlist/delete/${id}`);
    }
}