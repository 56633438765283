import { CommonModule } from '@angular/common';
import {  Component, Input} from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'ch-progress',
  templateUrl: './progress.component.html'
})
export class ProgressComponent {
    @Input() percent:number=0;
    @Input() description:string='';

    constructor(){}
}