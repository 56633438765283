import { User } from "./user";
import { UserGroup } from "./usergroup";

export class Share {
    id: number;
    objectId: number;
    shareLinkEnabled: boolean;
    linkKey: string;
    userId: string=null;
    userGroupId: number=null;
    user: User;
    userGroup: UserGroup;
}