import { Component, OnInit } from '@angular/core';
import { AccountService } from '../_services/account.service';
import { Account } from '../_models/account';
import { AuthServerService } from '../_services';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-newsletter-unsubscribe',
  templateUrl: './newsletterunsubscribe.component.html',
})
export class NewsletterUnsubscribeComponent implements OnInit {
    userAccount: Account;
    fsm: string; //pending-sub,success-sub,error-sub
    linkCode: string;
    constructor(
      private accountService: AccountService,
      private authServerService: AuthServerService,
      private route: ActivatedRoute) { 
        this.accountService.account.subscribe(x => this.userAccount = x);
        this.fsm = 'pending-unsub';
      }

    ngOnInit() {
      this.route.params.subscribe(params => { 
        this.linkCode=params['code']; 
        this.authServerService.reqNewsletterUnsub(this.linkCode).subscribe(() => this.fsm='success-unsub',()=> this.fsm='error-unsub')
    });
    }
}