import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from '@app/authserver/_models/account';
import { User } from '@app/_models';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangePasswordForm } from '../_models';
import { AuthServerService } from './authserver.service';
import { AlertService } from '@app/_services';
import { State } from '../_models/state';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private alertService: AlertService,
        private authServerService: AuthServerService
    ) {
        let loggedInAccount = null;
        if(localStorage.getItem('self-account')!= null)
            loggedInAccount = JSON.parse(localStorage.getItem('self-account'));


        this.accountSubject = new BehaviorSubject<Account>(loggedInAccount);
        this.account = this.accountSubject.asObservable();
    }

    getSelfAccountFromApi(): Observable<Account> {
        return this.http.get<Account>(`${environment.apiUrl}/account/self`).pipe(map((a: Account) => {this.accountSubject.next(a); localStorage.setItem("self-account",JSON.stringify(a)); return a;}));
    }
    
    login() {
        this.registerLoginState()
        location.replace(environment.loginUrl);
    }

    registerLoginState(){
        let state : State = new State();
        state.code = window.crypto.randomUUID();
        state.redirectUri = location.href;
        localStorage.setItem("loginState",JSON.stringify(state));
    }

    logout() {
        let sid = JSON.parse(atob(<string>(JSON.parse(localStorage.getItem("tokens")).access_token).split(".")[1])).sid;
        this.authServerService.reqLogout(sid).subscribe(()=>
        {
            this.accountSubject.next(null);
            localStorage.removeItem("tokens");
            localStorage.removeItem("self-account");
            this.login();
        }, () => {
            this.accountSubject.next(null);
            localStorage.removeItem("tokens");
            localStorage.removeItem("self-account");
            this.login();
        });
    }

    session(code: string, idProvider: string,receivedState: string) {
        let storedState: string = localStorage.getItem("loginState");
        let redirect: string = JSON.parse(storedState).redirectUri;
        localStorage.removeItem("loginState");
        if(storedState != receivedState)
            return;

        this.authServerService.reqToken(code,idProvider).subscribe((t)=>{
                localStorage.setItem("tokens",JSON.stringify(t))
                this.getSelfAccountFromApi().subscribe(
                    (acc) => {location.href=redirect;},
                    (err) => {this.logout()}
                )
            },
            (err) => {
                if(err.code == 400){
                    this.alertService.error(err.errorMessage);}
                }
        );
    }

    refresh(){
        if(localStorage.getItem('tokens')!= null){
            let tokens = JSON.parse(localStorage.getItem('tokens'));
            return this.authServerService.reqRefresh(tokens.refresh_token).pipe(map((t)=>localStorage.setItem("tokens",JSON.stringify(t))));
        }
        return null;
    }

    deleteAvatar() :Observable<any> {
        return this.authServerService.reqDeleteAvatar().pipe(map((x) => {this.getSelfAccountFromApi().subscribe(); return x;}));;
    }

    subscriptionInfo(){
        var subscriptionInfo="";
        var standardMilisecondsLeft : number = this.accountSubject.getValue().endOfStandardPlan - Date.now();
        var proMilisecondsLeft : number = this.accountSubject.getValue().endOfProPlan - Date.now();
        var standardDaysLeft : number = Math.floor(standardMilisecondsLeft/(3600*24*1000));
        var proDaysLeft : number = Math.floor(proMilisecondsLeft/(3600*24*1000));
        var activePlan : string = "FREE";
  
        if((this.accountSubject.getValue().endOfStandardPlan===undefined || this.accountSubject.getValue().endOfProPlan ===undefined) || (proDaysLeft<0 && standardDaysLeft<0)){
          activePlan="FREE";
          subscriptionInfo = "<p>Aktywny plan to FREE.</p>";
        }
        else if(proDaysLeft<0 && standardDaysLeft>=0){
          activePlan="STANDARD"
          if(standardDaysLeft==0)
            subscriptionInfo = "<p>Aktywny plan STANDARD kończy się <strong>dzisiaj</strong>. Zakup dostęp na kolejny okres.</p>";
          else if(standardDaysLeft==1)
            subscriptionInfo = "<p>Aktywny plan STANDARD kończy się <strong>jutro</strong>. Zakup dostęp na kolejny okres.</p>";
          else if(standardDaysLeft <= 7)
            subscriptionInfo = "<p>Aktywny plan STANDARD kończy się za "+standardDaysLeft.toString()+" dni. Zakup dostęp na kolejny okres.</p>";
          else if(standardDaysLeft <= 3650)
            subscriptionInfo = "<p>Aktywny plan STANDARD kończy się za "+standardDaysLeft.toString()+" dni.</p>";
        }
        else if(proDaysLeft>=0){
          var extraStandardDays = standardDaysLeft - proDaysLeft;
          var extraStandard = "";
          if(extraStandardDays <= 0)
            extraStandard = ""
          else if(extraStandardDays > 0)
            extraStandard = "Na Twoim koncie jest jeszcze do wykorzystania "+extraStandardDays.toString()+" dni w planie STANDARD. Po zakończeniu planu PRO zostaniesz przeniesiony na plan STANDARD."
          activePlan="PRO"
          if(proDaysLeft==0)
            subscriptionInfo = "<p>Aktywny plan PRO kończy się <strong>dzisiaj</strong>. Zakup dostęp na kolejny okres. "+extraStandard+" </p>";
          else if(proDaysLeft==1)
            subscriptionInfo = "<p>Aktywny plan PRO kończy się <strong>jutro</strong>. Zakup dostęp na kolejny okres. "+extraStandard+" </p>";
          else if(proDaysLeft <= 7)
            subscriptionInfo = "<p>Aktywny plan PRO kończy się za "+standardDaysLeft.toString()+" dni. Zakup dostęp na kolejny okres. "+extraStandard+" </p>";
          else if(proDaysLeft <= 3650)
            subscriptionInfo = "<p>Aktywny plan PRO kończy się za "+standardDaysLeft.toString()+" dni.</p>";
        } else
          subscriptionInfo= "<p>Posiadasz subskrypcję dożywotnią. Dziękujemy za wsparcie!</p>";
          return subscriptionInfo;
      }

      activePlan ():string{
        let standardMilisecondsLeft : number = this.accountSubject.getValue().endOfStandardPlan - Date.now();
        let proMilisecondsLeft : number = this.accountSubject.getValue().endOfProPlan - Date.now();

        if(proMilisecondsLeft>0)
          return "PRO";
        if(standardMilisecondsLeft>0)
          return "STANDARD";
        return "FREE";
      }
}