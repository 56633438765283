import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { Comment } from '@app/_models/comment';

@Injectable({ providedIn: 'root' })
export class CommentService {
    constructor(private http: HttpClient) { }

    getAllBlockComment(id: number) {
        return this.http.get<Comment[]>(`${environment.apiUrl}/comment/block/${id}`);
    }

    createComment(comment: Comment) : Observable<any> {
        return this.http.post(`${environment.apiUrl}/comment/create`, comment);
    }

//    deletePlaylistShare(id: number) {
//        return this.http.delete(`${environment.apiUrl}/share/playlist/delete/${id}`);
//    }
}