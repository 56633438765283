import { User } from ".";

import {Piece} from './piece';

export class Playlist {
    id: number;
    title: string;
    description: string;
    sharePublicEnabled: boolean;
    ownerId: string;
    owner: User;
    creationDate: number;
    pieces: Piece[];
}