import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SharingService, AlertService } from '@app/_services';
import { Share } from '@app/_models';
import { first } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';


@Component({
  selector: 'app-listsharing',
  templateUrl: './listsharing.component.html',
})
export class ListSharingComponent implements OnInit {
    userAccount: Account;
    pieceShares: Share[];
    playlistShares: Share[];
    sharingPlaylistLink: string;
    sharingPieceLink: string;
    filter: string;

  constructor(
      private alertService: AlertService,
      private sharingService: SharingService,
      private accountService: AccountService,
      private clipboardService: ClipboardService,
      private route: ActivatedRoute,
      private router: Router,
      ) {
        this.accountService.account.subscribe(x => this.userAccount = x);
      }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.filter = params['filter'] || 'all';
      
      if(this.filter === 'playlists' || this.filter === 'all'){
        this.sharingService.getAllPlaylistShare().pipe(first()).subscribe(x => { this.playlistShares = x; });
      }
      if(this.filter === 'pieces' || this.filter === 'all'){
        this.sharingService.getAllPieceShare().pipe(first()).subscribe(x => { this.pieceShares = x; });
      }
    });

    this.sharingPlaylistLink = environment.domain + "playlist/link/";
    this.sharingPieceLink = environment.domain + "piece/link/";
  }

  deletePieceShare(id: number){
    this.sharingService.deletePieceShare(id)
         .pipe(first())
         .subscribe(
             data => {
                this.pieceShares.forEach((share,index)=>{
                if(share.id==id) {
                  this.pieceShares.splice(index,1);
                  this.alertService.success('Wyłączono udostępnienie '+id);
                }
              });
             },
             error => {
                 this.alertService.error(error);
             });
  }

  deletePlaylistShare(id: number){
    this.sharingService.deletePlaylistShare(id)
         .pipe(first())
         .subscribe(
             data => {
                this.playlistShares.forEach((share,index)=>{
                if(share.id==id) {
                  this.playlistShares.splice(index,1);
                  this.alertService.success('Wyłączono udostępnienie '+id);
                }
              });
             },
             error => {
                 this.alertService.error(error);
             });
  }

  copyToClipboard(text: string){
    this.clipboardService.copy(text);
}
setFilter(filter: string){
  var queryParams: Params = { filter: filter };
  this.router.navigate([], {relativeTo: this.route, queryParams: queryParams, queryParamsHandling: 'merge', });
}

}
