import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { InAppNotification } from '@app/_models/inappnotification';

@Injectable({ providedIn: 'root' })
export class InAppNotificationService {
    constructor(private http: HttpClient) { }

    getAllNotifications() {
        return this.http.get<InAppNotification[]>(`${environment.apiUrl}/notification/all`);
    }

    view(notification: InAppNotification) {
        return this.http.post<InAppNotification>(`${environment.apiUrl}/notification/view`,notification);
    }

}