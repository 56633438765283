export class SubscriptionPurchaseData {
    orderId: number;
    userId: string;
    creationDate: number;
    przelewy24OrderId: number;
    productName: string;
    productPriceNetto: number;
    productTax: number;
    productPriceBrutto: number;
    purchaseStatus: string;
    productId: string;
    currency: string;
    company: boolean;
    naturalPerson: boolean;
    contactEmail: string;
    firstName: string;
    lastName: string;
    companyName: string;
    taxID: string;
    street1: string;
    street2: string;
    street3: string;
    city: string;
    postCode: string;
    emailConsent: boolean;
    przelewy24Consent: boolean;
}