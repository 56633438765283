import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent, LoadedImage, base64ToFile } from 'ngx-image-cropper';
import { Share, UserGroup, User, FileUpload } from '@app/_models';
import { AlertService, FileUploadService, SharingService, UserGroupService } from '@app/_services';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { environment } from '@environments/environment';
import { ClipboardService } from 'ngx-clipboard';
import { DomSanitizer } from '@angular/platform-browser';

@Component({ 
    selector: 'avatar-modal', 
    templateUrl: 'avatarmodal.component.html', 
    styleUrls: ['avatarmodal.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class AvatarModalComponent implements OnInit, OnDestroy {
    @Input() id: string;
    @Input() type: string;
    @Output() onUpdate: EventEmitter<any> = new EventEmitter();

    //for user-avatar type
    @Input() userAccount: Account;
    //for usergroup-avatar type
    @Input() userGroup: UserGroup;

    private element: any;
    selectedImageFile: any;
    croppedImage: any = '';
    croppedImageFile: File;
    uploadFsm: string = 'initial';
    avatarUpload: FileUpload;

    constructor(
        private el: ElementRef,
        private sanitizer: DomSanitizer,
        private alertService: AlertService,
        private fileUploadService: FileUploadService,
        private userGroupService : UserGroupService,
        private accountService: AccountService) {
            this.element = el.nativeElement;
    }

    ngOnInit(): void {
        if (!this.id) {
            console.error('modal must have an id');
            return;
        }
        if (!this.userAccount && !this.userGroup) {
            return;
        }
        if (!this.type) {
            console.error('modal must have an type');
            return;
        }
        if(this.type=="user-avatar"){
            // this.sharingService.getPieceSharesByObjectId(Number.parseInt(this.objectId)).subscribe(x => { this.shares = x; });
        }
        if(this.type=="usergroup-avatar"){
            // this.sharingService.getPlaylistSharesByObjectId(Number.parseInt(this.objectId)).subscribe(x => { this.shares = x; });
        }
        
        document.body.appendChild(this.element);
        this.element.addEventListener('click', el => {
            if (el.target.className === 'avatar-modal-background') {
                this.close();
            }
        });
    }

    // remove self from modal service when component is destroyed
    ngOnDestroy(): void {
        this.element.remove();
    }

    // close modal
    close(): void {
        this.element.style.display = 'none';
        this.uploadFsm='initial';
    }

    fileChangeEvent(event: any): void {
        //this.imageChangedEvent = event;
        this.selectedImageFile = event[0];
        this.uploadFsm='cropping';
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl);
        this.croppedImageFile = new File([event.blob],"avatar.png")
        // event.blob can be used to upload the cropped image
    }
    imageLoaded(/*image: LoadedImage*/) {
        //this.uploadFsm='initial';
        this.uploadFsm='cropping';
        //this.openEditModal();
    }
    cropperReady() {
        this.uploadFsm='cropping';
        // cropper ready
    }
    loadImageFailed() {
        this.alertService.error("Nie można załadować tego obrazu")
    }

    uploadAvatar(){
        this.uploadFsm='uploading';
        this.avatarUpload = new FileUpload;
        if(this.type=="user-avatar"){
            this.fileUploadService.uploadFile(this.avatarUpload,`${environment.authUrl}/upload/avatar`,this.croppedImageFile,(id) => {
                this.accountService.refresh().subscribe(() => { this.accountService.getSelfAccountFromApi().subscribe((x)=>{this.onUpdate.emit(x.avatarUrl);});});
            });
        } else if(this.type=="usergroup-avatar"){
            this.fileUploadService.uploadFile(this.avatarUpload,`${environment.apiUrl}/usergroup/avatar/upload/${this.userGroup.id}`,this.croppedImageFile,(id) => {
                this.onUpdate.emit(`${environment.apiUrl}/usergroup/avatar/download/${this.userGroup.id}`);
            });
        }
        this.alertService.success("Zaktualizowano Awatar");
        this.close();
    }

    removeAvatar() {
        if(this.type=="user-avatar"){
            this.accountService.deleteAvatar().subscribe(()=>{
                this.accountService.refresh().subscribe(() => { this.accountService.getSelfAccountFromApi().subscribe((x)=>{this.onUpdate.emit(null);});});
            });
        } else if(this.type=="usergroup-avatar"){
            this.userGroupService.deleteAvatar(this.userGroup.id).subscribe(()=>{ this.onUpdate.emit(null); });
        }
    }

}