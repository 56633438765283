import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionPurchaseData } from '@app/_models/subscriptionpurchasedata';
import { AlertService, PurchaseService, ValidationService } from '@app/_services';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-purchase-summary',
  templateUrl: './purchasesummary.component.html',
})
export class PurchaseSummaryComponent implements OnInit {
    userAccount: Account;
    spd: SubscriptionPurchaseData;
    creatingOrder: boolean = false;

    constructor(
      private alertService: AlertService,
      private accountService: AccountService,
      private purchaseService: PurchaseService,
      public validationService: ValidationService,
      private route: ActivatedRoute) { 
        this.accountService.account.subscribe(x => this.userAccount = x);
        this.updateSPD();
      }

    ngOnInit() {
    }

    beginPayment(){
      this.purchaseService.pay(this.spd.orderId).subscribe(
        (response) => {window.location.href=response.redirect;},
        () => {this.alertService.warn("Nie udało się zarejestrować transakcji płatniczej. Spróbuj ponownie później");}
      );
    }

    cancel(){
      this.purchaseService.cancel(this.spd.orderId).subscribe(
        ()=>{window.location.reload();},
        ()=>{this.alertService.warn("Anulowanie zamówienia nie powiodło się. Skontaktuj się z administratorem")}
      );
    }

    updateSPD(){
      this.route.params.subscribe(params => {
        this.purchaseService.get(+params['id']).subscribe(s => {
          this.spd=s;
          if(this.spd.purchaseStatus=="PAYMENT" || this.spd.purchaseStatus=="REALIZATION"){
            setTimeout(() => {this.updateSPD();}, 5000);
          } else if (this.spd.purchaseStatus=="SUCCESS"){
            this.accountService.getSelfAccountFromApi().subscribe();
          }
        });
      });
    }


}
