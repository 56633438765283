import { CommonModule } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { AudioService } from '@app/_services/audio.service';
import * as Tone from 'tone';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-piano',
  templateUrl: './piano.component.html'
})
export class PianoComponent implements OnInit {
  showPiano: boolean = false;

  minNote: number = 21;
  maxNote: number = 109;
  keysNum: number = this.maxNote - this.minNote;
  synth: Tone.DuoSynth = undefined;
  toneLimiter: Tone.Limiter;

  constructor(private audioService: AudioService) {}

  ngOnInit() :void {}

  openPiano(){
    this.audioService.startAudioContext();
    this.toneLimiter = new Tone.Limiter(-4).toDestination();
    this.synth = new Tone.DuoSynth(
      {
        harmonicity: 5,
        vibratoAmount: 0.05,
        volume: -8,
        voice0:{
          oscillator: {type: "sine"},
          envelope: {attack: 0.01,decay: 0.3, sustain: 0.2, release: 0.1}
        },
        voice1:{
          oscillator: {type: "sine"},
          envelope: {attack: 0.02,decay: 0.3, sustain: 0.05, release: 0.1}
        }
    
    }).connect(this.toneLimiter);
    this.showPiano=true;
    setTimeout(this.centerKeyboard,500);
  }

  closePiano(){
    this.showPiano=false;
    this.synth.dispose();
  }

  public keyToNote(key: number){
    return key%12;
  }

  public keyToName(key: number){
    let octave = Math.floor(key/12)-1;
    let note = this.keyToNote(key);
    switch(note){
      case 0:
        return octave + "C"
      case 1:
        return octave + "C#"
      case 2:
        return octave + "D"
      case 3:
        return octave + "D#"
      case 4:
        return octave + "E"
      case 5:
        return octave + "F"
      case 6:
        return octave + "F#"
      case 7:
        return octave + "G"
      case 8:
        return octave + "G#"
      case 9:
        return octave + "A"
      case 10:
        return octave + "A#"
      case 11:
        return octave + "H"
    }
    return "";
  }

  play(event: MouseEvent,note: number){
    if(event.buttons==1){
      this.synth.triggerAttack(Tone.Frequency(note,"midi").toFrequency())
    } else {
      this.synth.triggerRelease()
    }
  }

  playtouch(note: number){
      this.synth.triggerAttack(Tone.Frequency(note,"midi").toFrequency())
  }

  centerKeyboard(){
    let elem = document.getElementById("piano-keys");
    let pianoWidth = elem.scrollWidth;
    let screenWidth = elem.clientWidth;
    let scrollPos = (pianoWidth-screenWidth)/2;
    elem.scroll(scrollPos,0)
  }

  @HostListener('mouseup')
  stop(){
    if(this.synth)
      this.synth.triggerRelease()
  }
  @HostListener('touchcancel')
  stoptouchcancel(){
    if(this.synth)
      this.synth.triggerRelease()
  }
  @HostListener('touchend')
  stoptouchend(){
    if(this.synth)
      this.synth.triggerRelease()
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    this.audioService.clean();
    this.synth.dispose();
  }

}
