import { Component, OnInit } from '@angular/core';
import { AlertService, ValidationService } from '@app/_services';
import { Account } from '../_models';
import { AccountService, AuthServerService } from '../_services';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-passwordforgotten',
  templateUrl: './passwordforgotten.component.html',
})
export class PasswordForgottenComponent implements OnInit {
    userAccount: Account;
    fsm: string; //form,pending,success,error
    email: string = '';

    constructor(
        private alertService: AlertService,
        private authServerService: AuthServerService,
        private accountService: AccountService,
        public validationService: ValidationService) { 
            this.accountService.account.subscribe(x => this.userAccount = x);
            this.fsm='form';
      }

    ngOnInit() {
    }

    sendPasswordResetLink(){
        if(this.fsm!='form')
            return;
        this.email = (<HTMLInputElement>document.getElementById("email")).value;
        if(this.validationService.validateEmail(this.email,true)===''){
            this.fsm='pending';
            this.authServerService.reqSendPasswordResetLink(this.email).subscribe(()=>{this.fsm='success'},err =>{this.fsm='error'});
        } else{
            this.alertService.warn("Adres email niepoprawny.");
        }
    }
}