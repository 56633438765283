import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { User, UserGroup } from '@app/_models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserGroupService {
    constructor(private http: HttpClient) { }

    getUserOwnedAll() {
        return this.http.get<UserGroup[]>(`${environment.apiUrl}/usergroup/owned/all`);
    }

    getUserBelongAll() {
        return this.http.get<UserGroup[]>(`${environment.apiUrl}/usergroup/belong/all`);
    }
    
    getById(id: number) {
        return this.http.get<UserGroup>(`${environment.apiUrl}/usergroup/${id}`);
    }

    create(title: string) : Observable<any> {
        return this.http.post(`${environment.apiUrl}/usergroup/create`, title);
    }

    update(userGroup: UserGroup) {
        return this.http.post(`${environment.apiUrl}/usergroup/update`, userGroup);
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/usergroup/delete/${id}`);
    }

    //INVITATIONS
    getInvitations(id: number){
        return this.http.get<User[]>(`${environment.apiUrl}/usergroup/invitations/${id}`);
    }

    inviteMember(userGroupId:number ,userId: string){
        return this.http.get(`${environment.apiUrl}/usergroup/invitemember/${userGroupId}/${userId}`);
    }

    acceptInvitation(userGroupId:number){
        return this.http.get(`${environment.apiUrl}/usergroup/acceptinvitation/${userGroupId}`);
    }

    declineInvitation(userGroupId:number){
        return this.http.get(`${environment.apiUrl}/usergroup/declineinvitation/${userGroupId}`);
    }

    revokeInvitation(userGroupId:number,userId:string){
        return this.http.get(`${environment.apiUrl}/usergroup/revokeinvitation/${userGroupId}/${userId}`);
    }

    removeUser(userGroupId: number,userId: string) {
        return this.http.get(`${environment.apiUrl}/usergroup/removemember/${userGroupId}/${userId}`);
    }

    downloadAvatar(id: number): Observable<Blob> {
        return this.http.get(`${environment.apiUrl}/usergroup/avatar/download/${id}`, { responseType: 'blob' });
    }

    deleteAvatar(id: number) {
        return this.http.delete(`${environment.apiUrl}/usergroup/avatar/delete/${id}`);
    }
}