import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Article } from '@app/_models';
import { BlogService } from '@app/_services';


@Component({
  selector: 'app-article',
  templateUrl: './article.component.html'
})
export class ArticleComponent implements OnInit {
  date : string;
  id: number;
  article: Article;

    constructor(private route: ActivatedRoute,
                private blogService: BlogService,
                private titleService: Title) { }

    ngOnInit() {
      this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
        this.blogService.getArticleById(this.id).subscribe(x => {
          this.article = x; 
          this.date = new Date(x.creationDate).toLocaleTimeString() + " " + new Date(x.creationDate).toLocaleDateString();
          this.titleService.setTitle(x.title);
        });
      });
    }
}