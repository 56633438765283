<!-- COOKIES -->
<div *ngIf="showCookiesWarn" class="fixed z-[90] bottom-0 right-0 left-0 bg-[#D8D8D8] text-center py-4">
    <div class="mx-4 md:mx-24 flex gap-4 md:gap-16 items-center justify-center">
        <div class="flex-1">
            <p class="font-label">Nasz serwis internetowy choiry.io używa plików cookies do zapewnienia funkcjonalności logowania oraz zbierania statystyk dotyczących korzystania z serwisu. W ustawieniach twojej przeglądarki internetowej możesz zablokować zapisywanie plików cookies. Szczegóły znajdziesz w Polityce Prywatności.</p>
        </div>
        <div class="flex-none w-6">
            <span class="icon-24 icon-btn" (click)="onAccept()">close</span>
        </div>
    </div>
</div>
