<div *ngIf="cantLoad">
<h1>Zasób do którego się odwołujesz nie istnieje lub nie masz uprawnień by go załadować.</h1>
</div>

<div *ngIf="piece" #playerDiv class="player">
  <!-- TR-H=98px -->
    <!-- TITLE PANEL -->
    <div class="w-full bg-primary text-white px-6 py-2">
      <div class="w-full flex items-center">
        <div class="flex-1">
          <div [hidden]="titleEditing" class="cursor-pointer" (click)="changePieceTitle('piece-title-input')"><h3 class="p-0">{{piece.title}}</h3></div>
          <div [hidden]="!titleEditing" class="relative">
            <input class="choiry-input mx-4" type="text" (input)="validationService.updateName('piece-title-input');" (blur)="changePieceTitle('piece-title-input')" (keyup.enter)="blurInput($event)" id="piece-title-input" value="{{piece.title}}"/>
            <div class="choiry-input-feedback" id="validation-feedback-piece-title-input"></div>
          </div>
        </div>
        <div class="flex-none flex items-center relative">
          <span (click)="showNotes = !showNotes" class="icon-btn icon-48" [innerHTML]="showNotes?'arrow_drop_up':'arrow_drop_down'"></span>
          <span *ngIf="isEditor" (click)="openSharingModal('piece-sharing');" class="icon-btn icon-48">share</span>
        </div>
        <div class="flex-none w-12 h-12">
          <avatar type="user-avatar" [avatarUrl]="piece.owner.avatarUrl" [userId]="piece.ownerId" [firstName]="piece.owner.firstName" [lastName]="piece.owner.lastName" [showCreationTooltip]="true" [tooltipDate]="piece.creationDate"></avatar>
        </div>
      </div>
      <!-- NOTES -->
      <div *ngIf="showNotes" class="p-2">
        <div [hidden]="notesEditing" class="min-h-[8em] font-mono whitespace-pre-wrap"[ngClass]="isEditor ? 'cursor-pointer' : ''" (click)="changePieceNotes()">{{piece.notes || 'There are no notes.'}}</div>
        <div [hidden]="!notesEditing"><textarea class="w-full h-64 bg-ch-blue-50 text-ch-grey-900 p-4 rounded-lg" (blur)="changePieceNotes()" (keyup.enter)="blurInput($event)" id="piece-notes-input">{{piece.notes || ''}}</textarea></div>
      </div>
      <div *ngIf="!showNotes && piece.notes != null && piece.notes.length>0" class="cursor-pointer">
        <div (click)="showNotes = !showNotes" class="font-mono">{{ (piece.notes.length>16)? (piece.notes | slice:0:16)+'...':(piece.notes) }}</div>
      </div>
    </div>
    <!-- SIDE PANEL -->
    <div class="flex items-stretch">
      <div class="lg:flex hidden flex-col items-center w-16 p-2 bg-ch-grey-50 gap-2">
        <span *ngIf="!isPlaying()"(click)="play()" class="icon-btn icon-48" >play_circle</span>
        <span *ngIf="isPlaying()" (click)="pause()" class="icon-btn icon-48">pause</span>
        <span (click)="replay()" class="icon-btn icon-48">skip_previous</span>
        <span [ngClass]="isLoopToggled ? 'icon-btn-toggled' : ''" (click)="isLoopToggled = !isLoopToggled; rescheduleTransport();" class="icon-btn icon-48" >repeat</span>
        <span (click)="piece.metronome = !piece.metronome; changeMetronome();" [ngClass]="piece.metronome ? 'icon-btn-toggled' : ''" class="icon-btn icon-48">timer</span>
        <div [hidden]="metronomeEditing" class="text-right font-mono text-xs" [ngClass]="isEditor ? 'cursor-pointer' : ''" (click)="changeBPM('metronome-tempo')">{{piece.tempo}}BPM</div>
        <div [hidden]="!metronomeEditing"><input type="text" class="choiry-input" style="width: 40px;" (input)="validationService.updateBPM('metronome-tempo');" (blur)="changeBPM('metronome-tempo')" (keyup.enter)="blurInput($event)" id="metronome-tempo" value="{{piece.tempo}}"/></div>
        <div class="choiry-input-feedback" id="validation-feedback-metronome-tempo"></div>
        <div class="flex-1"></div>
      </div>
      <!-- CHANNEL CONTROL -->
      <player-channelcontrol 
      [tracks]="sortedTracks()" 
      [trackUploads]="trackUploads"
      [isEditor]="isEditor" 
      [isRecording]="isRecording" 
      [micMeterValue]="micMeterValue"
      [playerWidth]="playerDiv.clientWidth"
      (recBtn)="recordTrack()"
      (uploadTrack)="uploadTrackFile($event)"
      ></player-channelcontrol>
      <!-- TIMELINE -->
      <player-timeline class="flex-1 relative" 
      [tracks]="sortedTracks()" 
      [trackUploads]="trackUploads"
      [isEditor]="isEditor" 
      [isRecording]="isRecording" 
      [metronome]="piece.metronome"
      [metronomeBPM]="piece.tempo"
      [timelineLength]="timelineLength"
      (onSeek)="seek($event)"
      (updateTransport)="rescheduleTransport($event)"
      ></player-timeline>
    </div>
    <!-- MOBILE BOTTOM PANEL -->
    <div class="lg:hidden fixed bottom-0 left-0 right-0 z-[50] flex items-center justify-around p-2 bg-ch-grey-50">
      <span *ngIf="!isPlaying()"(click)="play()" class="icon-btn icon-64" >play_circle</span>
      <span *ngIf="isPlaying()" (click)="pause()" class="icon-btn icon-64">pause</span>
      <span (click)="replay()" class="icon-btn icon-64">skip_previous</span>
      <span [ngClass]="isLoopToggled ? 'icon-btn-toggled' : ''" (click)="isLoopToggled = !isLoopToggled; rescheduleTransport();" class="icon-btn icon-64" >repeat</span>
      <span (click)="piece.metronome = !piece.metronome; changeMetronome();" [ngClass]="piece.metronome ? 'icon-btn-toggled' : ''" class="icon-btn icon-64">timer</span>
      <div [hidden]="metronomeEditing" class="text-right font-mono" [ngClass]="isEditor ? 'cursor-pointer' : ''" (click)="changeBPM('metronome-tempo')">{{piece.tempo}}BPM</div>
      <div [hidden]="!metronomeEditing"><input type="text" class="choiry-input" style="width: 64px;" (input)="validationService.updateBPM('metronome-tempo');" (blur)="changeBPM('metronome-tempo')" (keyup.enter)="blurInput($event)" id="metronome-tempo" value="{{piece.tempo}}"/></div>
      <div class="choiry-input-feedback" id="validation-feedback-metronome-tempo"></div>
    </div>

    <div class="flex w-full items-center gap-1 py-2 px-6"><div class="flex-1"></div><div class="flex-none icon-24">visibility</div><div class="flex-none font-body">{{piece.views}}</div></div>

    <!-- SCORE -->
    <div class="my-8">
    <player-scoreview
    [isEditor]="isEditor"
    [haveScore]="piece.haveScore"
    [urlScore]="piece.urlScore"
    [hideScore]="hideScore"
    [pieceId]="piece.id"
    ></player-scoreview>
    </div>

<div *ngIf="userAccount && !hideComments" class="px-12">
  <app-commentblock [commentBlockId]="piece.commentBlockId"></app-commentblock>
</div>
<sharing-modal *ngIf="isEditor" id="piece-sharing" objectId="{{piece.id}}" type="piece" title="Udostępnij utwór" [isPublic]="piece.sharePublicEnabled" (onPublicShare)="piece.sharePublicEnabled = $event; updatePiece();" ></sharing-modal>
</div>