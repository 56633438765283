import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

@Component({ 
    selector: 'score-render', 
    template: `
        <canvas id="canvas"></canvas>
    `, 
    encapsulation: ViewEncapsulation.None
})
export class ScoreComponent implements OnInit, OnDestroy {
    @Input() width: number;
    @Input() keyG: boolean = false;
    @Input() keyF: boolean = false;
    @Input() drawGrid: boolean = true;


    private element: any;

    constructor(private el: ElementRef) {this.element = el.nativeElement;}

    canvas: HTMLCanvasElement;
    scoreContext: CanvasRenderingContext2D;

    ngOnInit(): void {
        this.canvas = (<HTMLCanvasElement> document.getElementById("canvas"));
        this.scoreContext = this.canvas.getContext("2d");
        var canvasHeight = 128;
        var canvasWidth = this.width;
        this.scoreContext.canvas.width = canvasWidth;
        this.scoreContext.canvas.height = canvasHeight;
        this.scoreContext.strokeStyle = "black";
        this.reset();
    }

    public reset(){
        this.scoreContext.clearRect(0, 0, this.scoreContext.canvas.width, this.scoreContext.canvas.height);
        if(this.drawGrid){
            this.scoreContext.beginPath();
            for(let i=0; i<5;i++){
                this.scoreContext.moveTo(0,Math.round(i*16+32));
                this.scoreContext.lineTo(this.width,Math.round(i*16+32));
            }
            this.scoreContext.closePath();
            this.scoreContext.stroke();
        }
        if(this.keyG==true){
            this.scoreContext.font = "96px serif";
            this.scoreContext.fillText("\u{1D11E}", 0, 96);
        }
        if(this.keyF==true){
            this.scoreContext.font = "96px serif";
            this.scoreContext.fillText("\u{1D122}", 0, 96);
        }
    }


    //http://www.smufl.org/files/smufl-0.4.pdf
    public drawNote(xPos,pitch:number,value){
        //y=72 -> C4 in G key
        //y=72 -> E2 in F key
        let yPos = 120;
        let pos = 0;
        let drawSharp = false;
        let drawAdditionalLine = false;
        let drawSharpOffset = 8;
        if(this.keyG){
            if([61,63,66,68,70,73,75,78,80,82].includes(pitch))
                drawSharp=true;
            if([61,68,73,80].includes(pitch))
                drawSharpOffset=0;
            if([60,61,83].includes(pitch))
                drawAdditionalLine=true;
            if(pitch==60)pos=0;
            if(pitch==61)pos=0;
            if(pitch==62)pos=1;
            if(pitch==63)pos=1;
            if(pitch==64)pos=2;
            if(pitch==65)pos=3;
            if(pitch==66)pos=3;
            if(pitch==67)pos=4;
            if(pitch==68)pos=4;
            if(pitch==69)pos=5;
            if(pitch==70)pos=5;
            if(pitch==71)pos=6;
            if(pitch==72)pos=7;
            if(pitch==73)pos=7;
            if(pitch==74)pos=8;
            if(pitch==75)pos=8;
            if(pitch==76)pos=9;
            if(pitch==77)pos=10;
            if(pitch==78)pos=10;
            if(pitch==79)pos=11;
            if(pitch==80)pos=11;
            if(pitch==81)pos=12;
            if(pitch==82)pos=12;
            if(pitch==83)pos=13;
            if(pitch==84)pos=14;
        }
        if(this.keyF){
            if([42,44,46,49,51,54,56,58].includes(pitch))
            drawSharp=true;
            if([44,49,51,54,58].includes(pitch))
                drawSharpOffset=0;
            if([40,60].includes(pitch))
                drawAdditionalLine=true;
            if(pitch==40)pos=0; //E   -
            if(pitch==41)pos=1; //F   =
            if(pitch==42)pos=1; //Fis =
            if(pitch==43)pos=2; //G   -
            if(pitch==44)pos=2; //Gis -
            if(pitch==45)pos=3; //A   =
            if(pitch==46)pos=3; //Ais =
            if(pitch==47)pos=4; //H   -
            if(pitch==48)pos=5; //C   =
            if(pitch==49)pos=5; //Cis =
            if(pitch==50)pos=6; //D   -
            if(pitch==51)pos=6; //Dis -
            if(pitch==52)pos=7; //E   =
            if(pitch==53)pos=8; //F   -
            if(pitch==54)pos=8; //Fis -
            if(pitch==55)pos=9; //G   =
            if(pitch==56)pos=9; //Gis =
            if(pitch==57)pos=10;//A   -
            if(pitch==58)pos=10;//Ais -
            if(pitch==59)pos=11;//H   =
            if(pitch==60)pos=12;//C   -
        }

        yPos -= pos*8;

        this.scoreContext.font = "84px serif";
        if(value=="whole")
            this.scoreContext.fillText("\u{1D15D}",xPos,yPos);
        if(value=="half")
            this.scoreContext.fillText("\u{1D15E}",xPos,yPos);
        if(value=="quarter")
            this.scoreContext.fillText("\u{1D15F}",xPos,yPos);
        if(value=="eight")
            this.scoreContext.fillText("\u{1D160}",xPos,yPos);
        if(value=="sixteenth")
            this.scoreContext.fillText("\u{1D161}",xPos,yPos);
        

        if(drawAdditionalLine){
            this.scoreContext.beginPath();
            this.scoreContext.moveTo(xPos-3,yPos-8);
            this.scoreContext.lineTo(xPos+35,yPos-8);
            this.scoreContext.stroke();
        }

        if(drawSharp){
            this.scoreContext.font = "48px serif";
            this.scoreContext.fillText("\u{266F}",xPos-16,yPos+drawSharpOffset);
        }
    }

    ngOnDestroy(): void {
        this.element.remove();
    }
}