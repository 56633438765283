import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { FileUpload, Track } from '@app/_models';
import { PieceService, TrackService, ValidationService } from '@app/_services';
import { ClickOutsideDirective } from '@app/_utility';
import { ConfirmModalComponent } from '@app/modals/confirmmodal/confirmmodal.component';
import { environment } from '@environments/environment';

@Component({
  standalone: true,
  imports: [CommonModule,ClickOutsideDirective],
  selector: 'player-channelcontrol',
  templateUrl: './channelcontrol.component.html',
  styleUrls: ['./channelcontrol.component.less']
})
export class ChannelControlComponent implements OnInit {
  @ViewChild('confirmationModalContainer', {read: ViewContainerRef}) confirmationModalContainer : ViewContainerRef;
  @Input() tracks:Track[];
  @Input() trackUploads: FileUpload[]=[];
  @Input() isEditor:boolean;
  @Input() isRecording:boolean;
  @Input() micMeterValue: number;
  @Input() playerWidth: number;

  @Output() recBtn = new EventEmitter<void>();
  @Output() uploadTrack = new EventEmitter<any>();
  environment = environment;
  havePermissions=false;

  constructor( private pieceService: PieceService,
    public trackService: TrackService,
    public validationService: ValidationService ) {}

  ngOnInit() {
    this.checkPermissions();
  }

  moveTrackUp(track: Track){
    if(track.ordinalNumber <= 1) return;
    this.tracks.find((t) => track.ordinalNumber-1 == t.ordinalNumber).ordinalNumber+=1
    track.ordinalNumber-=1;
    this.updateTrack(track);
  }
  moveTrackDown(track: Track){
    if(track.ordinalNumber >= this.tracks.length) return;
    this.tracks.find((t) => track.ordinalNumber+1 == t.ordinalNumber).ordinalNumber-=1
    track.ordinalNumber+=1;
    this.updateTrack(track);
  }

  updateTrackOrdinalNumbers(track: Track, newOrdinalNumber: number){
    let tr = this.tracks.find((e) => e.ordinalNumber==newOrdinalNumber);
    if(tr != undefined)
      this.updateTrackOrdinalNumbers(tr,newOrdinalNumber+1);
    track.ordinalNumber = newOrdinalNumber;
  }


  changeTrackTitle(track: Track){
    if(!this.isEditor)
      return;
    track.titleEditing = !track.titleEditing;
    if(track.titleEditing == true){
      setTimeout(() => {
        var input = document.getElementById("track-title-input-"+track.id);
        input.focus();
      },0)
    } else {
        var input = <HTMLInputElement>document.getElementById("track-title-input-"+track.id);
        if(this.validationService.validateTrackLabel(input.value)){
          track.name=input.value;
          this.updateTrack(track);
        } else{
          input.value=track.name;
          this.validationService.updateTrackLabel("track-title-input-"+track.id);
        }
    }
  }

  updateTrackVolume(track: Track, volStr: string){
    var mutehold = track.channel.mute;
    var vol:number = Number.parseInt(volStr);
    track.channel.volume.value = vol;
    track.volumeDb = vol;
    track.channel.mute = mutehold;
  }

  submitTrackVolume(track: Track, volStr: string){
    this.updateTrackVolume(track,volStr);
    this.updateTrack(track);
  }

  checkPermissions(){
    const permissionName = "microphone" as PermissionName;
    navigator.permissions.query({ name: permissionName}).then((permissionStatus) => {
    if(permissionStatus.state=='granted')
      this.havePermissions=true;
    else
      this.havePermissions=false;
    },()=>{});
  }

  grantPermission(){
      navigator.mediaDevices.getUserMedia({ video: false, audio: true })
        .then(() => {this.havePermissions=true;});
  }

  updateTrack(track: Track){
    if(!this.isEditor)
      return;
    this.trackService.update(track).subscribe();
  }
  
  confirmDeleteTrack(track){
    this.confirmationModalContainer.clear();
    const componentRef = this.confirmationModalContainer.createComponent(ConfirmModalComponent);
    componentRef.instance.confirmType = 'track-delete';
    componentRef.instance.title = 'Czy na pewno chcesz usunąć ścieżkę '+track.name+'?';
    componentRef.instance.action.subscribe(data => {
      if(data==='confirm'){
        track.player.unsync()
        track.channel.disconnect()
        track.player.dispose()
        track.channel.dispose()
        let oldOrdinalNumber = track.ordinalNumber;
        this.tracks.splice(this.tracks.findIndex((tr) => tr.id==track.id),1);
        this.trackService.delete(track.id).subscribe(x => {
          this.tracks.filter((t)=> t.ordinalNumber>oldOrdinalNumber).forEach((t)=> t.ordinalNumber-=1)
        });
      } else if (data ==='cancel'){
        //do nothing
      }
      componentRef.destroy();
    });
  }

  downloadTrackFile(track: Track){
    var link = document.createElement('a');
    link.href = track.url;
    link.download = track.name+".mp3";
    link.click();
  }
}
