import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionPurchaseData } from '@app/_models/subscriptionpurchasedata';
import { AlertService, PurchaseService, ValidationService } from '@app/_services';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';


@Component({
  selector: 'app-purchase-status',
  templateUrl: './purchasestatus.component.html',
})
export class PurchaseStatusComponent implements OnInit {
    userAccount: Account;
    screen: number=1;
    spd: SubscriptionPurchaseData;
    creatingOrder: boolean = false;

    constructor(
      private alertService: AlertService,
      private accountService: AccountService,
      private purchaseService: PurchaseService,
      public validationService: ValidationService,
        private route: ActivatedRoute) { 
          this.accountService.account.subscribe(x => this.userAccount = x);
          this.route.params.subscribe(params => {
            purchaseService.get(+params['id']).subscribe(s => {this.spd=s;});
          });
        }
  

    ngOnInit() {
      document.getElementById("subscription-info").innerHTML=this.accountService.subscriptionInfo();
    }

}
