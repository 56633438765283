import { Component, OnInit } from '@angular/core';
import { AlertService, ValidationService } from '@app/_services';
import { AccountService } from '../_services/account.service';
import { Account } from '../_models/account';
import { RegisterForm } from '../_models/registerForm'
import { AuthServerService } from '../_services';
import { ResetPasswordForm } from '../_models';
import { ActivatedRoute } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-passwordreset',
  templateUrl: './passwordreset.component.html',
})
export class PasswordResetComponent implements OnInit {
    userAccount: Account;
    rf: ResetPasswordForm;
    fsm: string; //form,pending,error,success
    linkCode: string;

    constructor(
      public validationService: ValidationService,
      private accountService: AccountService,
      private authServerService: AuthServerService,
      private alertService: AlertService,
      private route: ActivatedRoute) { 
        this.accountService.account.subscribe(x => this.userAccount = x);
        this.rf = new ResetPasswordForm;
        this.fsm = 'form';
      }

    ngOnInit() {
      this.route.params.subscribe(params => { this.rf.code=params['code']; });
    }

    resetPassword(){
      if(this.fsm != 'form')
        return;
      this.rf.newPass = (<HTMLInputElement>document.getElementById("resetpass-password-input")).value;
      var passwordRepeat = (<HTMLInputElement>document.getElementById("resetpass-password-repeat-input")).value;

      if(this.validationService.validatePassword(this.rf.newPass,passwordRepeat,true)===''){
        this.fsm='pending';
        this.authServerService.reqResetPassword(this.rf).subscribe(()=>{this.fsm='success';},() => {this.fsm='error';});
      } else {
        this.alertService.warn("Wybierz inne hasło");
      }
    }
}