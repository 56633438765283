import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { UserGroupService, AlertService, ValidationService } from '@app/_services';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UserGroup } from '@app/_models';
import { first } from 'rxjs/operators';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { ConfirmModalComponent } from '@app/modals/confirmmodal/confirmmodal.component';


@Component({
  selector: 'app-listusergroup',
  templateUrl: './listusergroup.component.html',
})
export class ListUserGroupComponent implements OnInit {
  @ViewChild('confirmationModalContainer', {read: ViewContainerRef}) confirmationModalContainer : ViewContainerRef;
  userAccount: Account;
  userGroups: UserGroup[];
  filter: string;

  userGroupCreation = false;


  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private alertService: AlertService,
      private userGroupService: UserGroupService,
      private accountService: AccountService,
      public validationService: ValidationService
  ) {
    this.accountService.account.subscribe(x => this.userAccount = x);
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.filter = params['filter'] || 'owned';
      
      if(this.filter === 'owned'){
        this.userGroupService.getUserOwnedAll().pipe(first()).subscribe(x => { this.userGroups = x; });
      } else if(this.filter === 'belong'){
        this.userGroupService.getUserBelongAll().pipe(first()).subscribe(x => { this.userGroups = x; });
      }
    });
  }

  createUserGroup(){  
    this.userGroupCreation=false;
    var name: string = (<HTMLInputElement>document.getElementById("new-usergroup-name")).value;
    if(this.validationService.validateName(name)){
      this.userGroupService.create(name).subscribe(x => {
        //todo: move updating to service
        this.accountService.getSelfAccountFromApi().subscribe(() =>{
          this.userGroupService.getById(x.id).subscribe(y => {
            this.userGroups.push(y);
          })
        });
      });
    }
  }

  confirmDeleteUserGroup(userGroup : UserGroup){
    this.confirmationModalContainer.clear();
    const componentRef = this.confirmationModalContainer.createComponent(ConfirmModalComponent);
    componentRef.instance.confirmType = 'usergroup-delete';
    componentRef.instance.title = 'Czy na pewno chcesz usunąć grupę użytkowników '+userGroup.name+'?';
    componentRef.instance.action.subscribe(data => {
      if(data==='confirm'){
        this.userGroupService.delete(userGroup.id)
        .pipe(first())
        .subscribe(
            data => {
               this.userGroups.forEach((ug,index)=>{
               if(ug.id==userGroup.id) {
                 this.userGroups.splice(index,1);
                 this.accountService.getSelfAccountFromApi().subscribe();
                 this.alertService.success("Usunięto grupę użytkowników "+userGroup.name);
               }
             });
            },
            error => {
                this.alertService.error(error);
            });
      } else if (data ==='cancel'){
        //do nothing
      }
      componentRef.destroy();
    });
  }

  deleteUserGroup(id: number){
    this.userGroupService.delete(id)
         .pipe(first())
         .subscribe(
             data => {
                this.userGroups.forEach((userGroup,index)=>{
                if(userGroup.id==id) {
                  this.userGroups.splice(index,1);
                  this.accountService.getSelfAccountFromApi().subscribe();
                  this.alertService.success('Successfully deleted playlist'+id);
                }
              });
             },
             error => {
                 this.alertService.error(error);
             });
  }

  setFilter(filter: string){
    var queryParams: Params = { filter: filter };
    this.router.navigate([], {relativeTo: this.route, queryParams: queryParams, queryParamsHandling: 'merge', });
  }

}
