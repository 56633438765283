import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ProgressComponent } from '@app/_global/progress/progress.component';
import { FileUpload } from '@app/_models';
import { FileUploadService, PieceService } from '@app/_services';
import { ConfirmModalComponent } from '@app/modals/confirmmodal/confirmmodal.component';
import { environment } from '@environments/environment';
import { PdfViewerComponent, PdfViewerModule } from 'ng2-pdf-viewer';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  standalone: true,
  imports: [CommonModule,ProgressComponent, PdfViewerModule ],
  selector: 'player-scoreview',
  templateUrl: './scoreview.component.html',
  styleUrls: ['./scoreview.component.less']
})
export class ScoreViewComponent implements OnInit {
  @ViewChild('confirmationModalContainer', {read: ViewContainerRef}) confirmationModalContainer : ViewContainerRef;
  @ViewChild(PdfViewerComponent, { static: false }) pdfComponent: PdfViewerComponent;
  @Input() isEditor:boolean;
  @Input() haveScore:boolean;
  @Input() urlScore:string;
  @Input() hideScore:boolean;
  @Input() pieceId:number;
  @Input() linkKey:string;

  public pdfViewerHeight = 0;

  private resizeObservable$: Observable<Event>;
  private resizeSubscription$: Subscription;

  environment = environment;
  scoreUpload: FileUpload;
  currentScorePage: number = 1;
  scorePages: number = 0;

  constructor( private pieceService: PieceService,
    public fileUploadService: FileUploadService, ) {}

  ngOnInit() {
    this.scoreUpload = new FileUpload;
    this.loadScoreFile();
  }


  setScorePageNumber(pdf) { 
    this.scorePages = pdf.numPages; 
  }

  uploadScoreFile(event: any){
    this.fileUploadService.uploadFile(this.scoreUpload,`${environment.apiUrl}/piece/score/upload/${this.pieceId}`,event.target.files.item(0),(seqId) =>{
      if(this.scoreUpload.fileId != null){
        this.haveScore=true; 
        this.loadScoreFile();
      } else
        this.scoreUpload=null;
    });
  }

  loadScoreFile(){
    if(this.haveScore){
      this.pieceService.downloadScore(this.pieceId,this.linkKey).subscribe(
      data =>  {this.urlScore = URL.createObjectURL(data);},
      error => console.error(error)
      );
    }
  }

  confirmDeleteScore(){
    this.confirmationModalContainer.clear();
    const componentRef = this.confirmationModalContainer.createComponent(ConfirmModalComponent);
    componentRef.instance.confirmType = 'score-delete';
    componentRef.instance.title = 'Czy na pewno chcesz usunąć nuty?';
    componentRef.instance.action.subscribe(data => {
      if(data==='confirm'){
        this.pieceService.deleteScore(this.pieceId).subscribe(x => {this.haveScore=false; this.urlScore=null; this.scorePages=0;});
      } else if (data ==='cancel'){
        //do nothing
      }
      componentRef.destroy();
    });
  }

  public ngOnDestroy() {
      this.resizeSubscription$ && this.resizeSubscription$.unsubscribe();
  }

  public pageRendered(e: CustomEvent) {
      // initial set
      this.pdfViewerHeight = this.pdfComponent.pdfViewer.viewer.clientHeight;

      // on resize
      this.resizeObservable$ = fromEvent(window, 'resize');
      this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
        this.pdfViewerHeight = this.pdfComponent.pdfViewer.viewer.clientHeight;
      });
  }

}
