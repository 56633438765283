import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { SubscriptionPurchaseData } from '@app/_models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PurchaseService {
    constructor(private http: HttpClient) { }

    create(spd: SubscriptionPurchaseData) : Observable<any> {
        return this.http.post(`${environment.apiUrl}/purchase/order/create`, spd);
    }

    get(id: number) {
        return this.http.get<SubscriptionPurchaseData>(`${environment.apiUrl}/purchase/order/get/${id}`);
    }

    pay(id: number): Observable<any> {
        return this.http.post<any>(`${environment.apiUrl}/purchase/order/pay`,id);
    }

    cancel(id: number) {
        return this.http.post(`${environment.apiUrl}/purchase/order/cancel`,id);
    }
}