import { Component, OnInit } from '@angular/core';
import { PieceService, AlertService, ValidationService } from '@app/_services';
import { Article, Piece } from '@app/_models';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { BlogService } from '@app/_services/blog.service';

@Component({
  selector: 'app-listarticle',
  templateUrl: './listarticle.component.html',
})
export class ListArticleComponent implements OnInit {
  userAccount: Account;
  articles: Article[];
  articleCreation: boolean = false;

constructor(
    private router: Router,
    private alertService: AlertService,
    private blogService: BlogService,
    private accountService: AccountService,
    public validationService: ValidationService
    ) {
      this.accountService.account.subscribe(x => this.userAccount = x);
    }

ngOnInit() {
  this.blogService.getAllArticles().subscribe(x => { this.articles = x; });
}

createArticle(){  
  this.articleCreation=false;
  var title: string = (<HTMLInputElement>document.getElementById('new-article-title')).value;
  if(this.validationService.validateName(title)){
    this.blogService.createArticle(title).subscribe(x => {
        this.blogService.getArticleById(x.id).subscribe(y => {
          this.articles.push(y); 
        });
    });
  }
}

deleteArticle(id: number){
  this.blogService.deleteArticle(id)
       .pipe(first())
       .subscribe(
           data => {
              this.articles.forEach((piece,index)=>{
              if(piece.id==id) {
                this.articles.splice(index,1);
                this.alertService.success('Usunięto artykuł '+id);
              }
            });
           },
           error => {
               this.alertService.error(error);
           });
  }

}
