import { CommonModule } from '@angular/common';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ProgressComponent } from '@app/_global/progress/progress.component';
import { ScoreComponent } from '@app/_global/score/score.component';
import { ExerciseLesson } from '@app/_models/exerciselesson';
import { AlertService } from '@app/_services';
import { AudioService } from '@app/_services/audio.service';
import { ExerciseService } from '@app/_services/exercise.service';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { AppMenuComponent } from '@app/layout';
import * as Tone from 'tone';

@Component({
  standalone: true,
  imports: [CommonModule,AppMenuComponent,ProgressComponent,ScoreComponent],
  selector: 'app-scorereading',
  templateUrl: './scorereading.component.html',
  styleUrls: ['./scorereading.component.css']
})
export class ScoreReadingComponent implements OnInit {
    userAccount: Account;
    lessons : ExerciseLesson[];
    selectedLesson :ExerciseLesson = null;
    showOptions = false;

    allowedNotes = [];
    allowedKeys = [];
    allowedClefs = [];
    lessonMode = 'note'

    drawClef = 'G';
    drawKey = 0;
    drawNote = null;
    fsm = "ready";
    lessonProgress;
    lessonLength;
    correctAnswers;

    @ViewChild("score") score: ScoreComponent;

    constructor(private accountService: AccountService,
                private exerciseService: ExerciseService,
                private alertService: AlertService) {
        this.accountService.account.subscribe(x => this.userAccount = x);
        this.exerciseService.getExerciseLessons("score_reading").subscribe(
            (res)=>{
            this.lessons = res;
            this.selectLesson(this.lessons[0])
            this.changeState("ready");
        });
      }

    ngOnInit() {}

    selectLesson(lesson: ExerciseLesson){
        if(this.fsm=='lesson-result')
            this.changeState('ready');
        if(this.fsm=="ready"){
            this.selectedLesson = lesson;
            var lessonData = JSON.parse(lesson.data);
            this.allowedKeys = lessonData.allowedKeys;
            this.allowedNotes = lessonData.allowedNotes;
            this.allowedClefs = lessonData.allowedClefs;
            this.lessonMode = lessonData.mode;
        }
        else{
            this.alertService.info("Zatrzymaj obecną lekcję, aby ją zmienić")
        }
    }

    start(){
        this.changeState("show-task");
    }

    stop(){
        this.changeState("ready");
    }

    noteClick(note){
        if(this.fsm!='show-task')
            return;
        if(note%12 == this.drawNote%12)
            this.changeState("feedback-right");
        else
            this.changeState("feedback-wrong");
    }
    
    keyClick(key){
        if(this.fsm!='show-task')
            return;
        if(key == this.drawKey)
            this.changeState("feedback-right");
        else
            this.changeState("feedback-wrong");
    }

    drawTask(note){
        // console.log(note)
        this.score.reset(this.drawClef,this.drawKey);
        if(note != null)
            this.score.drawNote(200,note,"whole");
    }

    changeState(newState){
        if(newState=="ready"){
            this.score.reset(this.drawClef,this.drawKey);
            this.clearStatistics();
        }
        if(newState=="show-task"){
            this.score.reset(this.drawClef,this.drawKey);
            this.lessonProgress+=1;
            this.generateTask();
        }
        if(newState=="feedback-wrong"){
            if(this.lessonProgress==this.lessonLength)
                this.delay(500).then(()=>this.changeState("lesson-result"))
            else
                this.delay(500).then(()=>this.changeState("show-task"))
        }
        if(newState=="feedback-right"){
            this.correctAnswers+=1;
            if(this.lessonProgress==this.lessonLength)
                this.delay(500).then(()=>this.changeState("lesson-result"))
            else
                this.delay(500).then(()=>this.changeState("show-task"))
        }
        if(newState=="lesson-result"){
            if(this.selectedLesson!=null){
                if(this.selectedLesson.bestScore<this.correctAnswers){
                    var oldBestScore = this.selectedLesson.bestScore;
                    this.selectedLesson.bestScore = this.correctAnswers;
                    this.exerciseService.updateLessonProgress(this.selectedLesson)
                    .subscribe(()=>{},()=>{this.selectedLesson.bestScore=oldBestScore;});
                }
            }
        }
        this.fsm=newState;
    }

    generateTask(){
        this.drawKey = this.allowedKeys[this.randomInt(0,this.allowedKeys.length-1)];
        if(this.lessonMode == 'keys'){
            //do nothing...
            this.drawTask(null);
            this.drawClef = this.allowedClefs[this.randomInt(0,this.allowedClefs.length-1)];
        }
        if(this.lessonMode == 'notes'){
            this.drawNote = this.allowedNotes[this.randomInt(0,this.allowedNotes.length-1)];
            if(this.allowedClefs.length==1)
                this.drawClef=this.allowedClefs[0];
            else{
                if(this.drawNote>=60)
                    this.drawClef='G';
                else
                    this.drawClef='F';
            }
            this.drawTask(this.drawNote);
        }
    }

    clearStatistics(){
        this.lessonProgress = 0;
        this.correctAnswers = 0;
        this.lessonLength = 100;
    }

    randomInt(min,max){
        return Math.floor(Math.random() * (max+1-min))+min;
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

}