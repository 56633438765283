import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { AlertService, PieceService, PlaylistService, SharingService, UserGroupService, ValidationService } from '@app/_services';
import { Piece, Playlist, User, UserGroup } from '@app/_models';
import { Account } from '@app/authserver/_models';
import { AccountService, AuthServerService } from '@app/authserver/_services';
import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { AppMenuComponent } from '@app/layout';
import { AvatarComponent } from '@app/_global';

@Component({
  standalone: true,
  imports: [CommonModule,RouterModule,AppMenuComponent,AvatarComponent],
  selector: 'app-usergrdoup',
  templateUrl: './usergroup.component.html'
})
export class UserGroupComponent implements OnInit {
    userAccount: Account;
    id: number;
    isEditor: boolean = false;
    userGroup: UserGroup;
    creationDateString: string = '';
    playlists: Playlist[];
    pieces: Piece[];
    showUsers: boolean = true;
    showPlaylists: boolean = false;
    showPieces: boolean = false;
    invitedUsers: User[] = [];
    nameEditing: boolean = false;
    descriptionEditing: boolean = false;

    //invitation user search
    foundUsers: User[] =[];
    filteredUsers: User[] =[];
    lastUserListUpdate: number;
    isThrottled: boolean = false;
    showSearchResults: boolean = false;

    constructor(private route: ActivatedRoute,
      private userGroupService: UserGroupService,
      private accountService: AccountService,
      private pieceService: PieceService,
      private playlistService: PlaylistService,
      private validationService: ValidationService,
      private alertService: AlertService,
      private authServerService: AuthServerService,
      private titleService: Title
      ) {
        this.accountService.account.subscribe(x => this.userAccount = x);
        }

    ngOnInit() {
        this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number

        //load the details
        this.userGroupService.getById(this.id).subscribe(x => { 
          this.userGroup = x; 
          this.isEditor = this.userAccount.userId==this.userGroup.ownerId;
          if(this.isEditor)
            this.userGroupService.getInvitations(this.id).subscribe((i)=>{this.invitedUsers=i;})
          this.creationDateString=new Date(x.creationDate).toLocaleTimeString() + " " + new Date(x.creationDate).toLocaleDateString();
          this.titleService.setTitle(this.userGroup.name);
        });
        this.pieceService.getUserGroupSharedAll(this.id).subscribe(x => { 
          this.pieces = x; 
        });
        this.playlistService.getUserGroupSharedAll(this.id).subscribe(x => { 
          this.playlists = x; 
        });

      })
    }
    selectTab(tab){
      this.showUsers=false;
      this.showPlaylists=false;
      this.showPieces=false
      if(tab==='users'){
        this.showUsers=true;
      } else if (tab==='playlists'){
        this.showPlaylists=true;
      } else if (tab==='pieces'){
        this.showPieces=true;
      }
    }
    revokeInvitation(userId: string){
      this.userGroupService.revokeInvitation(this.id,userId).subscribe(()=>{
        this.invitedUsers.forEach((u,index)=>{
          if(u.id===userId) {
            this.invitedUsers.splice(index,1);
          }
        })
      })
    }
    removeMemberFromUserGroup(userId: string){
      this.userGroupService.removeUser(this.userGroup.id,userId).subscribe(()=>{
        this.userGroup.users.forEach((u,index)=>{
          if(u.id===userId) {
            this.userGroup.users.splice(index,1);
          }
        })
      });
  }

  updateUserGroup(){
    if(!this.isEditor)
      return;
    this.userGroupService.update(this.userGroup).subscribe();
  }

  changeUserGroupName(inputName: string){
    if(!this.isEditor)
      return;
    this.nameEditing = !this.nameEditing;
    if(this.nameEditing == true){
      setTimeout(() => {
        var input = document.getElementById(inputName);
        input.focus();
      },0)
    } else {
        var input = <HTMLInputElement>document.getElementById(inputName);
        if(this.validationService.validateName(input.value)){
          this.userGroup.name=input.value;
          this.updateUserGroup();
        } else{
          input.value=String(this.userGroup.name);
          this.validationService.updateName(inputName);
        }
    }
  }

  changeUserGroupDescription(){
    if(!this.isEditor)
      return;
    this.descriptionEditing = !this.descriptionEditing;
    if(this.descriptionEditing == true){
      setTimeout(() => {
        var input = document.getElementById("usergroup-description-input");
        input.focus();
      },0)
    } else {
        var input = <HTMLInputElement>document.getElementById("usergroup-description-input");
        if(this.validationService.validateDescription(input.value)){
          this.userGroup.description=input.value;
          this.updateUserGroup()
        } else {
          this.alertService.error("Nie można zapisać opisu grupy. Niepoprawny tekst!");
          this.descriptionEditing = !this.descriptionEditing;
        }
    }
  }

  blurInput($event){
    ($event.target as HTMLElement).blur();
  }

   //SEARCH MANAGEMENT
   updateSearchResults(){
    var currentDate = new Date();
    var timebalance = this.lastUserListUpdate+2000-currentDate.getTime();
    if(this.isThrottled)
        return;
    this.isThrottled=true;
    if(timebalance<0)
        timebalance=0;
    setTimeout(() =>{
        var query = (<HTMLInputElement>document.getElementById("usergroup-search-input")).value;
        if(query.length>1)
            this.authServerService.reqSearchUser(query).subscribe(x => { this.foundUsers = x; this.subtractUserSets(); this.isThrottled=false; this.lastUserListUpdate = currentDate.getTime();});
        else {
            this.isThrottled=false;
            this.foundUsers=[];
        }
    }, timebalance)
    this.subtractUserSets();
  }

  hideSearchResults(){
      setTimeout(() =>{this.showSearchResults=false;},500);
  }

  subtractUserSets(){
      if(this.foundUsers){
          this.filteredUsers = this.foundUsers.filter(x => {
            var dup=0; 
            this.userGroup.users.forEach(y => {if(x.id==y.id) dup+=1;});
            this.invitedUsers.forEach(y => {if(x.id==y.id) dup+=1;});
            return dup==0;
          });
          this.filteredUsers = this.filteredUsers.filter(x => x.id != this.userAccount.userId)
      }
  }

  inviteUser(user: User){
    this.userGroupService.inviteMember(this.userGroup.id,user.id).subscribe(()=>{
      this.invitedUsers.push(user);
    });
  }
}
