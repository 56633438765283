import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Account } from '@app/authserver/_models';
import { AccountService} from '@app/authserver/_services';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-menuuseraccount',
  templateUrl: './menuuseraccount.component.html'
})
export class AppMenuUserAccountComponent implements OnInit {
  userAccount: Account;
  environment = environment;
  showMenu: boolean = false;

  constructor(
      private accountService: AccountService,
      private router: Router
      ) {
        this.accountService.account.subscribe(x => {this.userAccount = x;});
  }

  ngOnInit() {
  }
  
  logout(){
    this.accountService.logout();
    this.router.navigate(['/']);
  }
}
