export class Account {
    userId: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    avatarUrl: string;
    roles: string[];
    idProvider: string;
    //LOCAL EXTENSION
    endOfStandardPlan: number = 0;
    endOfProPlan: number = 0;
    usedSpace: number = 0;
    groups: number[];
    ownedGroups: number[];
    ownedPlaylists: number[];
    ownedPieces: number[];
}