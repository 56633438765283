import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdownfilter',
  templateUrl: './dropdownfilter.component.html'
})
export class AppDropdownFilterComponent {
    showOptions: boolean = false;
    selected: any = null;
    @Input() options : any;
    @Output() onSelect: EventEmitter<string> = new EventEmitter;

  constructor( ) { }

  select(option){
    this.selected=option;
    this.showOptions=false;
    this.onSelect.emit(option.key)
  }
}
