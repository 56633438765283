<div class="avatar-modal flex flex-col gap-4 items-center relative">
    <div class="absolute top-0 right-0 p-6">
        <div (click)="close()" class="icon-btn icon-24 text-ch-grey-100">close</div>
    </div>
    <div class="flex flex-col w-full gap-8 items-center">
        <div class="font-headline">Zmień awatar</div>
        <label *ngIf="uploadFsm=='initial'" fileDragDrop (filesChangeEmiter)="fileChangeEvent($event.dataTransfer.files)" for="avatar-input-file" 
        class="flex flex-col w-full gap-6 py-6 rounded-[12px] items-center border-primary border-2 border-dashed file-drop-zone">
            <div class="icon icon-40 text-primary">upload</div>
            <div class="flex flex-col gap-3 items-center">
                <div class="font-body text-ch-grey-800">Przeciągnij plik tutaj lub</div>
                <div class="ch-btn ch-btn-filled ch-btn-sec">Wybierz plik z dysku</div>
            </div>
            <input type="file" id="avatar-input-file" accept="image/*" (change)="fileChangeEvent($event.target.files)"/>
        </label>
        <div *ngIf="uploadFsm=='initial' && (
            ( type == 'user-avatar' && userAccount.avatarUrl && userAccount.avatarUrl != '') || 
            (type == 'usergroup-avatar' && userGroup.haveAvatar)
            )" class="flex gap-2 items-center text-ch-grey-200 cursor-pointer hover:opacity-80" (click)="removeAvatar()">
        <div class="icon icon-24">close</div>
        <div class="font-boldbody underline">Usuń obecny awatar</div>
        </div>
    </div>
    <div *ngIf="uploadFsm=='cropping'" class="cropper-elem">
        <image-cropper
                [imageFile]="selectedImageFile"
                [maintainAspectRatio]="true"
                [containWithinAspectRatio]="true"
                [aspectRatio]="1 / 1"
                [roundCropper]="true"
                [resizeToWidth]="128"
                [resizeToHeight]="128"
                [cropperMinWidth]="128"
                [alignImage]="'left'"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                (cropperReady)="cropperReady()"
                (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </div>
    <div *ngIf="uploadFsm=='cropping'" class="ch-btn ch-btn-filled ch-btn-sec" (click)="uploadAvatar()">Prześlij Awatar</div>
    <h3 *ngIf="uploadFsm=='uploading'" class="text-center h-4 p-8">Przesyłanie...</h3>
</div>
<div class="avatar-modal-background"></div>