import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ScoreComponent } from '@app/_global/score/score.component';
import { AudioService } from '@app/_services/audio.service';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import * as Tone from 'tone';

@Component({
  selector: 'app-intervalrecognition',
  templateUrl: './intervalrecognition.component.html',
  styleUrls: ['./intervalrecognition.component.css']
})
export class IntervalRecognitionComponent implements OnInit,OnDestroy {
    userAccount: Account;
    showOptions = false;
    allowedIntervals = [7,12];
    /*
    * ready
    * playing
    * answer
    * feedback-wrong
    * feedback-right
    */
    fsm = "ready";
    firstNote = 0;
    secondNote = 0;
    intervalCount = 0;
    correctAnswers = 0;
    sampler: Tone.Sampler;

    @ViewChild("score") score: ScoreComponent;

    constructor(private audioService: AudioService,
                private accountService: AccountService) {
        this.accountService.account.subscribe(x => this.userAccount = x);
      }

    ngOnInit() {
        this.sampler = new Tone.Sampler({urls: {A3: "A1.mp3",A4: "A2.mp3",},baseUrl: "https://tonejs.github.io/audio/casio/",}).toDestination();
        this.changeState("ready");
    }

    start(){
        this.changeState("playing");
    }

    stop(){
        this.changeState("ready");
    }

    intervalClick(interval){
        if(this.fsm=="ready"){
            if(this.allowedIntervals.includes(interval))
                this.allowedIntervals.splice(this.allowedIntervals.indexOf(interval),1);
            else
                this.allowedIntervals.push(interval);
            this.updateAllowedIntervals();
        } 
        else if(this.fsm=="answer") {
            if(!this.allowedIntervals.includes(interval))
                return;
            if(interval == Math.abs(this.firstNote-this.secondNote))
                this.changeState("feedback-right");
            else
                this.changeState("feedback-wrong");
        }
    }

    changeState(newState){
        if(newState=="ready"){
            this.clearStatistics();
            this.updateAllowedIntervals();
        }
        if(newState=="playing"){
            this.score.reset();
            this.intervalCount+=1;
            this.generateInterval()
            this.playInterval()
        }
        if(newState=="feedback-wrong"){
            this.score.drawNote(120,this.firstNote,"whole");
            this.score.drawNote(220,this.secondNote,"whole");
            this.delay(1000).then(()=>this.changeState("playing"))
        }
        if(newState=="feedback-right"){
            this.correctAnswers+=1;
            this.score.drawNote(120,this.firstNote,"whole");
            this.score.drawNote(220,this.secondNote,"whole");
            this.delay(1000).then(()=>this.changeState("playing"))
        }
        this.fsm=newState;
    }

    updateAllowedIntervals(){
        for(let i=1;i<=12;i++){
            let elem = document.getElementById("interval-"+String(i));
            elem.classList.remove("interval-allowed")
            if(this.allowedIntervals.includes(i)){
                elem.classList.add("interval-allowed")       
            }
        }
    }

    generateInterval(){
        let interval = this.allowedIntervals[Math.floor(Math.random() * (this.allowedIntervals.length))]
        //C4=60
        if(Math.random()>0.5){
            this.firstNote = Math.floor(Math.random()*(13)+60);
            this.secondNote = this.firstNote + interval;
        }
        else {
            this.firstNote = Math.floor(Math.random()*(13)+72);
            this.secondNote = this.firstNote - interval;
        }
    }

    clearStatistics(){
        this.correctAnswers = 0;
        this.intervalCount = 0;
    }

    playInterval(){
        //play melodic interval
        this.changeState("repeat");
        this.audioService.startAudioContext();
        Tone.Transport.cancel();
        Tone.Transport.schedule(() => this.sampler.triggerAttackRelease(Tone.Frequency(this.firstNote,"midi").toFrequency(), 0.9),0.0);
        Tone.Transport.schedule(() => this.sampler.triggerAttackRelease(Tone.Frequency(this.secondNote,"midi").toFrequency(), 0.9),1.0);
        this.delay(2000).then(() => this.changeState("answer"));
        this.audioService.restartTransport()
    }

    delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    @HostListener('unloaded')
    ngOnDestroy() {
      this.audioService.clean();
      this.sampler.dispose();
    }

}