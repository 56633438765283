import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PieceService, FileUploadService, TrackService, AlertService, ValidationService } from '@app/_services';
import { FileUpload, Piece, Track } from '@app/_models';
import { DomSanitizer, SafeResourceUrl, SafeUrl, Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import * as Tone from 'tone';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { AudioService } from '@app/_services/audio.service';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { CommonModule } from '@angular/common';
import { ChannelControlComponent } from './channelcontrol/channelcontrol.component';
import { ScoreViewComponent } from './scoreview/scoreview.component';
import { TempoSelectorComponent } from './temposelector/temposelector.component';
import { TimelineComponent } from './timeline/timeline.component';
import { AvatarComponent } from '@app/_global';
import { SharingModalComponent } from '@app/modals/sharingmodal/sharingmodal.component';
import { CommentBlockComponent } from '@app/commentblock';

declare var MediaRecorder: any;

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ChannelControlComponent,
    ScoreViewComponent,
    //TempoSelectorComponent,
    TimelineComponent,
    AvatarComponent,
    SharingModalComponent,
    CommentBlockComponent
  ],
  selector: 'app-player',
  templateUrl: './player.component.html'
})
export class PlayerComponent implements OnInit {
  @ViewChild("playerDiv") playerDiv: HTMLElement;
  @Input() paramId: number;
  @Input() paramLinkKey: string | undefined;
  @Input() paramEmbedded: boolean;
  @Input() hideScore: boolean = false;
  @Input() hideComments: boolean = false;
  id: number;
  linkKey: string | undefined;
  private sub: any;
  userAccount: Account;
  isSubscriptionActive: boolean=false;
  isEditor: boolean = false;
  piece: Piece;
  showNotes: boolean = false;
  metronomeEditing: boolean = false;
  titleEditing: boolean = false;
  notesEditing: boolean = false;
  isLoopToggled: boolean = false;
  cantLoad: boolean = false;
  timelineLength: number = 0; // in seconds

  //TODO REPLACE BY CSS VARIABLES
  colors: any = {"primary": "#7274D4",
          "primary_content": "#FFFFFF",
          "secondary": "#FFC567",
          "secondary_content": "#2A2828",
          "neutral": "#646262",
          "neutral_content": "#FFFFFF",
          "grey_900": "#2A2828",
          "grey_50": "#F0EFEF"
          };
//COPIED FROM TAILWIND FOR USE IN CANVAS
  metronomeLimiter: Tone.Limiter;
  isRecording: boolean = false;
  mic : Tone.UserMedia;
  micMeter : any;
  micMeterValue : number;
  recorder : MediaRecorder;
  chunks : any = [];

  trackUploads: FileUpload[] = [];

    constructor(private router: Router,
      private route: ActivatedRoute,
      private pieceService: PieceService,
      public trackService: TrackService,
      public validationService: ValidationService,
      private sanitizer: DomSanitizer,
      public fileUploadService: FileUploadService,
      private alertService: AlertService,
      private accountService: AccountService,
      private audioService: AudioService,
      private titleService: Title
      ) {
        this.accountService.account.subscribe(x => this.userAccount = x);
      }

    ngOnInit() {
        //this.audioService.startAudioContext();
        this.id = this.paramId;
        this.linkKey = this.paramLinkKey;
        this.metronomeLimiter = new Tone.Limiter(-12).toDestination();

        //record prepare
        const dest  = Tone.context.createMediaStreamDestination();
        this.mic = new Tone.UserMedia();
        this.micMeter = new Tone.Meter();
        this.recorder = new MediaRecorder(dest.stream);
        this.mic.connect(dest);
        this.mic.connect(this.micMeter);
        setInterval(() => {this.micMeterValue = 100 + (this.micMeter.getValue()*2); if(this.micMeterValue<0) this.micMeterValue=0;},50);
        this.recorder.ondataavailable = evt => this.chunks.push(evt.data);
        this.recorder.onstop = evt => {
          let blob = new Blob(this.chunks, { type: 'audio/ogg; codecs=opus' });
          this.uploadRecordedFile(new File([blob],"recorded"));
          this.chunks = [];
        };

        //load the details
        this.pieceService.getById(this.id,this.linkKey).subscribe(x => {
          this.piece = x;
          this.titleService.setTitle(this.piece.title);
          //prepare metronome
          this.piece.metronomeChannel = new Tone.Channel().connect(this.metronomeLimiter);
          this.piece.metronomePlayer = new Tone.Player("/assets/sounds/tick1.mp3").connect(this.piece.metronomeChannel);
          Tone.Transport.bpm.value = this.piece.tempo;
          
          for(let track of this.piece.tracks)
            this.loadTrackFile(track);
          if(!this.paramEmbedded && this.userAccount)
            this.isEditor=(this.piece.ownerId===this.userAccount.userId);
        },err => {this.cantLoad=true;});
    }

    prepareTick(){
      this.piece.metronomeChannel.mute = !this.piece.metronome;
      this.piece.metronomePlayer.unsync();
      let metronomeTime = Math.max(this.timelineLength,60);
      this.audioService.scheduleMetronome(this.piece.metronomePlayer,metronomeTime);
    }

    makePlayer(track: Track) {
      track.meter = new Tone.Meter();
      track.limiter = new Tone.Limiter(-12).toDestination();
      track.limiter.connect(track.meter);
			track.channel = new Tone.Channel().connect(track.limiter);
			track.player = new Tone.Player(track.url, () => {track.loadingAudio=false;this.updateTimelineLength();});
      track.player.connect(track.channel);
      setInterval(() => {track.meterValue = 100 + (track.meter.getValue()*2); if(track.meterValue<0) track.meterValue=0;},50);
      track.channel.volume.value = track.volumeDb;
      track.channel.solo = track.solo;
      track.channel.mute = track.mute;
      this.rescheduleTransport();
		}

    updateTimelineLength(){
      var endTime = 0;
      for( let track of this.piece.tracks)
        if(!track.loadingAudio)
        var endTime = Math.max(endTime,track.player.buffer.duration+track.offset-track.trimLeft-track.trimRight);
      this.timelineLength = endTime;
    }

    /*data managing*/
    addTrack(id : number){
      this.trackService.getById(id,this.linkKey).subscribe(x => {this.loadTrackFile(x); this.piece.tracks.push(x);});
      this.trackUploads.forEach((trackUpload,index)=>{
        if(trackUpload.fileId==id) {this.trackUploads.splice(index,1);}});
    }
    
    recordTrack(){
      this.isRecording = !this.isRecording;
      if(this.isRecording){
        this.mic.open();
        this.play();
        this.recorder.start();
      } else {
        this.mic.close();
        this.recorder.stop();
        this.pause();
      }
    }
  
    /*UI handlers*/

    openSharingModal(id: string) {
      document.getElementById("piece-sharing").style.display = 'block';
      //document.body..    overflow: hidden;
    }

    seek(time){
      if(this.isRecording)
        this.recordTrack();
      Tone.Transport.seconds=time;
    }

    /*sound functions*/
    rescheduleTransport(){
      this.updateTimelineLength();
      Tone.Transport.cancel();
      this.prepareTick();
      for(let track of this.piece.tracks){
        if(!track.loadingAudio){
          track.player.unsync();
          track.player.sync().start(track.offset,track.trimLeft,track.player.buffer.duration-track.trimRight-track.trimLeft);
        }
      }
      if(!this.isRecording)
        Tone.Transport.schedule(() => {
          if(this.isLoopToggled) {
            this.replay();
          } else {
            this.pause();
            this.replay();
          }
        }, (this.timelineLength+0.1));
    }

    play(){
      this.audioService.startAudioContext();
      this.rescheduleTransport();
      this.audioService.startTransport();
    }
    replay(){
      if(this.isRecording)
        this.recordTrack();
      if(this.isPlaying()) {
        Tone.Transport.stop();
        this.play()
      } else {
        Tone.Transport.stop();
      }
    }
    pause(){
      if(this.isRecording)
        this.recordTrack();
      Tone.Transport.pause();
    }

    isPlaying(): boolean{
      return Tone.Transport.state == "started";
    }

    /*backend synchronization*/

  
    uploadTrackFile(event: any){
      var i = this.trackUploads.push(new FileUpload) - 1;
      this.fileUploadService.uploadFile(this.trackUploads[i],`${environment.apiUrl}/audio-tr/upload/${this.piece.id}`,event.target.files.item(0),(seqId) =>{
        let trackUploadIndex = this.trackUploads.findIndex((fu)=>{return fu.seqId==seqId}); 
        if(this.trackUploads[trackUploadIndex].fileId != null)
          this.addTrack(this.trackUploads[trackUploadIndex].fileId);
        else
          this.trackUploads.splice(trackUploadIndex,1);
          this.trackUploads = [...this.trackUploads];  //refresh child component
      });
      this.trackUploads = [...this.trackUploads];  //refresh child component
    }

    uploadRecordedFile(file: File){
      var i = this.trackUploads.push(new FileUpload) - 1;
      this.fileUploadService.uploadFile(this.trackUploads[i],`${environment.apiUrl}/audio-tr/upload/${this.piece.id}`,file,(seqId) =>{
        let trackUploadIndex = this.trackUploads.findIndex((fu)=>{return fu.seqId==seqId}); 
        if(this.trackUploads[trackUploadIndex].fileId != null)
          this.addTrack(this.trackUploads[trackUploadIndex].fileId);
        else
          this.trackUploads.splice(trackUploadIndex,1);
          this.trackUploads = [...this.trackUploads];  //refresh child component
      });
      this.trackUploads = [...this.trackUploads];  //refresh child component
    }

    loadTrackFile(track: Track){
      track.loadingAudio=true;
      this.trackService.download(track.id,this.linkKey).subscribe(
        data =>  {track.url = URL.createObjectURL(data); this.makePlayer(track);},
        error => console.error(error)
      );
    }

    sortedTracks(){
      return this.piece.tracks.sort((a, b) => a.ordinalNumber > b.ordinalNumber ? 1 : a.ordinalNumber === b.ordinalNumber ? 0 : -1);
    }

    changeBPM(inputName: string){
      if(!this.isEditor)
        return;
      this.metronomeEditing = !this.metronomeEditing;
      if(this.metronomeEditing == true){
        setTimeout(() => {
          var input = document.getElementById(inputName);
          input.focus();
        },0)
      } else {
          var input = <HTMLInputElement>document.getElementById(inputName);
          if(this.validationService.validateBPM(input.value)){
            this.piece.tempo=Number(input.value);
            Tone.Transport.bpm.value=this.piece.tempo;
            this.prepareTick();
            this.updatePiece();
          }else{
            input.value=String(this.piece.tempo);
            this.validationService.updateBPM(inputName);
          }
      }
    }

    changeMetronome(){
      this.piece.metronomeChannel.mute = !this.piece.metronome;
      if(!this.isEditor)
        return;
      this.updatePiece();
    }

    changePieceTitle(inputName: string){
      if(!this.isEditor)
        if(this.paramEmbedded){
          if(this.linkKey !== undefined)
            this.router.navigate([`/piece/link/${this.id}/${this.linkKey}`]);
          else
            this.router.navigate([`/piece/${this.id}`]);
        }
        else
          return;
      this.titleEditing = !this.titleEditing;
      if(this.titleEditing == true){
        setTimeout(() => {
          var input = document.getElementById(inputName);
          input.focus();
        },0)
      } else {
          var input = <HTMLInputElement>document.getElementById(inputName);
          if(this.validationService.validateName(input.value)){
            this.piece.title=input.value;
            this.updatePiece();
          } else{
            input.value=String(this.piece.title);
            this.validationService.updateName(inputName);
          }
      }
    }

    changePieceNotes(){
      if(!this.isEditor)
        return;
      this.notesEditing = !this.notesEditing;
      if(this.notesEditing == true){
        setTimeout(() => {
          var input = document.getElementById("piece-notes-input");
          input.focus();
        },0)
      } else {
          var input = <HTMLInputElement>document.getElementById("piece-notes-input");
          if(this.validationService.validateDescription(input.value)){
            this.piece.notes=input.value;
            this.updatePiece()
          } else {
            this.alertService.error("Cannot save this piece description. Content invalid!");
            this.notesEditing = !this.notesEditing;
          }
      }
    }

    updatePiece(){
      if(!this.isEditor)
        return;
      this.pieceService.update(this.piece).subscribe();
    }

    blurInput($event){
      ($event.target as HTMLElement).blur();
    }

    @HostListener('window:keydown.space',['$event'])
    onSpace(event: KeyboardEvent){
      var tagName = (event.target as HTMLElement).tagName.toUpperCase();
      if(!tagName || tagName==='INPUT' || tagName==='TEXTAREA')
         return;
      if(this.isPlaying()) this.pause();
      else this.play();
      event.preventDefault()
    }

    @HostListener('unloaded')
    ngOnDestroy() {
      this.audioService.clean();
      for(let track of this.piece.tracks){
        if(track.player != undefined) track.player.unsync();
        if(track.meter != undefined)track.meter.dispose();
        if(track.player != undefined)track.player.dispose();
        if(track.channel != undefined)track.channel.dispose();
      }
    }
    

}
