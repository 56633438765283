import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AlertService, PlaylistService, SharingService, ValidationService } from '@app/_services';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Piece, Playlist } from '@app/_models';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { Title } from '@angular/platform-browser';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html'
})
export class PlaylistComponent implements OnInit {
    id: number;
    linkKey: string | undefined;
    isSubscriptionActive: boolean=false;
    userAccount: Account;
    isEditor: boolean = false;
    selectedPiece: number = 0;
    playlist: Playlist;
    cantLoad: boolean = false;
    titleEditing: boolean = false;
    descriptionEditing: boolean = false;

    constructor(private router: Router,
      private route: ActivatedRoute,
      private playlistService: PlaylistService,
      private accountService: AccountService,
      private alertService: AlertService,
      private titleService: Title,
      private validationService: ValidationService
      ) {
        this.accountService.account.subscribe(x => this.userAccount = x);
      }

    ngOnInit() {
      this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
        this.linkKey = params['key'];
        this.route.queryParams.subscribe(queryParams => {
          this.selectedPiece = +queryParams['piece'];
          if(Number.isNaN(this.selectedPiece)){
            this.selectedPiece=0;
          }
        })

        //load the details
        this.playlistService.getById(this.id,this.linkKey).subscribe(x => { 
          this.playlist = x; 
          this.isEditor= this.userAccount != null && this.userAccount.userId==x.ownerId;
          this.titleService.setTitle(this.playlist.title); 
        },err => {this.cantLoad=true;});
      })
    }

    selectPiece(id: number,title: string){
      var queryParams: Params;
      if(id==this.selectedPiece) id=0;
      if(id != 0){
        queryParams = { piece: id };
        this.selectedPiece=id;
      }
      else{
        queryParams = { piece: 'none'};
        this.selectedPiece=0;
      }

      this.router.navigate([], {relativeTo: this.route, queryParams: queryParams, queryParamsHandling: 'merge', });
    }

    sortedPlaylistPieces(){
      return this.playlist.pieces.sort((a, b) => a.ordinalNumber > b.ordinalNumber ? 1 : a.ordinalNumber === b.ordinalNumber ? 0 : -1);
    }

    openModal(id: string) {
      document.getElementById(id).style.display = 'block';
      //document.body..    overflow: hidden;
    }

    updatePlaylist(){
      if(!this.isEditor)
        return;
      this.playlistService.update(this.playlist).subscribe();
    }

    readableDate(timestamp){
      let date = new Date(timestamp)
      return formatDate(date,'MM/dd/yyyy','en-US');
    }

    changePlaylistTitle(inputName: string){
      if(!this.isEditor)
        return;
      this.titleEditing = !this.titleEditing;
      if(this.titleEditing == true){
        setTimeout(() => {
          var input = document.getElementById(inputName);
          input.focus();
        },0)
      } else {
          var input = <HTMLInputElement>document.getElementById(inputName);
          if(this.validationService.validateName(input.value)){
            this.playlist.title=input.value;
            this.updatePlaylist();
          } else{
            input.value=String(this.playlist.title);
            this.validationService.updateName(inputName);
          }
      }
    }

    changePlaylistDescription(){
      if(!this.isEditor)
        return;
      this.descriptionEditing = !this.descriptionEditing;
      if(this.descriptionEditing == true){
        setTimeout(() => {
          var input = document.getElementById("playlist-description-input");
          input.focus();
        },0)
      } else {
          var input = <HTMLInputElement>document.getElementById("playlist-description-input");
          if(this.validationService.validateDescription(input.value)){
            this.playlist.description=input.value;
            this.updatePlaylist()
          } else {
            this.alertService.error("Nie można zapisać opisu playlisty. Niepoprawny tekst!");
            this.descriptionEditing = !this.descriptionEditing;
          }
      }
    }

    blurInput($event){
      ($event.target as HTMLElement).blur();
    }

    switchPieceOrder(event: CdkDragDrop<Piece[]>){
      if(!this.isEditor)
        return
      this.playlistService.reorderPiece(this.playlist.id,event.item.data.id,event.currentIndex+1).subscribe(()=>{
        if(event.currentIndex < event.previousIndex) {
            this.playlist.pieces.filter((p) => (p.ordinalNumber < (event.previousIndex+1) && p.ordinalNumber >= (event.currentIndex+1))).forEach((p)=> p.ordinalNumber+=1);
        } else {
            this.playlist.pieces.filter((p) => (p.ordinalNumber > (event.previousIndex+1) && p.ordinalNumber <= (event.currentIndex+1))).forEach((p)=> p.ordinalNumber-=1);
        }
        this.playlist.pieces.find((p)=> p.id==event.item.data.id).ordinalNumber=event.currentIndex+1;
    });
    }

}
