import { Component, OnInit } from '@angular/core';
import { AccountService } from '../_services/account.service';
import { Account } from '../_models/account';
import { AuthServerService } from '../_services';
import { ValidationService } from '@app/_services';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-newsletter-form',
  templateUrl: './newsletterform.component.html',
})
export class NewsletterFormComponent implements OnInit {
    userAccount: Account;
    fsm: string; //form,pending-form,success-form,error-form
    constructor(
      private accountService: AccountService,
      private authServerService: AuthServerService,
      public validationService: ValidationService) { 
        this.accountService.account.subscribe(x => this.userAccount = x);
        this.fsm = 'form';
      }

    ngOnInit() {}

    send(){
      let email = (<HTMLInputElement>document.getElementById("email")).value;
      if(this.validationService.validateEmail(email,true))
      {
        this.fsm='pending-form';
        this.authServerService.reqSendNewsletterSubLink(email).subscribe(()=>this.fsm='success-form',()=> this.fsm='error-form');
      }
    }
}

//changepass
//sendpassresetlink
//deleteuser
//logout
//update user
//avatar upload
//avatar delete