<div *ngIf="!userAccount" class="center-container">
    <div class="relative mx-auto w-full md:max-w-[612px] rounded-xl px-[24px] py-[32px] md:px-[106px] md:py-[64px] flex flex-col gap-[96px] items-center overflow-hidden" style="box-shadow: 0px 30px 60px 0px rgba(114, 116, 212, 0.08);">
        <svg class="absolute z-[-5] left-[11px] top-[203px] md:left-[149px] md:top-[247px] w-[530px] h-[420px] md:w-[530px] md:h-[454px]" viewBox="0 0 612 491" fill="none" xmlns="http://www.w3.org/2000/svg">
            <use href="/assets/images/plain_svg/symbol.svg#symbol"/>
        </svg>
        <div *ngIf="registerFsm=='form'" class="flex flex-col gap-[40px] md:gap-[56px] items-center">
            <div class="flex flex-col gap-6 items-center">
                <h1 class="text-primary pb-0 text-center">Załóż nowe&nbsp;konto</h1>
                <div class="text-ch-grey-500">Posiadasz już konto? <a class="font-bold text-primary" href="/login">Zaloguj&nbsp;się</a></div>
            </div>
            <div class="flex flex-col gap-10 items-center w-full">
                <div class="flex flex-col gap-2 w-full">
                    <input type="text" id="register-first-name-input" class="choiry-input w-full" #firstname placeholder="Imię" 
                        (input)="firstNameValidity=validationService.validateFirstName(firstname.value,true)" />
                    <div *ngIf="firstNameValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="firstNameValidity"></p>
                    </div>
                </div>
                <div class="flex flex-col gap-2 w-full">
                    <input type="text" id="register-last-name-input" class="choiry-input w-full" #lastname placeholder="Nazwisko" 
                        (input)="lastNameValidity=validationService.validateLastName(lastname.value,true)" />
                    <div *ngIf="lastNameValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="lastNameValidity"></p>
                    </div>
                </div>
                <div class="flex flex-col gap-2 w-full">
                    <input type="text" id="register-email-input" class="choiry-input w-full" #email required placeholder="E-mail" 
                        (input)="emailValidity=validationService.validateEmail(email.value,true)" />
                    <div *ngIf="emailValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="emailValidity"></p>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-10 items-center w-full">
                <div class="flex flex-col gap-2 w-full">
                    <input type="password" id="register-password-input" class="choiry-input w-full" #password required placeholder="Hasło" 
                        (input)="passwordValidity=validationService.validatePassword(password.value,passwordrepeat.value,true)" />
                    <div *ngIf="passwordValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="passwordValidity"></p>
                    </div>
                </div>
                <input type="password" id="register-password-repeat-input" class="choiry-input w-full" #passwordrepeat required placeholder="Powtórz hasło" 
                    (input)="passwordValidity=validationService.validatePassword(password.value,passwordrepeat.value,true)" />
            </div>

            <div class="flex flex-col gap-4 font-label">
                <label class="flex gap-4">
                    <input type="checkbox" id="register-touppconsent-input" name="TOUPPConsent"/>
                    <span class="text-ch-grey-500">
                        <span class="text-error-content">*</span> 
                        Zgadzam się z postanowieniami 
                        <a class="text-primary font-bold" href="/statement/terms_of_use" target="_blank">regulaminu</a> choiry.io
                    </span>
                </label>
                <label class="flex gap-4 font-label">
                    <input type="checkbox" id="register-newsletterconsent-input" name="NewsletterConsent" value="1"/>
                    <span class="text-ch-grey-500">Chcę być na bieżąco i zapisać się do newslettera choiry.io</span>
                </label>
            </div>
            <div *ngIf="enableRegisterBtn" class="ch-btn ch-btn-filled ch-btn-sec" (click)="register()">Załóż konto</div>
            <div *ngIf="!enableRegisterBtn"><i class="icon icon-48 animate-spin">progress_activity</i></div>
            <!-- <div class="flex flex-col gap-[56px] items-center">
                <div class="flex items-center gap-8">
                    <div class="bg-ch-grey-100 h-[1px] w-[79px] md:w-[157px]"></div>
                    <div class="text-ch-grey-500 flex-none">lub</div>
                    <div class="bg-ch-grey-100 h-[1px] w-[79px] md:w-[157px]"></div>
                </div>
                <div class="ch-btn ch-btn-outline ch-btn-sec">Zaloguj się z Facebook</div>
            </div> -->
        </div>
        <div *ngIf="registerFsm=='success'">
            <h1 class="text-center text-primary">Sprawdź swoją skrzynkę email aby aktywować konto...</h1>
        </div>
    </div>
</div>