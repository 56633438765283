import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Article } from '@app/_models';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BlogService {
    constructor(private http: HttpClient) { }

    getAllArticles() {
        return this.http.get<Article[]>(`${environment.apiUrl}/blog/mod/all`);
    }

    getArticleById(id: number) {
        return this.http.get<Article>(`${environment.apiUrl}/blog/${id}`);
    }

    createArticle(title: string) : Observable<any> {
        return this.http.post(`${environment.apiUrl}/blog/mod/create`, title);
    }

    updateArticle(article: Article) {
        return this.http.post(`${environment.apiUrl}/blog/mod/update`, article);
    }

    deleteArticle(id: number) {
        return this.http.delete(`${environment.apiUrl}/blog/mod/delete/${id}`);
    }
}