import { Component, HostListener, OnInit} from '@angular/core';
import { Account } from '@app/authserver/_models';
import { AccountService, AuthServerService } from '@app/authserver/_services';


@Component({ templateUrl: 'home.component.html',
            styleUrls: ['home.component.css'] })
export class HomeComponent implements OnInit {
    userAccount: Account;
    scrollTop: boolean = true;
    selectedPiece: number = 111;

    constructor(
        private accountService: AccountService,
        ) {
            this.accountService.account.subscribe(x => this.userAccount = x);
    }

    ngOnInit() {
    }

    @HostListener("window:scroll", ["$event"])
    onWindowScroll() {
        this.scrollTop = (window.scrollY < 10);
    }

}