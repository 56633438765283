<div class="center-container">
    <div class="relative mx-auto w-full md:max-w-[612px] rounded-xl px-[24px] py-[32px] md:px-[106px] md:py-[64px] flex flex-col gap-[56px] items-center overflow-hidden" style="box-shadow: 0px 30px 60px 0px rgba(114, 116, 212, 0.08);">
        <svg class="absolute z-[-5] left-[11px] top-[203px] md:left-[149px] md:top-[247px] w-[530px] h-[420px] md:w-[530px] md:h-[454px]" viewBox="0 0 612 491" fill="none" xmlns="http://www.w3.org/2000/svg">
            <use href="/assets/images/plain_svg/symbol.svg#symbol"/>
        </svg>
        
        <div>
            <div class="font-display">Zakup subskrypcji</div>
        </div>
        <!--add a roadmap-->
        <div class="flex flex-col gap-8 items-center w-full">
            <div class="font-title">Wybierz usługę</div>

            <div class="flex flex-col gap-4 w-full">
                <div class="flex w-full font-label text-center">
                    <div class="choiry-v-radio-l flex-1" [ngClass]="selectedPlan==='STANDARD' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectPlan('STANDARD');">STANDARD</div>
                    <div class="choiry-v-radio-r flex-1" [ngClass]="selectedPlan==='PRO' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectPlan('PRO')">PRO</div>
                </div>

                <div class="py-4 flex w-full font-label text-center">
                    <div class="choiry-v-radio-l flex-1" [ngClass]="selectedPeriod==='30' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectPeriod('30');">30 dni</div>
                    <div class="choiry-v-radio-r flex-1" [ngClass]="selectedPeriod==='365' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectPeriod('365');">365 dni</div>
                </div>
                
                <div class="flex items-center bg-ch-grey-50 rounded-[10px] w-full h-[40px] py-3 px-6">
                    <div class="flex-1 font-label">Kwota do zapłaty</div>
                    <div class="font-bold" [innerHTML]="amount"></div>
                </div>
            </div>
        </div>

        <div class="flex flex-col gap-8 items-center w-full">
            <div class="font-title">Uzupełnij dane</div>

            <div class="py-4 w-full flex font-label text-center">
                <div class="choiry-v-radio-l flex-1" [ngClass]="entity==='NATURALPERSON' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectEntity('NATURALPERSON');">Osoba fizyczna</div>
                <div class="choiry-v-radio-r flex-1" [ngClass]="entity==='COMPANY' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="selectEntity('COMPANY');">Firma</div>
            </div>

            <div class="flex flex-col gap-10 items-center w-full">
                <ng-container *ngIf="entity==='NATURALPERSON'">
                    <div class="flex flex-col gap-2 w-full">
                        <input type="text" id="purchase-first-name-input" class="choiry-input" #firstname placeholder="Imię" 
                            (input)="firstNameValidity=validationService.validateFirstName(firstname.value,true)" />
                        <div *ngIf="firstNameValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                            <span class="icon-24 w-6 h-6">warning</span>
                            <p class="font-label" [innerHTML]="firstNameValidity"></p>
                        </div>
                    </div>

                    <div class="flex flex-col gap-2 w-full">
                        <input type="text" id="purchase-last-name-input" class="choiry-input" #lastname placeholder="Nazwisko" 
                            (input)="lastNameValidity=validationService.validateLastName(lastname.value,true)" />
                        <div *ngIf="lastNameValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                            <span class="icon-24 w-6 h-6">warning</span>
                            <p class="font-label" [innerHTML]="lastNameValidity"></p>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="entity==='COMPANY'">

                    <div class="flex flex-col gap-2 w-full">
                        <input type="text" id="purchase-company-name-input" class="choiry-input" #companyname placeholder="Nazwa Firmy" 
                            (input)="companyNameValidity=validationService.validateCompanyName(companyname.value,true)" />
                        <div *ngIf="companyNameValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                            <span class="icon-24 w-6 h-6">warning</span>
                            <p class="font-label" [innerHTML]="companyNameValidity"></p>
                        </div>
                    </div>

                    <div class="flex flex-col gap-2 w-full">
                        <input type="text" id="purchase-taxid-input" class="choiry-input" #taxid placeholder="NIP" 
                            (input)="taxIdValidity=validationService.validateTaxId(taxid.value,true)" />
                        <div *ngIf="taxIdValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                            <span class="icon-24 w-6 h-6">warning</span>
                            <p class="font-label" [innerHTML]="taxIdValidity"></p>
                        </div>
                    </div>
                </ng-container>

                <div class="flex flex-col gap-2 w-full">
                    <input type="text" id="purchase-email-input" class="choiry-input" #email required placeholder="E-mail" 
                        (input)="emailValidity=validationService.validateEmail(email.value,true)" />
                    <div *ngIf="emailValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="emailValidity"></p>
                    </div>
                </div>

                <div class="flex flex-col gap-2 w-full">
                    <input type="text" id="purchase-street1-input" class="choiry-input" #street1 required placeholder="Ulica" 
                        (input)="street1Validity=validationService.validateStreet1(street1.value,true)" />
                    <div *ngIf="street1Validity!=''" class="flex gap-2 h-6 text-error-content items-center">
                        <span class="icon-24 w-6 h-6">warning</span>
                        <p class="font-label" [innerHTML]="street1Validity"></p>
                    </div>
                </div>

                <div class="flex w-full gap-4">
                    <div class="flex flex-col gap-2">
                        <input type="text" id="purchase-street2-input" class="choiry-input w-full" #street2 required placeholder="Numer Budynku" 
                            (input)="street2Validity=validationService.validateStreet2(street2.value,true)" />
                        <div *ngIf="street2Validity!=''" class="flex gap-2 h-6 text-error-content items-center">
                            <span class="icon-24 w-6 h-6">warning</span>
                            <p class="font-label" [innerHTML]="street2Validity"></p>
                        </div>
                    </div>

                    <div class="flex flex-col gap-2">
                        <input type="text" id="purchase-street3-input" class="choiry-input w-full" #street3 required placeholder="Numer Mieszkania" 
                            (input)="street3Validity=validationService.validateStreet3(street3.value,false)" />
                        <div *ngIf="street3Validity!=''" class="flex gap-2 h-6 text-error-content items-center">
                            <span class="icon-24 w-6 h-6">warning</span>
                            <p class="font-label" [innerHTML]="street3Validity"></p>
                        </div>
                    </div>
                </div>

                <div class="flex w-full gap-4">
                    <div class="flex flex-col gap-2 flex-1">
                        <input type="text" id="purchase-postcode-input" class="choiry-input w-full" #postcode required placeholder="Kod pocztowy" 
                            (input)="postCodeValidity=validationService.validatePostCode(postcode.value,true)" />
                        <div *ngIf="postCodeValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                            <span class="icon-24 w-6 h-6">warning</span>
                            <p class="font-label" [innerHTML]="postCodeValidity"></p>
                        </div>
                    </div>

                    <div class="flex flex-col gap-2 flex-1">
                        <input type="text" id="purchase-city-input" class="choiry-input w-full" #city required placeholder="Miasto" 
                            (input)="cityValidity=validationService.validateCity(city.value,true)" />
                        <div *ngIf="cityValidity!=''" class="flex gap-2 h-6 text-error-content items-center">
                            <span class="icon-24 w-6 h-6">warning</span>
                            <p class="font-label" [innerHTML]="cityValidity"></p>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col gap-4 font-label">
                    <label class="flex gap-4">
                        <input type="checkbox" id="purchase-email-consent" name="emailConsent"/>
                        <span class="text-ch-grey-500">
                            * Zgadzam się na przesłanie faktury w formie elektronicznej na wskazany adress email.
                        </span>
                    </label>
                    <label class="flex gap-4">
                        <input type="checkbox" id="purchase-przelewy24-consent" name="purchase-przelewy24Consent"/>
                        <span class="text-ch-grey-500">
                            * Oświadczam, że zapoznałem się z <a href="https://www.przelewy24.pl/regulamin" target="_blank">regulaminem</a> i <a href="https://www.przelewy24.pl/obowiazek-informacyjny-platnik" target="_blank">obowiązkiem informacyjnym</a> serwisu Przelewy24.
                        </span>
                    </label>
                </div>

                <div class="flex flex-col gap-4 items-center" >
                    <div *ngIf="enableSummaryBtn" class="ch-btn ch-btn-filled ch-btn-sec w-full" (click)="createPurchaseOrder()">Przejdź do podsumowania</div>
                    <div class="ch-btn ch-btn-outline ch-btn-sec w-full" [routerLink]="'/subscription'">Powrót</div>
                    <div *ngIf="!enableSummaryBtn"><i class="icon ion-48 animate-spin">progress_activity</i></div>
                </div>
            </div>
        </div>
    </div>
</div>