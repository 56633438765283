<div *ngIf="userAccount" class="flex mb-5">
  <div class="flex-none w-12 h-12 m-2"><avatar type="user-avatar" [avatarUrl]="userAccount.avatarUrl" [userId]="userAccount.userId" [firstName]="userAccount.firstName" [lastName]="userAccount.lastName"></avatar></div>
    <div class="flex-1"><strong>{{userAccount.firstName}} {{userAccount.lastName}}</strong><br>
      <input type="text" #newComment value="" placeholder="Kliknij aby skomentować..." class="choiry-input" (keyup.enter)="createComment(newComment.value); newComment.value=''"/><div class="ch-btn ch-btn-filled ch-btn-sec inline-block mx-4" (click)="createComment(newComment.value); newComment.value=''">Wyślij</div></div>
</div>
<ul class="ml-12">
  <li *ngFor="let comment of comments">
    <div class="flex mt-2">
      <div class="flex-none w-12 h-12 m-2"><avatar type="user-avatar" [avatarUrl]="comment.author.avatarUrl" [userId]="comment.authorId" [firstName]="comment.author.firstName" [lastName]="comment.author.lastName"></avatar></div>
      <div class="flex-1"><strong>{{comment.author.firstName}} {{comment.author.lastName}}</strong><span id="comment-id-{{comment.id}}" class="font-label ml-12" >{{comment.timestr}}</span>
        <p>{{comment.content}}</p></div>
    </div>
  </li>
</ul>