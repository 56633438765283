import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService, AuthServerService } from '@app/authserver/_services';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class AppMenuComponent implements OnInit {
  environment = environment;
  showMenu: boolean = false;

  constructor(
    private accountService: AccountService,
    private router: Router
    ) {}

  ngOnInit() {
  }

  logout(){
    this.accountService.logout();
    this.router.navigate(['/']);
  }
}
