import { Directive, EventEmitter, HostBinding, HostListener, Output } from "@angular/core";

@Directive({
    selector: '[fileDragDrop]'
  })

export class FileDragNDropDirective {
    //@Input() private allowed_extensions : Array<string> = ['png', 'jpg', 'bmp'];
    @Output() private filesChangeEmiter : EventEmitter<File[]> = new EventEmitter();
    //@Output() private filesInvalidEmiter : EventEmitter<File[]> = new EventEmitter();
    @HostBinding('style.background') private background = 'white';
  
    constructor() { }
  
    @HostListener('dragover', ['$event']) public onDragOver(evt){
      evt.preventDefault();
      evt.stopPropagation();
      this.background = '#F1F1FB';
    }
  
    @HostListener('dragleave', ['$event']) public onDragLeave(evt){
      evt.preventDefault();
      evt.stopPropagation();
      this.background = 'white';
    }
  
    @HostListener('drop', ['$event']) public onDrop(evt){
      evt.preventDefault();
      evt.stopPropagation();
      this.background = 'white';
      let files = evt.dataTransfer.files;
      //optional validation
      let valid_files : Array<File> = files;

      this.filesChangeEmiter.emit(evt);
    }
  }