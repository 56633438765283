<div *ngFor="let alert of alerts" class="w-full {{cssClass(alert)}} shadow-lg">
    <div class="mx-auto py-4 lg:px-24 md:px-10 px-4" style="max-width: 1440px;">
        <div class="flex justify-center items-center">
            <div class="md:flex-auto md:w-auto"></div>
            <div *ngIf="alert.type==alertType.Success" class="flex-none w-8 h-8 icon-32">check_circle</div>
            <div *ngIf="alert.type==alertType.Error" class="flex-none w-8 h-8 icon-32">cancel</div>
            <div *ngIf="alert.type==alertType.Warning" class="flex-none w-8 h-8 icon-32">warning</div>
            <div *ngIf="alert.type==alertType.Info" class="flex-none w-8 h-8 icon-32">info</div>
            <div class="ml-4 font-boldbody md:text-center" [innerHTML]="alert.message"></div>
            <div class="w-4 min-w-[16px] flex-auto"></div>
            <div class="flex-none w-8 h-8 icon-32 icon-btn" (click)="removeAlert(alert)">close</div>
        </div>
    </div>
</div>