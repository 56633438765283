<div class="flex gap-6">
  <app-menu *ngIf="userAccount"></app-menu>
  <div class="flex-1 overflow-hidden">
    <div class="bg-white pb-[56px] flex flex-col gap-6 rounded-b-xl">
      <div class="h-16 bg-ch-grey-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
        <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1"><div class="icon icon-24">arrow_left_alt</div></div>
        <div class="font-boldbody">Playlisty</div>
        <div class="w-8 h-8"></div>
      </div>
      <div *ngIf="cantLoad" class="px-12 font-title">Zasób do którego się odwołujesz nie istnieje lub nie masz uprawnień by go załadować.</div>
      <div *ngIf="playlist" class="">
        <div class="flex flex-col gap-4 px-12 min-w-0">
          <!-- HEADER -->
          <div class="flex gap-10 px-6 py-4 align-top">
            <div class="flex flex-col gap-4 flex-1">
              <div class="flex-1 flex items-center gap-4">
                <div class="icon icon-24 text-primary">playlist_play</div>
                <div [hidden]="titleEditing" class="font-title flex-1" [ngClass]="isEditor ? 'cursor-pointer' : ''" (click)="changePlaylistTitle('playlist-title-input')"><h3 class="p-0">{{playlist.title}}</h3></div>
                <div [hidden]="!titleEditing" class="font-title relative">
                  <input class="choiry-input mx-4" type="text" (input)="validationService.updateName('playlist-title-input');" (blur)="changePlaylistTitle('playlist-title-input')" (keyup.enter)="blurInput($event)" id="playlist-title-input" value="{{playlist.title}}"/>
                  <div class="choiry-input-feedback" id="validation-feedback-playlist-title-input"></div>
                </div>
              </div>
              <div class="w-full">
                <div [hidden]="descriptionEditing" class="min-h-[8em] whitespace-pre-wrap" [ngClass]="isEditor ? 'cursor-pointer' : ''" (click)="changePlaylistDescription()">{{playlist.description || 'Brak opisu.'}}</div>
                <div [hidden]="!descriptionEditing"><textarea class="w-full h-64 bg-ch-blue-50 text-ch-grey-900 p-4 rounded-lg" (blur)="changePlaylistDescription()" (keyup.enter)="blurInput($event)" id="playlist-description-input">{{playlist.description || ''}}</textarea></div>
              </div>
            </div>
            <div class="flex items-center gap-4 h-8">
              <avatar type="user-avatar" [size]="'small'"[avatarUrl]="playlist.owner.avatarUrl" [userId]="playlist.ownerId" [firstName]="playlist.owner.firstName" [lastName]="playlist.owner.lastName" [showCreationTooltip]="true" [tooltipDate]="playlist.creationDate"></avatar>
              <div *ngIf="isEditor" (click)="openModal('playlist-sharing');" class="icon icon-btn icon-24 text-primary">share</div>
            </div>
          </div>
          <table class="w-full" cdkDropList 
          (cdkDropListDropped)="switchPieceOrder($event)">
            <tr class="bg-ch-blue-50 h-12 text-ch-grey-900 font-boldbody">
              <td class="pl-6 w-16">#</td>
              <td>Tytuł</td>
              <td>Kompozytor</td>
              <td class="text-center">Data dodania</td>
              <td class="text-center pr-6"><div class="icon icon-24">timer</div></td>
            </tr>
            <!-- FULL LIST -->
            <tr *ngFor="let piece of sortedPlaylistPieces()" 
            class="h-[72px] cursor-pointer" 
            [ngClass]="piece.id==selectedPiece ? 'bg-ch-yellow-50' : 'hover:bg-ch-blue-50'" 
            (click)="selectPiece(piece.id,piece.title)" 
            [cdkDragData]="piece" cdkDrag>
              <td class="pl-6"><div class="px-1 flex items-center gap-2">
                <div class="icon icon-24 text-ch-blue-50" cdkDragHandle>drag_indicator</div>
                <div>{{piece.ordinalNumber}}.</div>
              </div></td>
              <td>
                <div class=" flex items-center gap-3">
                  <div class="icon icon-24 text-primary">library_music</div>
                  <div>{{piece.title}}</div>
                </div>
              </td>
              <td>-</td>
              <td class="text-center">{{readableDate(piece.creationDate)}}</td>
              <td class="pr-6 text-center">{{piece.id}}</td>
              <div *cdkDragPreview class="px-6 py-2 rounded-full bg-blue-50 shadow-sm opacity-70">
                <div class="flex items-center gap-3">
                  <div class="icon icon-24 text-primary">library_music</div>
                  <div>{{piece.title}}</div>
                </div>
              </div>
            </tr>
          </table>
        </div>
        <div *ngFor="let piece of playlist.pieces" class="mt-6 px-12">
          <app-player *ngIf="selectedPiece==piece.id" [paramId]="selectedPiece" [paramLinkKey]="linkKey" [paramEmbedded]="true"></app-player>
        </div>
        <sharing-modal *ngIf="isEditor" id="playlist-sharing" objectId="{{playlist.id}}" type="playlist" title="Udostępnij playlistę" [isPublic]="playlist.sharePublicEnabled" (onPublicShare)="playlist.sharePublicEnabled = $event; updatePlaylist();"></sharing-modal>
      </div>
    </div>
  </div>
</div>