import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({ 
    standalone: true,
    imports: [CommonModule],
    selector: 'confirm-modal', 
    templateUrl: 'confirmmodal.component.html',
    styleUrls: ['confirmmodal.component.css']
})
export class ConfirmModalComponent implements OnInit {
    @Input() confirmType: string = 'undefined';
    @Input() title: string;
    @Output() action = new EventEmitter();

    description :string = "";
    cancel :string = "Anuluj";
    confirm :string = "Tak";

    constructor() { }

    ngOnInit() {
        if(this.confirmType==="playlist-delete"){
            this.description="To działanie jest nieodwracalne. Po usunięciu playlisty nie będzie można jej odzyskać."
            this.cancel="Anuluj"
            this.confirm="Tak, usuń playlistę"
        }
        if(this.confirmType==="piece-delete"){
            this.description="To działanie jest nieodwracalne. Po usunięciu utworu nie będzie można go odzyskać."
            this.cancel="Anuluj"
            this.confirm="Tak, usuń utwór"
        }
        if(this.confirmType==="usergroup-delete"){
            this.description="To działanie jest nieodwracalne. Po usunięciu grupy nie będzie można jej odzyskać."
            this.cancel="Anuluj"
            this.confirm="Tak, usuń grupę"
        }
        if(this.confirmType==="track-delete"){
            this.description="To działanie jest nieodwracalne. Po usunięciu ścieżki nie będzie można jej odzyskać."
            this.cancel="Anuluj"
            this.confirm="Tak, usuń ścieżkę"
        }
    }

    triggerAction(){ this.action.emit('confirm'); }

    close(){ this.action.emit('cancel');}
}