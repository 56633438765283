import { Component,  Input,  OnInit, ViewChild } from '@angular/core';
import { AlertService, CommentService } from '@app/_services';
import { first } from 'rxjs/operators';
import { Comment } from '@app/_models/comment'
import { AccountService } from '@app/authserver/_services';
import { Account } from '@app/authserver/_models';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from '@app/_global';




@Component({
  standalone: true,
  imports: [CommonModule,AvatarComponent],
  selector: 'app-commentblock',
  templateUrl: './commentblock.component.html',
  styleUrls: ['./commentblock.component.css']
})
export class CommentBlockComponent implements OnInit {
    @Input() commentBlockId: number;
    userAccount: Account;
    comments: Comment[];

  constructor(
      private alertService: AlertService,
      private commentService: CommentService,
      private accountService: AccountService
      ) {
        this.accountService.account.subscribe(x => this.userAccount = x);
      }

  ngOnInit() {
    this.commentService.getAllBlockComment(this.commentBlockId).pipe(first()).subscribe(x => { this.comments = x; for(let y of this.comments) this.initCommentTimeString(y); });
  }

  responseToComment(id: number,content: string){
    var comment: Comment = new Comment;
    comment.content = content;
    comment.commentBlockId = this.commentBlockId;
    comment.responseToCommentId = id;
    this.commentService.createComment(comment).subscribe();
  }

  initCommentTimeString(comm: Comment) {
    comm.timestr = this.intervalToStr(comm.creationDate);
  }

  intervalToStr (timestamp) {
    var current_time_seconds = Math.floor(new Date().getTime()/1000);
    var interval = current_time_seconds - Math.floor(timestamp/1000);
    function numberEnding (number) {
        return '';//(number > 1) ? 's' : '';
    }


    var years = Math.floor(interval / 31536000);
    if (years) {
        return years + ' lat' + numberEnding(years);
    }
    //TODO: Months! Maybe weeks? 
    var days = Math.floor((interval %= 31536000) / 86400);
    if (days) {
        return days + ' dni' + numberEnding(days);
    }
    var hours = Math.floor((interval %= 86400) / 3600);
    if (hours) {
        return hours + ' godziny' + numberEnding(hours);
    }
    var minutes = Math.floor((interval %= 3600) / 60);
    if (minutes) {
        return minutes + ' minuty' + numberEnding(minutes);
    }
    var seconds = interval % 60;
    if (seconds) {
        return seconds + ' sekundy' + numberEnding(seconds);
    }
    return 'przed chwilą';
}

  createComment(content: string) {
    var comment: Comment = new Comment;
    comment.content = content;
    comment.commentBlockId = this.commentBlockId;
    this.commentService.createComment(comment).subscribe(comm => { this.initCommentTimeString(comm); this.comments.unshift(comm);});
  }
}
