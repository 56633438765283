<div class="mobilemenu-modal">
    <div class="h-screen overflow-y-scroll bg-white p-10 flex flex-col gap-[72px] justify-start items-center relative z-[1001] overflow-x-hidden">
        <span (click)="close()" class="icon-btn icon-40 top-[20px] right-[20px] absolute">close</span>
        <div class="absolute z-[-1] top-[128px] left-[-163px] w-[700px] h-[554px]"><img class="w-full h-full" src="assets/images/choiry/choiry-symbol.svg" /></div>
        <div class="flex-none icon-choiry-sygnet bg-gray-900 w-[138px] h-[140px]"></div>
        <div class="flex flex-col gap-8">
            <a class="ch-btn ch-btn-outline ch-btn-sec" [routerLink]="['/login']">Zaloguj się</a>
            <a class="ch-btn ch-btn-filled ch-btn-sec" [routerLink]="['/register']">ZAREJESTRUJ SIĘ</a>
        </div>
        <div class="mx-auto w-[67px] h-0 border-[0.5px] border-base-100"></div>
        <div class="flex-none h-40 flex flex-col font-label justify-around text-center">
            <a routerLink="/statement/terms_of_use">Regulamin</a>
            <a routerLink="/statement/privacy_policy">Polityka Prywatności</a>
            <a>Przewodnik po Choiry</a>
        </div>
        <div class="flex-none">
            <div class="flex justify-around gap-8">
              <a class="icon-social-facebook icon-btn bg-primary w-8 h-8 flex-none" href="{{environment.socialFacebook}}" target="_blank"></a>
              <a class="icon-social-x icon-btn bg-primary w-8 h-8 flex-none" href="{{environment.socialX}}" target="_blank"></a>
              <a class="icon-social-instagram icon-btn bg-primary w-8 h-8 flex-none" href="{{environment.socialInstagram}}" target="_blank"></a>
              <a class="icon-social-youtube icon-btn bg-primary w-8 h-8 flex-none" href="{{environment.socialYoutube}}" target="_blank"></a>
            </div>
        </div>
      </div>
</div>