<div class="center-container">
    <div class="help-container mx-auto w-[288px] gap-[40px] md:w-[612px] md:gap-[56px]">
        <h1>Zmiany planu konta</h1>
        <p>Dostępne są trzy plany: FREE, STANDARD i PRO. Plany STANDARD i PRO można aktywować poprzez panel zakupu lub użycie kodu promocyjnego. Funkcje wyższego planu można wykorzystać od razu po zakupie lub wysłaniu kodu.</p>
        <h4>Zmiana planu konta na wyższy</h4>
        <p>W momencie aktywacji planu PRO pozostały niewykorzystany czas z planu STANDARD przenoszony jest do wykorzystania po zakończeniu planu PRO. 
            Przykładowo jeśli plan STANDARD kończy się za 4 dni i w tym momencie aktywowany zostanie plan PRO na okres 30 dni to użytkownik ma do dyspozycji funkcje planu PRO przez kolejne 30 dni i funkcje planu STANDARD przez następujące 4 dni po tych 30 dniach.</p>
        <p>Nie można aktywować pojedynczego planu PRO lub STANDARD na okres dłuższy niż 18 miesięcy.</p>
        <h4>Zmiana planu konta na niższy</h4>
        <p>Wraz ze zmniejszeniem planu zmniejsza się dostępna przestrzeń na nagrania audio i nuty. Jeśli w momencie przejścia na niższy plan użytkownik wykorzystuje więcej przestrzeni niż oferuje niższy plan to następuje procedura blokowania.
            W procedurze blokowania wyłączane są wszystkie udostępnienia utworzone przez użytkownika. Więc inni użytkownicy nie będą mieli możliwości wyświetlenia tych utworów.
            Odblokować udostępnienia utworów można poprzez ponowną aktywację planu wyższego lub ograniczenie wielkości swojej biblioteki do adekwatnej dla nowego planu.
        </p>
        <p>Jeśli przez kolejne 6 miesięcy użytkownik nie odblokuje udostępnień to system trwale usunie utwory, które przekraczają limit dostępnej przestrzeni usuwając w pierwszej kolejności utwory utworzone najdawniej.</p>
    </div>
</div>
    