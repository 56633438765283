<!-- USER AVATAR -->
<ng-container *ngIf="type=='user-avatar'">
<div *ngIf="size=='small'" class="small-avatar">
  <div *ngIf="showPlaceholder" class="small-avatar"><div class="rounded-full small-avatar-font noselect small-avatar" [ngStyle]="{'background-color': background}">{{placeholderLetters}}</div></div>
  <div *ngIf="!showPlaceholder" class="small-avatar"><img class="rounded-full small-avatar" src="{{displayedUrl}}"/></div>
</div>
<div *ngIf="size=='normal'" class="normal-avatar relative group">
    <div *ngIf="showPlaceholder" class="normal-avatar" ><div class="rounded-full normal-avatar-font noselect normal-avatar" [ngStyle]="{'background-color': background}">{{placeholderLetters}}</div>
      <span *ngIf="showCreationTooltip" class="hidden group-hover:block absolute bg-black text-white font-label -left-20 right-0 top-12 -bottom-12 rounded-md z-10 p-2"><small>{{createdByString}}<br>{{creationDateString}}</small></span>
    </div>
    <div *ngIf="!showPlaceholder" class="normal-avatar"><img class="rounded-full normal-avatar" src="{{displayedUrl}}"/>
      <span *ngIf="showCreationTooltip" class="hidden group-hover:block absolute bg-black text-white font-label -left-20 right-0 top-12 -bottom-12 rounded-md z-10 p-2"><small>{{createdByString}}<br>{{creationDateString}}</small></span>
    </div>
</div>
<div *ngIf="size=='full'" class="full-avatar relative group">
  <div *ngIf="showPlaceholder" class="full-avatar"><div class="rounded-full full-avatar-font noselect full-avatar" [ngStyle]="{'background-color': background}">{{placeholderLetters}}</div>
    <span *ngIf="showCreationTooltip" class="hidden group-hover:block absolute -left-28 right-0 top-24 -bottom-16 rounded-md z-10 p-2" ><small>{{createdByString}}<br>{{creationDateString}}</small></span>
  </div>
  <div *ngIf="!showPlaceholder" class="full-avatar"><img class="rounded-full full-avatar" src="{{displayedUrl}}"/>
    <span *ngIf="showCreationTooltip" class="hidden group-hover:block absolute -left-28 right-0 top-24 -bottom-16 rounded-md z-10 p-2"><small>{{createdByString}}<br>{{creationDateString}}</small></span>
  </div>
  <label *ngIf="showEditBadge" class="absolute bottom-[-4px] right-[-10px] w-10 h-10 bg-secondary rounded-full icon-btn p-1" (click)="openModal(type)">
    <span class="icon-20 p-[5px]">border_color</span>
  </label>
</div>
<avatar-modal *ngIf="showEditBadge && userId == userAccount.userId" id="user-avatar" [userAccount]="userAccount" [type]="type" (onUpdate)="updateAvatar($event);"></avatar-modal>
</ng-container>
<!-- USERGROUP AVATAR -->
<ng-container *ngIf="type=='usergroup-avatar'">
<div *ngIf="size=='small'" class="small-avatar">
  <div *ngIf="showPlaceholder" class="small-avatar"><div class="rounded-full small-avatar-font noselect small-avatar bg-primary"><div class="icon icon-16">group</div></div></div>
  <div *ngIf="!showPlaceholder" class="small-avatar"><img class="rounded-full small-avatar" src="{{displayedUrl}}"/></div>
</div>
<div *ngIf="size=='normal'" class="normal-avatar inline-block">
    <div *ngIf="showPlaceholder" class="normal-avatar" ><div class="rounded-full normal-avatar-font noselect normal-avatar bg-primary"><div class="icon icon-32">group</div></div></div>
    <div *ngIf="!showPlaceholder" class="normal-avatar"><img class="rounded-full normal-avatar" src="{{displayedUrl}}"/></div>
</div>
<div *ngIf="size=='full'" class="full-avatar relative ">
  <div *ngIf="showPlaceholder" class="full-avatar"><div class="rounded-full p-6 noselect full-avatar text-white bg-primary"><div class="icon icon-48">group</div></div></div>
  <div *ngIf="!showPlaceholder" class="full-avatar"><img class="rounded-full full-avatar" src="{{displayedUrl}}"/></div>
  <label *ngIf="showEditBadge" class="absolute bottom-[-4px] right-[-10px] w-10 h-10 bg-secondary rounded-full icon-btn p-1" (click)="openModal(type)">
    <span class="icon-20 p-[5px]">border_color</span>
  </label>
</div>
<avatar-modal *ngIf="showEditBadge && userGroup && userGroup.ownerId == userAccount.userId" id="usergroup-avatar" [userGroup]="userGroup" [type]="type" (onUpdate)="updateAvatar($event);"></avatar-modal>
</ng-container>