import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FileUpload, Track } from '@app/_models';
import { TrackService } from '@app/_services';
import { environment } from '@environments/environment';
import { AngularResizeEventModule, ResizedEvent } from 'angular-resize-event-package';
import * as Tone from 'tone';
import { AudioTrackComponent } from '../audiotrack/audiotrack.component';
import { ProgressComponent } from '@app/_global/progress/progress.component';

@Component({
  standalone: true,
  imports: [CommonModule,AngularResizeEventModule,AudioTrackComponent,ProgressComponent],
  selector: 'player-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.less']
})
export class TimelineComponent implements OnChanges, AfterViewInit{
  @Input() tracks:Track[];
  @Input() trackUploads: FileUpload[] = [];
  @Input() isEditor:boolean;
  @Input() isRecording:boolean;
  @Input() metronome:boolean;
  @Input() metronomeBPM:number;
  @Input() timelineLength:number;
  @Output() onSeek = new EventEmitter<number>();
  @Output() updateTransport = new EventEmitter<void>();
  environment = environment;

  infiniteLoop: any;
  timelineZoomLevels: number[] = [5,10,25,50,100];  // pixels per second
  currentZoomLevel: number =3;
  timelineScale: number = 50;

  //TODO REPLACE BY CSS VARIABLES
  colors: any = {"primary": "#7274D4",
      "primary_content": "#FFFFFF",
      "secondary": "#FFC567",
      "secondary_content": "#2A2828",
      "neutral": "#646262",
      "neutral_content": "#FFFFFF",
      "grey_900": "#2A2828",
      "grey_50": "#F0EFEF",
      "grey_100":"#b8b7b7"
      };
  //COPIED FROM TAILWIND FOR USE IN CANVAS

  constructor( private trackService: TrackService ) {}

  ngAfterViewInit() {
    this.updateGrid();
    this.infiniteLoop = window.setInterval(() => this.updatePlayerHead(), 10);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.metronome && !changes.metronome.firstChange)
      this.updateGrid()
    if(this.metronome && changes.metronomeBPM && !changes.metronomeBPM.firstChange)
      this.updateGrid()
  }

  onTrackUpdate() {
    this.updateGrid();
    this.updateTransport.emit()
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    window.clearInterval(this.infiniteLoop);
  }

  updateTimelineScale(){
    this.timelineScale = this.timelineZoomLevels[this.currentZoomLevel];
  }

  updateGrid(){
    //TODO: upgrade
    //timemarkers
    //preset by zoomlevel
    var enable1sTime = false
    var enable5sTime = false;
    var enable15sTime = false;
    var enable30sTime = false;
    var enable1sMarks = false;
    var enable5sMarks = false;
    var enable05sMarks = false;

    switch(this.timelineScale){
      case 5:
        enable1sMarks=true;
        enable5sMarks=true;
        enable30sTime=true;
        break;
      case 10:
        enable1sMarks=true;
        enable5sMarks=true;
        enable15sTime=true;
        break;
      case 25:
        enable1sMarks=true;
        enable5sMarks=true;
        enable5sTime=true;
        break;
      case 50:
        enable1sMarks=true;
        enable5sMarks=true;
        enable5sTime=true;
        break;
      case 100:
        enable05sMarks=true;
        enable1sMarks=true;
        enable5sMarks=true;
        enable1sTime=true;
        break;
    }

    document.getElementById("piece-editor-ruller").innerHTML="";
    document.getElementById("piece-editor-timeline-visible")
    var rullerCanvas = document.createElement('canvas');
    var rullerContext = rullerCanvas.getContext("2d");
    var rullerCanvasHeight = 36;
    var rullerCanvasWidth = document.getElementById("piece-editor-ruller").clientWidth;
    var visibleTimelineWidth = document.getElementById("piece-editor-timeline-visible").clientWidth;
    var endTime = Math.max(visibleTimelineWidth/this.timelineScale,this.timelineLength);
    endTime = Math.ceil(endTime);
    rullerContext.canvas.width = rullerCanvasWidth;
    rullerContext.canvas.height = rullerCanvasHeight;
    rullerContext.strokeStyle = this.colors.grey_50;
    rullerContext.lineWidth = 1;
    rullerContext.clearRect(0, 0, rullerCanvasWidth, rullerCanvasHeight);
    rullerContext.beginPath();
    
    //ruller markers
    //resolution 0.5s
    for (var i = 0; i < endTime*2; i+=1) {
      var x = (i/2)*this.timelineScale;
      if(i % (5*2)==0 && enable5sMarks){
        rullerContext.moveTo(x,0);
        rullerContext.lineTo(x,rullerCanvasHeight/2);  
      } else if(i % (1*2)==0 && enable1sMarks){
        rullerContext.moveTo(x,0);
        rullerContext.lineTo(x,rullerCanvasHeight/3);  
      } else if(enable05sMarks){
        rullerContext.moveTo(x,0);
        rullerContext.lineTo(x,rullerCanvasHeight/8);  
      }

      //timelabels
      if((i % (30*2)==0 && enable30sTime) || (i % (15*2)==0 && enable15sTime) || (i % (5*2)==0 && enable5sTime) || (i % (1*2)==0 && enable1sTime)){
        var m = Math.floor((i/2) / 60);
        var s = (i/2) % 60;
        var timeDiv = document.createElement('div');
        timeDiv.innerHTML=String(m).padStart(2, '0')+":"+String(s).padStart(2, '0');
        timeDiv.setAttribute("style","left:"+x+"px; top: 12px; position: absolute; color: "+this.colors.grey_50+"; width:max-content;font-family: monospace;");
        document.getElementById("piece-editor-ruller").appendChild(timeDiv);
      }
    }
    rullerContext.closePath();
    rullerContext.stroke();
    document.getElementById("piece-editor-ruller").appendChild(rullerCanvas);
    
    //grid
    document.getElementById("piece-editor-timeline-scroll").setAttribute("style","width: "+(endTime+5)*this.timelineScale+"px;");
    var gridCanvas = <HTMLCanvasElement> document.getElementById("piece-editor-timeline-scroll-grid");
    var gridContext = gridCanvas.getContext("2d");
    var gridCanvasHeight = document.getElementById("piece-editor-timeline-scroll").clientHeight;
    var gridCanvasWidth = document.getElementById("piece-editor-timeline-scroll").clientWidth;
    gridContext.canvas.width = gridCanvasWidth;
    gridContext.canvas.height = gridCanvasHeight;
    gridContext.strokeStyle = this.colors.grey_900+"20";
    gridContext.lineWidth = 1;
    gridContext.clearRect(0, 0, gridCanvasWidth, gridCanvasHeight);
    
    var drawGridWidth = Math.max(this.timelineLength,gridCanvasWidth/this.timelineScale)
    for(var time=0 ; time < drawGridWidth ; time += Tone.Time("4n").toSeconds()){
      var x = Math.round(time * (this.timelineScale));
      //line
      gridContext.beginPath();
      gridContext.moveTo(x+0.5,0); //start with 0.5 to make 1px wide line
      gridContext.lineTo(x+0.5,gridCanvasHeight);
      gridContext.closePath();
      gridContext.stroke();
    }

    this.drawPlayerHead();
  }

  drawPlayerHead() {
    //playerhead
    var canvas = <HTMLCanvasElement> document.getElementById("piece-editor-timeline-scroll-head");
    var gridContext = canvas.getContext("2d");
    var canvasHeight = document.getElementById("piece-editor-timeline-scroll").clientHeight;
    var canvasWidth = 20;
    gridContext.canvas.width = canvasWidth;
    gridContext.canvas.height = canvasHeight;
    gridContext.strokeStyle = this.colors.secondary;
    gridContext.lineWidth = 1;
    gridContext.clearRect(0, 0, canvasWidth, canvasHeight);
    //line
    gridContext.beginPath();
    gridContext.moveTo(10,0);
    gridContext.lineTo(10,canvasHeight);
    gridContext.closePath();
    gridContext.stroke();
    //triangle
    gridContext.fillStyle = this.colors.secondary;
    gridContext.beginPath();
    gridContext.moveTo(0,0);
    gridContext.lineTo(10,5);
    gridContext.lineTo(20,0);
    gridContext.closePath();
    gridContext.fill();
  }

  updatePlayerHead(){
    var element = document.getElementById("piece-editor-timeline-scroll-head");
    if(element)
      element.setAttribute("style","left:" + ((Tone.Transport.seconds * this.timelineScale)-10) + "px;");
  }

  seek(event){
    if(event.buttons==1 && event.button==0){
      var time = (event.clientX - document.getElementById("piece-editor-ruller").getBoundingClientRect().left)/this.timelineScale;
      if(time < this.timelineLength){
        this.onSeek.emit(time)
      }
    }
  }

  onScroll(e){
    if(e.ctrlKey){
      var delta = -Math.round(Math.abs(e.deltaY)/e.deltaY)
      this.currentZoomLevel = Math.min(Math.max(this.currentZoomLevel+delta,0),4)
      this.updateTimelineScale();
      this.updateGrid()
      e.preventDefault()
    }
  }

  updateTrack(track: Track){
    if(!this.isEditor)
      return;
    this.trackService.update(track).subscribe(()=>{this.updateTransport.emit();});
  }
  

}
