<div class="flex gap-6">
  <app-menu *ngIf="userAccount"></app-menu>
  <div class="flex-1 overflow-hidden">
    <div class="bg-white pb-[56px] flex flex-col rounded-b-xl">
      <div class="h-16 bg-ch-grey-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
        <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1"><div class="icon icon-24">arrow_left_alt</div></div>
        <div class="font-boldbody">Utwór</div>
        <div class="w-8 h-8"></div>
      </div>
      <div *ngIf="id!=0">
        <app-player [paramId]="id" [paramLinkKey]="linkKey" [paramEmbedded]="false"></app-player>
      </div>
    </div>
  </div>
</div>