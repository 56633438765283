import { Component, OnInit } from '@angular/core';
import { Account } from '@app/authserver/_models';
import { AccountService} from '@app/authserver/_services';
import { environment } from '@environments/environment';
import { InAppNotification } from '@app/_models';
import { InAppNotificationService } from '@app/_services/inappnotification.service';
import { UserGroupService } from '@app/_services';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from '@app/_global';
import { RouterModule } from '@angular/router';
import { ClickOutsideDirective } from '@app/_utility';

@Component({
  standalone: true,
  imports: [CommonModule,AvatarComponent,RouterModule,ClickOutsideDirective],
  selector: 'app-menunotification',
  templateUrl: './menunotification.component.html'
})
export class AppMenuNotificationComponent implements OnInit {
  userAccount: Account;
  environment = environment;
  showMenu: boolean = false;
  notifications: InAppNotification[];
  showCount: number;
  attention: boolean;

  constructor(
      private accountService: AccountService,
      private inAppNotificationService: InAppNotificationService,
      private userGroupService: UserGroupService
      ) {
        // this.notifications = [
        //   {"id": 1, "creationDate": 123, "type": "PLAYLIST_SHARE","userId":"abc",accepted: false, declined:false, viewed:false, generatedByUser:{firstName: "Piotr", lastName: "Terczyński",username: "#1234",id: null,avatarUrl:""}, invitationUserGroupId: 0, invitationUserGroupName: "",joinRequestUserGroupId: 0, joinRequestUserGroupName: "", sharedPieceId: 0, sharedPieceTitle:"",sharedPlaylistId:0,sharedPlaylistTitle:"",daysUntilSubscriptionEnds: 0, "timestr":"59\xa0sec"},
        //   {"id": 2, "creationDate": 123, "type": "USERGROUP_INVITATION","userId":"abc",accepted: false, declined:false, viewed:false, generatedByUser:{firstName: "Mateusz", lastName: "Kowalski",username: "#1234",id: null,avatarUrl:""}, invitationUserGroupId: 0, invitationUserGroupName: "",joinRequestUserGroupId: 0, joinRequestUserGroupName: "", sharedPieceId: 0, sharedPieceTitle:"",sharedPlaylistId:0,sharedPlaylistTitle:"",daysUntilSubscriptionEnds: 0, "timestr":"10\xa0min"},
        //   {"id": 3, "creationDate": 123, "type": "PIECE_SHARE","userId":"abc",accepted: false, declined:false, viewed:true, generatedByUser:{firstName: "Maria", lastName: "Nowak",username: "#1234",id: null,avatarUrl:""}, invitationUserGroupId: 0, invitationUserGroupName: "",joinRequestUserGroupId: 0, joinRequestUserGroupName: "", sharedPieceId: 0, sharedPieceTitle:"",sharedPlaylistId:0,sharedPlaylistTitle:"",daysUntilSubscriptionEnds: 0, "timestr":"2\xa0tyg"},
        //   {"id": 4, "creationDate": 123, "type": "ENDING_SUBSCRIPTION","userId":"abc",accepted: false, declined:false, viewed:true, generatedByUser:{firstName: "Maria", lastName: "Nowak",username: "#1234",id: null,avatarUrl:""}, invitationUserGroupId: 0, invitationUserGroupName: "",joinRequestUserGroupId: 0, joinRequestUserGroupName: "", sharedPieceId: 0, sharedPieceTitle:"",sharedPlaylistId:0,sharedPlaylistTitle:"",daysUntilSubscriptionEnds: 0, "timestr":"1\xa0rok"}
        // ];
        this.accountService.account.subscribe(x => {this.userAccount = x;});
  }

  ngOnInit() {
    this.inAppNotificationService.getAllNotifications().subscribe((n)=> {
      this.notifications=n;
      this.notifications.forEach((n)=>{n.timestr=this.genTimeStr(n.creationDate)});
      this.updateAttention();
    });
  }

  genTimeStr(time: number){
    const currentDate = new Date();
    const currentTime = currentDate.getTime();

    let passedYears = Math.floor((currentTime - time)/(1000*60*60*24*365))
    let passedDays = Math.floor((currentTime - time)/(1000*60*60*24))
    let passedHours = Math.floor((currentTime - time)/(1000*60*60))
    let passedMinutes = Math.floor((currentTime - time)/(1000*60))
    let passedSeconds = Math.floor((currentTime - time)/(1000))

    if(passedYears > 4)
      return passedYears+"\xa0lat"
    if(passedYears > 1)
      return passedYears+"\xa0lata"
    if(passedYears == 1)
      return passedYears+"\xa0rok"
    if(passedDays > 1)
      return passedDays+"\xa0dni"
    if(passedDays == 1)
      return passedDays+"\xa0dzień"
    if(passedHours >= 1)
      return passedHours+"\xa0godz"
    if(passedMinutes >= 1)
      return passedMinutes+"\xa0min"
    if(passedSeconds >= 1)
      return passedSeconds+"\xa0sec"
    return "---";
  }

  markReadAll() {
    this.notifications.filter((n)=> !n.viewed).forEach((n)=>{this.markRead(n)});
  }

  markRead(notification: InAppNotification) {
    if(notification.viewed)
      return;
    this.inAppNotificationService.view(notification).subscribe((x)=>{
      notification.viewed=true; 
      this.updateAttention();
    });
  }

  acceptGroupInvitation(notification: InAppNotification){
    this.userGroupService.acceptInvitation(notification.invitationUserGroupId).subscribe((n)=>{
      notification.accepted=true;
      notification.viewed=true;
    })
  }

  declineGroupInvitation(notification: InAppNotification){
    this.userGroupService.declineInvitation(notification.invitationUserGroupId).subscribe((n)=>{
      notification.declined=true;
      notification.viewed=true;
    })
  }

  updateAttention(){
    if( this.notifications.find((n)=>n.viewed==false)=== undefined )
      this.attention=false;
    else
      this.attention=true;
  }
}
