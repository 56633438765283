<div class="flex gap-6">
    <app-menu *ngIf="userAccount"></app-menu>
      <div class="flex-1 overflow-hidden">
        <div class="bg-white pb-[56px] flex flex-col rounded-b-xl">
          <div class="h-16 bg-ch-yellow-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
            <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1"><div class="icon icon-24">arrow_left_alt</div></div>
            <div class="font-boldbody">Dyktando rytmiczne</div>
            <div class="min-w-8 h-8 flex">
              <div *ngIf="fsm=='ready'" class="text-primary icon-btn icon-32" (click)="start()">play_arrow</div>    
              <div *ngIf="fsm=='answer'" class="text-primary icon-btn icon-32" (click)="playRythm()">repeat</div>    
              <div *ngIf="fsm=='answer'" class="text-primary icon-btn icon-32" (click)="stop()">stop</div>    
            </div>
          </div>
          <div class="text-center font-title md:hidden p-4">Przepraszamy dyktando rytmiczne nie jest jeszcze przystosowane do małych ekranów.</div>
          <div class="hidden md:block">
            <!--HEADER CONTROLLER-->
            <div *ngIf="fsm=='ready'" class="py-4 mx-auto w-64">
              <div class="choiry-v-radio-l" [ngClass]="level==1 ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="setLevel(1);">Poziom 1</div>
              <div [ngClass]="level==2 ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="setLevel(2);">Poziom 2</div>
              <div class="choiry-v-radio-r" [ngClass]="level==3 ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="setLevel(3);">Poziom 3</div>
            </div>
            <div class="font-title w-full text-center my-8">Poprawne odpowiedzi: {{correctAnswers}}/{{rythmsCount}}</div>
            <div class="w-[620px] h-32 my-8 mx-auto"><score-render #score [width]="620" [drawGrid]="false" ></score-render></div>
            <div class="w-full h-16 text-center text-2xl">
              <div *ngIf="fsm=='feedback-right'" class="text-success-content font-bold">Poprawnie</div>
              <div *ngIf="fsm=='feedback-wrong'" class="text-error-content font-bold">Źle</div>
              <div *ngIf="fsm=='ready'" class="w-full">Wybierz poziom i kliknij play</div>
              <div *ngIf="fsm=='answer'" class="w-full">Kliknij na kolejne wartości rytmiczne które usłyszałeś</div>
            </div>
            <div class="w-full h-16 text-center text-2xl">
              <div class="w-full text-4xl"><i *ngIf="guessedRythm.length>0" class="fa fa-remove hover:text-red-700 cursor-pointer" (click)="stepback()"></i>{{guessedRythmText}}</div>
            </div>
            <div class="grid grid-cols-5 justify-items-center w-full my-8">
              <div>
                <div class="interval-btn" id="note-16" (click)="noteClick(16)">{{'&#x1D15D;'}}</div>
              </div>
              <div>
                <div class="interval-btn" id="note-8" (click)="noteClick(8)">{{'&#x1D15E;'}}</div>
              </div>
              <div>
                <div class="interval-btn" id="note-4" (click)="noteClick(4)">{{'&#x1D15F;'}}</div>
              </div>
              <div>
                <div class="interval-btn" id="note-2" (click)="noteClick(2)">{{'&#x1D160;'}}</div>
              </div>
              <div>
            <div class="interval-btn" id="note-1" (click)="noteClick(1)">{{'&#x1D161;'}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>