import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation, ElementRef, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';

@Component({ 
    standalone: true,
    imports: [CommonModule],
    selector: 'score-render', 
    template: `
        <canvas id="canvas"></canvas>
    `, 
    encapsulation: ViewEncapsulation.None
})
export class ScoreComponent implements OnInit, OnDestroy {
    @Input() width: number;
    clef: string = 'G';
    key: number = 0;
    /*
     0 - C/a
     1 - G/e
     2 - D/h
     3 - A/fis
     4 - E/cis
     5 - H/gis
     6 - GesFis/esdis
     7 - Des/b
     8 - As/f
     9 - Es/c
     10 - B/g
     11 - F/d
    */
    @Input() drawGrid: boolean = true;


    private element: any;

    constructor(private el: ElementRef) {this.element = el.nativeElement;}

    canvas: HTMLCanvasElement;
    scoreContext: CanvasRenderingContext2D;

    ngOnInit(): void {
        this.canvas = (<HTMLCanvasElement> document.getElementById("canvas"));
        this.scoreContext = this.canvas.getContext("2d");
        var canvasHeight = 136;
        var canvasWidth = this.width;
        this.scoreContext.canvas.width = canvasWidth;
        this.scoreContext.canvas.height = canvasHeight;
        this.scoreContext.strokeStyle = "black";
        this.reset('G',0);
    }

    public reset(clef,key){
        this.clef=clef;
        this.key=key;
        let keyChromaticsOffset = 0
        this.scoreContext.clearRect(0, 0, this.scoreContext.canvas.width, this.scoreContext.canvas.height);
        if(this.drawGrid){
            this.scoreContext.beginPath();
            for(let i=0; i<5;i++){
                this.scoreContext.moveTo(0,Math.round(i*16+40));
                this.scoreContext.lineTo(this.width,Math.round(i*16+40));
            }
            this.scoreContext.closePath();
            this.scoreContext.stroke();
            if(this.clef=='G'){
                this.scoreContext.font = "96px serif";
                this.scoreContext.fillText("\u{1D11E}", 0, 104);
                keyChromaticsOffset = 8;
            }
            if(this.clef=='F'){
                this.scoreContext.font = "96px serif";
                this.scoreContext.fillText("\u{1D122}", 0, 112);
                keyChromaticsOffset = 24;
            }
        }
        this.scoreContext.font = "48px serif";
        switch(this.key){
            case 11:
                this.scoreContext.fillText("\u{266D}",64,9*8+keyChromaticsOffset);
                break;
            case 10:
                this.scoreContext.fillText("\u{266D}",64,9*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",80,46*8+keyChromaticsOffset);
                break;
            case 9:
                this.scoreContext.fillText("\u{266D}",64,9*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",80,6*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",96,10*8+keyChromaticsOffset);
                break;
            case 8:
                this.scoreContext.fillText("\u{266D}",64,9*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",80,6*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",96,10*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",112,7*8+keyChromaticsOffset);
                break;
            case 7:
                this.scoreContext.fillText("\u{266D}",64,9*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",80,6*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",96,10*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",112,7*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",128,11*8+keyChromaticsOffset);
                break;
            case 6:
                this.scoreContext.fillText("\u{266D}",64,9*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",80,6*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",96,10*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",112,7*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",128,11*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266D}",144,8*8+keyChromaticsOffset);
                //
                // this.scoreContext.fillText("\u{266F}",64,6*8+keyChromaticsOffset);
                // this.scoreContext.fillText("\u{266F}",80,9*8+keyChromaticsOffset);
                // this.scoreContext.fillText("\u{266F}",96,5*8+keyChromaticsOffset);
                // this.scoreContext.fillText("\u{266F}",112,8*8+keyChromaticsOffset);
                // this.scoreContext.fillText("\u{266F}",128,11*8+keyChromaticsOffset);
                // this.scoreContext.fillText("\u{266F}",144,7*8+keyChromaticsOffset);
                break;
            case 5:
                this.scoreContext.fillText("\u{266F}",64,6*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266F}",80,9*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266F}",96,5*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266F}",112,8*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266F}",128,11*8+keyChromaticsOffset);
                break;
            case 4:
                this.scoreContext.fillText("\u{266F}",64,6*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266F}",80,9*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266F}",96,5*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266F}",112,8*8+keyChromaticsOffset);
                break;
            case 3:
                this.scoreContext.fillText("\u{266F}",64,6*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266F}",80,9*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266F}",96,5*8+keyChromaticsOffset);
                break;
            case 2:
                this.scoreContext.fillText("\u{266F}",64,6*8+keyChromaticsOffset);
                this.scoreContext.fillText("\u{266F}",80,9*8+keyChromaticsOffset);
                break;
            case 1:
                this.scoreContext.fillText("\u{266F}",64,6*8+keyChromaticsOffset);
        }
    }


    //http://www.smufl.org/files/smufl-0.4.pdf
    public drawNote(xPos,pitch:number,value){
        //y=72 -> C4 in G key
        //y=72 -> E2 in F key
        let yPos = 128;
        let pos = 0;
        let drawSharp = false;
        let drawBmol = false;
        let drawEraser = false;
        let keySharp = false;
        let keyBmol = false;
        let drawChromaticOffset = 8;
        let pitchNatural = pitch; //which C/a natural note chromatic shift

        const noteC = 0;
        const noteCis = 1; const noteDes = 1;
        const noteD = 2;
        const noteDis = 3; const noteEs = 3;
        const noteE = 4;
        const noteF = 5;
        const noteFis = 6; const noteGes = 6;
        const noteG = 7;
        const noteGis = 8; const noteAs = 8;
        const noteA = 9;
        const noteAis = 10; const noteB = 10;
        const noteH = 11;

        switch(this.key){
            case 0: //C/a
                keySharp = false
                keyBmol = false
                drawSharp = [noteCis,noteDis,noteFis,noteGis,noteAis].includes(pitch%12); 
                drawBmol=false;
                drawEraser=false;
                break;
            case 1: //G/e
                keySharp = [noteFis].includes(pitch%12)
                keyBmol = false
                drawSharp = [noteCis,noteDis,noteGis,noteAis].includes(pitch%12);  
                drawBmol=false;
                drawEraser= [noteF].includes(pitch%12);
                break;
            case 2: //D/h
                keySharp = [noteFis,noteCis].includes(pitch%12)
                keyBmol = false
                drawSharp = [noteDis,noteGis,noteAis].includes(pitch%12); 
                drawBmol=false;
                drawEraser= [noteF,noteC].includes(pitch%12);
                break;
            case 3: //A/fis
                keySharp = [noteFis,noteCis,noteGis].includes(pitch%12)
                keyBmol = false
                drawSharp = [noteAis].includes(pitch%12); 
                drawBmol=false;
                drawEraser= [noteF,noteC,noteG].includes(pitch%12);
                break;
            case 4: //E/cis
                keySharp = [noteFis,noteCis,noteGis,noteDis].includes(pitch%12)
                keyBmol = false
                drawSharp = [noteAis].includes(pitch%12); 
                drawBmol=false;
                drawEraser= [noteF,noteC,noteG,noteD].includes(pitch%12);
                break;
            case 5: //H/gis
                keySharp = [noteFis,noteCis,noteGis,noteDis,noteAis].includes(pitch%12)
                keyBmol = false
                drawSharp = false; 
                drawBmol=false;
                drawEraser= [noteF,noteC,noteG,noteD,noteA].includes(pitch%12);
                break;
            case 6: //GesFis/esdis
                if(pitch%12==noteE) pitchNatural -=1;//problem jak zanotować e->f?
                keySharp = [noteFis,noteCis,noteGis,noteDis,noteAis].includes(pitch%12)
                keyBmol = false
                drawSharp = false; 
                drawBmol=false;
                drawEraser= [noteF,noteC,noteG,noteD,noteA,noteE].includes(pitch%12);
                break;
            case 7: //Des/b
                keySharp = false
                keyBmol = [noteB,noteEs,noteAs,noteDes,noteGes].includes(pitch%12)
                drawSharp = false; 
                drawBmol= false
                drawEraser= [noteH,noteE,noteA,noteD,noteG].includes(pitch%12);
                break;
            case 8: //As/f
                keySharp = false
                keyBmol = [noteB,noteEs,noteAs,noteDes].includes(pitch%12)
                drawSharp = false; 
                drawBmol= [noteGes].includes(pitch%12);
                drawEraser= [noteH,noteE,noteA,noteD].includes(pitch%12);
                break;
            case 9: //Es/c
                keySharp = false
                keyBmol = [noteB,noteEs,noteAs].includes(pitch%12)
                drawSharp = false; 
                drawBmol= [noteDes,noteGes].includes(pitch%12);
                drawEraser= [noteH,noteE,noteA].includes(pitch%12);
                break;
            case 10: //B/g
                keySharp = false
                keyBmol = [noteB,noteEs].includes(pitch%12)
                drawSharp = false; 
                drawBmol= [noteDes,noteGes,noteAs].includes(pitch%12);
                drawEraser= [noteH,noteE].includes(pitch%12);
                break;
            case 11: //F/d
                keySharp = false
                keyBmol = [noteB].includes(pitch%12)
                drawSharp = false; 
                drawBmol= [noteDes,noteEs,noteGes,noteAs].includes(pitch%12);
                drawEraser= [noteH].includes(pitch%12);
                break;
        }
        if(drawSharp || keySharp)pitchNatural=pitch-1;
        if(drawBmol || keyBmol)pitchNatural=pitch+1;

        if(this.clef=='G'){
            if([61,68,73,80].includes(pitchNatural))
                drawChromaticOffset=0;
            if(pitchNatural==60)pos=0; //C   -
            if(pitchNatural==62)pos=1; //D   =
            if(pitchNatural==64)pos=2; //E   -
            if(pitchNatural==65)pos=3; //F   =
            if(pitchNatural==67)pos=4; //G   -
            if(pitchNatural==69)pos=5; //A   =
            if(pitchNatural==71)pos=6; //H   -
            if(pitchNatural==72)pos=7; //C   =
            if(pitchNatural==74)pos=8; //D   -
            if(pitchNatural==76)pos=9; //E   =
            if(pitchNatural==77)pos=10;//F   -
            if(pitchNatural==79)pos=11;//G   =
            if(pitchNatural==81)pos=12;//A   -
            if(pitchNatural==83)pos=13;//H   =
            if(pitchNatural==84)pos=14;//C   -
        }
        if(this.clef=='F'){
            if([44,49,51,54,58].includes(pitch))
                drawChromaticOffset=0;
            if(pitchNatural==40)pos=0; //E   -
            if(pitchNatural==41)pos=1; //F   =
            if(pitchNatural==43)pos=2; //G   -
            if(pitchNatural==45)pos=3; //A   =
            if(pitchNatural==47)pos=4; //H   -
            if(pitchNatural==48)pos=5; //C   =
            if(pitchNatural==50)pos=6; //D   -
            if(pitchNatural==52)pos=7; //E   =
            if(pitchNatural==53)pos=8; //F   -
            if(pitchNatural==55)pos=9; //G   =
            if(pitchNatural==57)pos=10;//A   -
            if(pitchNatural==59)pos=11;//H   =
            if(pitchNatural==60)pos=12;//C   -
        }

        if(pos==0 || pos==12 || pos==14){
            this.scoreContext.beginPath();
            this.scoreContext.moveTo(xPos-3,yPos-8-pos*8);
            this.scoreContext.lineTo(xPos+35,yPos-8-pos*8);
            this.scoreContext.stroke();
        }
        if(pos>12){
            this.scoreContext.beginPath();
            this.scoreContext.moveTo(xPos-3,yPos-8-12*8);
            this.scoreContext.lineTo(xPos+35,yPos-8-12*8);
            this.scoreContext.stroke();
        }

        yPos -= pos*8;

        this.scoreContext.font = "84px serif";
        if(value=="whole")
            this.scoreContext.fillText("\u{1D15D}",xPos,yPos);
        if(value=="half")
            this.scoreContext.fillText("\u{1D15E}",xPos,yPos);
        if(value=="quarter")
            this.scoreContext.fillText("\u{1D15F}",xPos,yPos);
        if(value=="eight")
            this.scoreContext.fillText("\u{1D160}",xPos,yPos);
        if(value=="sixteenth")
            this.scoreContext.fillText("\u{1D161}",xPos,yPos);

        this.scoreContext.font = "48px serif";
        if(drawSharp)this.scoreContext.fillText("\u{266F}",xPos-16,yPos+drawChromaticOffset);
        if(drawBmol)this.scoreContext.fillText("\u{266D}",xPos-16,yPos+drawChromaticOffset);
        if(drawEraser)this.scoreContext.fillText("\u{266E}",xPos-16,yPos+drawChromaticOffset);
    }

    ngOnDestroy(): void {
        this.element.remove();
    }
}