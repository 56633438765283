import { Injectable } from '@angular/core';
import { SubscriptionPurchaseData } from '@app/_models';

@Injectable({ providedIn: 'root' })
export class ValidationService {

    //instead of \p{L}
    private PL = "A-Za-z_\\-ĄĆĘŁŃÓŚŹŻąćęłńóśźż";

    private BPMLenMin=2;        private BPMLenMax=3;            private BPMRegex                :string = "^\\d{2,3}$";
    private nameLenMin=3;       private nameLenMax=30;          private nameRegex               :string = "^[\\w\\ "+this.PL+"0-9!#$%&@\\*\\(\\)=\\+\\[\\]\\'\\\"\\,\\.\\?\\;\\:]{3,30}$";
    private userNameLenMin=2;   private userNameLenMax=15;      private userNameRegex           :string = "^["+this.PL+"0-9]{2,15}$";
    private trackLabelLenMin=1; private trackLabelLenMax=20;    private trackLabelRegex         :string = "^[\\w\\ "+this.PL+"0-9!#]{1,20}$";
    private descriptionLenMin=1;private descriptionLenMax=2000; private descriptionRegex        :string = "^[\\w\\s"+this.PL+"0-9!#$%&@\\*\\(\\)=\\+\\[\\]\\'\\\"\\,\\.\\?\\;\\:]{1,2000}$";
    private commentLenMin=1;    private commentLenMax=500;      private commentRegex            :string = "^[\\w\\ "+this.PL+"0-9!#$%&@\\*\\(\\)=\\+\\[\\]\\'\\\"\\,\\.\\?\\;\\:]{1,500}$";
    private passwordLenMin=8;   private passwordLenMax=32;      private passwordRegex           :string = "^[\\w\\ "+this.PL+"0-9!#$%&@\\*\\(\\)=\\+\\[\\]\\,\\.\\?\\;\\:]{8,32}$"


    private companyNameLenMin=2;private companyNameLenMax=100;  private companyNameRegex        :string = "^["+this.PL+"0-9\\.\\s\\',]{2,100}$";
    private emailLenMin=2;      private emailLenMax=100;        private emailRegex              :string = "^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$";
    private firstNameLenMin=2;  private firstNameLenMax=50;     private firstNameRegex          :string = "^["+this.PL+"\\s]{1,50}$";
    private lastNameLenMin=2;   private lastNameLenMax=50;      private lastNameRegex           :string = "^["+this.PL+"\\s]{1,50}$";
    private taxIDLenMin=10;     private taxIDLenMax=10;         private taxIDRegex              :string = "^\\d{10}$";
    private street1LenMin=2;    private street1LenMax=100;      private street1Regex            :string = "^["+this.PL+"0-9\\.\\s\\',]{2,100}$"; //street name
    private street2LenMin=1;    private street2LenMax=30;       private street2Regex            :string = "^["+this.PL+"0-9\\s\\/]{1,30}$"; //building number
    private street3LenMin=0;    private street3LenMax=30;       private street3Regex            :string = "^["+this.PL+"0-9\\s]{0,30}$"; //flat number
    private cityLenMin=2;       private cityLenMax=50;          private cityRegex               :string = "^["+this.PL+"0-9\\.\\s\\',]{2,50}$";
    private postCodeLenMin=6;   private postCodeLenMax=6;       private postCodeRegex           :string = "^[0-9]{2}\\-[0-9]{3}$";

    constructor() {}

    validateFirstName(firstName: string,required: boolean){
        if(!required && firstName === ''){
            return '';
        }
        if(required && firstName === ''){
            return 'Imie jest wymagane';
        }
        if(firstName.length<this.firstNameLenMin){
            return 'Zbyt krótke Imie';
        }
        if(firstName.length>this.firstNameLenMax){
            return 'Za długie Imie. max: '+this.firstNameLenMax;
        }
        if(firstName.search(this.firstNameRegex)<0){
            return 'Imie zawiera nieprawidłowe znaki';
        }
        return '';
    }

    validateLastName(lastName: string,required: boolean){
        if(!required && lastName === ''){
            return '';
        }
        if(required && lastName === ''){
            return 'Nazwisko jest wymagane';
        }
        if(lastName.length<this.lastNameLenMin){
            return 'Zbyt krótke Nazwisko';
        }
        if(lastName.length>this.lastNameLenMax){
            return 'Za długie Nazwisko. max: '+this.lastNameLenMax;
        }
        if(lastName.search(this.lastNameRegex)<0){
            return 'Nazwisko zawiera nieprawidłowe znaki';
        }
        return '';
    }

    validateEmail(email: string,required: boolean){
        if(!required && email === ''){
            return '';
        }
        if(required && email === ''){
            return 'Pole email jest wymagane';
        }
        if(email.length<this.emailLenMin){
            return 'Zbyt krótki adres email';
        }
        if(email.length>this.emailLenMax){
            return 'Za długi adres email. max: '+this.emailLenMax;
        }
        if(email.search(this.emailRegex)<0){
            return 'Adres nieprawidłowy';
        }
        return '';
    }

    validatePassword(password: string,passwordRepeat: string, required: boolean){
        if(!required && password === ''){
            return '';
        }
        if(required && password === ''){
            return 'Hasło jest wymagane';
        }
        if(password.length<this.passwordLenMin){
            return 'Zbyt krótkie hasło. min: '+this.passwordLenMin;
        }
        if(password.length>this.passwordLenMax){
            return 'Za długie hasło. max: '+this.passwordLenMax;
        }
        if(password.search(this.passwordRegex)<0){
            return 'Hasło zawiera niedozwolone znaki';
        }
        if(!(password === passwordRepeat)){
            return 'Hasło niepoprawnie powtórzone'
        }
        //todo check capital letters and numbers
        return '';
    }

    // validateMrpRegisterForm(form){
    //     return (
    //         this.validateEmail(form.email)
    //         && this.validateFirstName(form.firstName)
    //         && this.validateLastName(form.lastName)
    //         && this.validatePassword(form.passwordId,form.passwordRepeatId)
    //         && this.validatePasswordRepeat(form.passwordId,form.passwordRepeatId)
    //         && form.TOUPPConsent.checked);
    // }

    // validateMrpLoginForm(form){
    //     return (
    //         this.validateEmail(form.email)
    //         && form.password.value.length>this.passwordLenMin);
    // }

    validateBPM(bpm: string){
        return (bpm.search(this.BPMRegex)>=0 && Number.parseInt(bpm)>=30 && Number.parseInt(bpm)<=240);
    }

    validateName(name: string){
        return (name.search(this.nameRegex)>=0);
    }

    validateUserName(username: string){
        return (username.search(this.userNameRegex)>=0);
    }

    validateTrackLabel(trackLabel: string){
        return (trackLabel.search(this.trackLabelRegex)>=0);
    }

    validateDescription(description: string){
        return (description == null || description == "" || description.search(this.descriptionRegex)>=0);
    }

    validateComment(comment: string){
        return (comment.search(this.commentRegex)>=0);
    }

    validateCompanyName(companyName: string,required: boolean){
        if(!required && companyName === ''){
            return '';
        }
        if(required && companyName === ''){
            return 'Nazwa firmy jest wymagana';
        }
        if(companyName.length<this.commentLenMin){
            return 'Zbyt krótka nazwa firmy';
        }
        if(companyName.length>this.companyNameLenMax){
            return 'Za długa nazwa firmy. max: '+this.companyNameLenMax;
        }
        if(companyName.search(this.companyNameRegex)<0){
            return 'Nieprawidłowa nazwa firmy';
        }
        return '';
    }

    validateTaxId(taxId: string,required: boolean){
        if(!required && taxId === ''){
            return '';
        }
        if(required && taxId === ''){
            return 'Numer NIP jest wymagany';
        }
        if(taxId.length<this.taxIDLenMin){
            return 'Zbyt krótki numer NIP';
        }
        if(taxId.length>this.taxIDLenMax){
            return 'Za długi numer NIP.';
        }
        if(taxId.search(this.taxIDRegex)<0){
            return 'Nieprawidłowy numer NIP';
        }
        return '';
    }

    validateStreet1(street1: string,required: boolean){
        if(!required && street1 === ''){
            return '';
        }
        if(required && street1 === ''){
            return 'Nazwa ulicy jest wymagana';
        }
        if(street1.length<this.street1LenMin){
            return 'Zbyt krótka nazwa ulicy';
        }
        if(street1.length>this.street1LenMax){
            return 'Za długa nazwa ulicy. maksymalnie znaków: '+this.street1LenMax;
        }
        if(street1.search(this.street1Regex)<0){
            return 'Nieprawidłowa nazwa ulicy';
        }
        return '';
    }

    validateStreet2(street2: string,required: boolean){
        if(!required && street2 === ''){
            return '';
        }
        if(required && street2 === ''){
            return 'Numer budynku jest wymagany';
        }
        if(street2.length<this.street2LenMin){
            return 'Zbyt krótki numer budynku';
        }
        if(street2.length>this.street2LenMax){
            return 'Za długi numer budynku. maksymalnie znaków: '+this.street1LenMax;
        }
        if(street2.search(this.street2Regex)<0){
            return 'Nieprawidłowy numer budynku';
        }
        return '';
    }

    validateStreet3(street3: string,required: boolean){
        if(!required && street3 === ''){
            return '';
        }
        if(required && street3 === ''){
            return 'Numer mieszkania jest wymagany';
        }
        if(street3.length<this.street3LenMin){
            return 'Zbyt krótki numer mieszkania';
        }
        if(street3.length>this.street3LenMax){
            return 'Za długu numer mieszkania. maksymalnie znaków: '+this.street3LenMax;
        }
        if(street3.search(this.street3Regex)<0){
            return 'Nieprawidłowy numer mieszkania';
        }
        return '';
    }

    validateCity(city: string,required: boolean){
        if(!required && city === ''){
            return '';
        }
        if(required && city === ''){
            return 'Nazwa miasta jest wymagana';
        }
        if(city.length<this.cityLenMin){
            return 'Zbyt krótka nazwa miasta';
        }
        if(city.length>this.cityLenMax){
            return 'Za długa nazwa miasta. maksymalnie znaków: '+this.cityLenMax;
        }
        if(city.search(this.cityRegex)<0){
            return 'Nieprawidłowa nazwa miasta';
        }
        return '';
    }

    validatePostCode(postCode: string,required: boolean){
        if(!required && postCode === ''){
            return '';
        }
        if(required && postCode === ''){
            return 'Kod pocztowy jest wymagany';
        }
        if(postCode.search(this.postCodeRegex)<0){
            return 'Wprowadź kod pocztowy w formacie XX-XXX';
        }
        return '';
    }

    updateBPM(inputId: string){
        var feedbackElement = document.getElementById("validation-feedback-"+inputId);
        var inputElement = <HTMLInputElement>document.getElementById(inputId);
        if(this.validateBPM(inputElement.value)){
            inputElement.classList.remove("ch-input-invalid");
            feedbackElement.style.display="none";   //hide
            return true;
        }
        else{
            inputElement.classList.add("ch-input-invalid");
            if(inputElement.value.length<this.BPMLenMin)
                feedbackElement.innerHTML="Too short text.";
            else if(inputElement.value.length>this.BPMLenMax)
                feedbackElement.innerHTML="Max length is "+this.BPMLenMax;
            else
                feedbackElement.innerHTML="Select tempo in range <30,240> bpm.";
            feedbackElement.style.display="inline-block";   //show
            return false;
        }
    }

    updateName(inputId: string){
        var feedbackElement = document.getElementById("validation-feedback-"+inputId);
        var inputElement = <HTMLInputElement>document.getElementById(inputId);
        if(this.validateName(inputElement.value)){
            inputElement.classList.remove("ch-input-invalid");
            feedbackElement.style.display="none";   //hide
            return true;
        }
        else{
            inputElement.classList.add("ch-input-invalid");
            if(inputElement.value.length<this.nameLenMin)
                feedbackElement.innerHTML="Too short text.";
            else if(inputElement.value.length>this.nameLenMax)
                feedbackElement.innerHTML="Max length is "+this.nameLenMax;
            else
                feedbackElement.innerHTML="Can't contain any special characters.";
            feedbackElement.style.display="inline-block";   //show
            return false;
        }
    }

    updateUserName(inputId: string){
        var feedbackElement = document.getElementById("validation-feedback-"+inputId);
        var inputElement = <HTMLInputElement>document.getElementById(inputId);
        if(this.validateUserName(inputElement.value)){
            inputElement.classList.remove("ch-input-invalid");
            feedbackElement.style.display="none";   //hide
            return true;
        }
        else{
            inputElement.classList.add("ch-input-invalid");
            if(inputElement.value.length<this.userNameLenMin)
                feedbackElement.innerHTML="Too short text.";
            else if(inputElement.value.length>this.userNameLenMax)
                feedbackElement.innerHTML="Max length is "+this.userNameLenMax;
            else
                feedbackElement.innerHTML="Can't contain any special characters.";
            feedbackElement.style.display="inline-block";   //show
            return false;
        }
    }

    updateTrackLabel(inputId: string){
        var feedbackElement = document.getElementById("validation-feedback-"+inputId);
        var inputElement = <HTMLInputElement>document.getElementById(inputId);
        if(this.validateTrackLabel(inputElement.value)){
            inputElement.classList.remove("ch-input-invalid");
            feedbackElement.style.display="none";   //hide
            return true;
        }
        else{
            inputElement.classList.add("ch-input-invalid");
            if(inputElement.value.length<this.trackLabelLenMin)
                feedbackElement.innerHTML="Too short text.";
            else if(inputElement.value.length>this.trackLabelLenMax)
                feedbackElement.innerHTML="Max length is "+this.trackLabelLenMax;
            else
                feedbackElement.innerHTML="Can't contain any special characters.";
            feedbackElement.style.display="inline-block";   //show
            return false;
        }
    }

    updateDescription(inputId: string){
        var feedbackElement = document.getElementById("validation-feedback-"+inputId);
        var inputElement = <HTMLInputElement>document.getElementById(inputId);
        if(this.validateDescription(inputElement.value)){
            inputElement.classList.remove("ch-input-invalid");
            feedbackElement.style.display="none";   //hide
            return true;
        }
        else{
            inputElement.classList.add("ch-input-invalid");
            if(inputElement.value.length<this.descriptionLenMin)
                feedbackElement.innerHTML="Too short text.";
            else if(inputElement.value.length>this.descriptionLenMax)
                feedbackElement.innerHTML="Max length is "+this.descriptionLenMax;
            else
                feedbackElement.innerHTML="Can't contain any special characters.";
            feedbackElement.style.display="inline-block";   //show
            return false;
        }
    }

    updateComment(inputId: string){
        var feedbackElement = document.getElementById("validation-feedback-"+inputId);
        var inputElement = <HTMLInputElement>document.getElementById(inputId);
        if(this.validateComment(inputElement.value)){
            inputElement.classList.remove("ch-input-invalid");
            feedbackElement.style.display="none";   //hide
            return true;
        }
        else{
            inputElement.classList.add("ch-input-invalid");
            if(inputElement.value.length<this.commentLenMin)
                feedbackElement.innerHTML="Too short text.";
            else if(inputElement.value.length>this.commentLenMax)
                feedbackElement.innerHTML="Max length is "+this.commentLenMax;
            else
                feedbackElement.innerHTML="Can't contain any special characters.";
            feedbackElement.style.display="inline-block";   //show
            return false;
        }
    }

    validateSPD(spd: SubscriptionPurchaseData){
        //natural person XOR company
        if( (spd.naturalPerson && spd.company) || (!spd.naturalPerson && !spd.company) )
            return false;
        //natural person specyfic data
        if( spd.naturalPerson && (this.validateFirstName(spd.firstName,true)!='' || this.validateLastName(spd.lastName,true)!='') )
            return false;
        //company specyfic data
        if( spd.company && (this.validateCompanyName(spd.companyName,true)!='' || this.validateTaxId(spd.taxID,true)!='') )
            return false;
        //consents
        if( !spd.emailConsent || !spd.przelewy24Consent )
            return false;
        //productId
        if( spd.productId == null || spd.productId=="" )
            return false;
        //address and contact data
        if( 
            this.validateEmail(spd.contactEmail,true) != '' ||
            this.validateStreet1(spd.street1,true) != '' ||
            this.validateStreet2(spd.street2,true) != '' ||
            this.validateStreet3(spd.street3,false) != '' ||
            this.validateCity(spd.city,true) != '' ||
            this.validatePostCode(spd.postCode,true) != '' )
            return false;
        return true;
    }

}