<div id="piece-editor-timeline-visible" class="absolute top-0 bottom-0 left-0 right-0" (resized)="updateGrid()">
  <div id="piece-editor-timeline-scroll" (wheel)="onScroll($event)" (resized)="updateGrid()">
    <div id="piece-editor-ruller" class="clickable w-full" (mousemove)="seek($event)" (mousedown)="seek($event)"></div>
    <div *ngFor="let track of tracks" class="channel relative">
        <player-audiotrack
        class="w-full absolute"
        [isEditor]="isEditor"
        [isLoading]="track.loadingAudio"
        [track]="track" 
        [timelineScale]="timelineScale"
        [timelineLength]="timelineLength"
        (onTrackLoad)="updateTimelineLength()"
        (onTrackUpdate)="onTrackUpdate()">
        </player-audiotrack>
    </div>
    <ng-container *ngIf="isEditor">
      <div *ngFor="let trackUpload of trackUploads" class="channel">
        <div *ngIf="trackUpload.inProgress" class=" mx-8 w-80 h-full flex flex-col gap-2 items-center justify-center">
          <ch-progress *ngIf="trackUpload.inProgress && trackUpload.progress!=100" class="w-full" 
          [percent]="trackUpload.progress" 
          [description]="'przesyłanie '+trackUpload.file.name+' - '+trackUpload.progress+'%'">
          </ch-progress>
          <ch-progress *ngIf="trackUpload.done || trackUpload.progress==100" class="w-full" 
          [percent]="100" 
          [description]="'przetwarzanie...'">
          </ch-progress>
        </div>
      </div>
    </ng-container>
    <div class="w-full">
      <div class="channel"></div>
    </div>
    <canvas id="piece-editor-timeline-scroll-head"></canvas>
    <canvas [hidden]="!metronome" id="piece-editor-timeline-scroll-grid" class="pointer-events-none"></canvas>
  </div>
</div>
<div class="absolute bottom-0 right-0 h-12 bg-gray-600 bg-opacity-40 rounded-lg p-1 text-white text-sm">Ctrl + scroll aby przybliżyć<br>Shift + scroll aby przewinąć</div>