<div class="hidden w-[294px] rounded-xl lg:flex flex-col gap-8 py-10 bg-white">
    <h4 class="text-primary px-6">Moje konto</h4>
    <div class="flex flex-col gap-8">
        <div class="font-body">
            <a class="p-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/list/playlist" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">playlist_play</div><div routerLinkActive="text-primary">Playlisty</div></a>
            <a class="p-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/list/piece" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">library_music</div><div routerLinkActive="text-primary">Utwory</div></a>
            <a class="p-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/list/usergroup" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">group</div><div routerLinkActive="text-primary">Grupy</div></a>
            <a class="p-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/list/sharing" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">share</div><div routerLinkActive="text-primary">Udostępnienia</div></a>
        </div>
        <div class="font-body">
            <a class="p-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/excercises/intervalrecognition" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">headphones</div><div routerLinkActive="text-primary">Rozpoznawanie interwałów</div></a>
            <a class="p-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/excercises/rythmicdictation" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">music_note</div><div routerLinkActive="text-primary">Dyktando rytmiczne</div></a>
            <a class="p-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/excercises/scorereading" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">visibility</div><div routerLinkActive="text-primary">Czytanie nut</div></a>
        </div>
        <div class="font-body">
            <a class="p-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/subscription" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="text-primary">payments</div><div routerLinkActive="!text-primary">Płatności</div></a>
            <a class="p-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/settings" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="text-primary">settings</div><div routerLinkActive="!text-primary">Ustawienia</div></a>
        </div>

    </div>
    <div class="px-6"><div class="ch-btn ch-btn-outline ch-btn-pri !w-full" (click)="logout()">Wyloguj się</div></div>
</div>