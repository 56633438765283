import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { User, FileUpload, SubscriptionCode } from '@app/_models';
import { AlertService, FileUploadService } from '@app/_services';
import { SubscriptionCodeService } from '@app/_services/subscriptioncode.service';
import { ValidationService } from '@app/_services/validation.service';
import { ChangePasswordForm, Account } from '@app/authserver/_models';
import { AccountService, AuthServerService } from '@app/authserver/_services';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
})
export class SettingsComponent implements OnInit {
    userAccount: Account;
    isValidFirstName: boolean = true;
    isValidLastName: boolean = true;
    avatarUpload: FileUpload;
    showAvatar: boolean=false;

    constructor(private alertService: AlertService,
      private accountService: AccountService,
      private authServerService: AuthServerService,
      public validationService: ValidationService) { 
        this.accountService.account.subscribe(x => {this.userAccount = x; this.showAvatar=true;});
      }

    ngOnInit() {
      this.avatarUpload = new FileUpload;
    }

    updateFullName(){
      var fn = (<HTMLInputElement>document.getElementById("first-name")).value;
      var ln = (<HTMLInputElement>document.getElementById("last-name")).value;
      if(this.validationService.validateFirstName(fn,true)!='' || this.validationService.validateLastName(ln,true)!=''){
        this.alertService.error("Podano niepoprawne imie lub nazwisko");
        return;
      }
      this.authServerService.reqUpdateUser(fn,ln,this.userAccount.avatarUrl).subscribe(()=>{this.alertService.success("Zaktualizowano Imie i Nazwisko.")},err => {this.alertService.error("Nie udało się zaktualizować Imienia i Nazwiska")});
    }

    changePassword(){
      var req: ChangePasswordForm = new ChangePasswordForm();
      req.currentPass = (<HTMLInputElement>document.getElementById("current-password")).value;
      req.newPass = (<HTMLInputElement>document.getElementById("new-password")).value;

      if(this.validationService.validatePassword(req.newPass,(<HTMLInputElement>document.getElementById("new-password-repeat")).value,true) != ''){
        this.alertService.error("Nowe hasło jest niepoprawne!");
        return;
      }
      this.authServerService.reqChangePassword(req).subscribe(res => {this.alertService.success("Hasło zostało zmienione")},err => {
        if(err.code == 400)
          this.alertService.error(err.errorMessage);
        else
          this.alertService.error("Zmiana hasła nie powiodła się!");
      });
    }
}
