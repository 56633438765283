import { CommonModule } from '@angular/common';
import { Component, ViewEncapsulation} from '@angular/core';

@Component({ 
  standalone: true,
  imports: [CommonModule],
  selector: 'help-subscription-end',
  templateUrl: './subscriptionend.component.html',
  styleUrls: ['./help.less'],
  encapsulation: ViewEncapsulation.None })
export class SubscriptionEndComponent {
    constructor() { }
}