<div class="confirm-modal flex flex-col gap-9 items-center rounded-[20px]">
    <div class="flex flex-col gap-4 items-center">
        <div class="icon icon-56 text-primary">delete</div>
        <div class="font-title text-center">{{title}}</div>
        <div class="font-body text-center">{{description}}</div>
    </div>
    <div class="flex gap-6">
        <div class="ch-btn ch-btn-outline ch-btn-sec" (click)="close()">{{cancel}}</div>
        <div class="ch-btn ch-btn-filled ch-btn-sec" (click)="triggerAction()">{{confirm}}</div>
    </div>

</div>
<div class="confirm-modal-background"></div>