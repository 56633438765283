<div class="relative">
  <div *ngIf="haveScore && urlScore && !hideScore" class="w-full mb-24">
    <div *ngIf="currentScorePage>1" id="prev-score-page-btn" (click)="currentScorePage = currentScorePage - 1">
      <span class="icon-btn icon-48 valign">navigate_before</span>
    </div>
    <div *ngIf="currentScorePage<scorePages" id="next-score-page-btn" (click)="currentScorePage = currentScorePage + 1">
      <span class="icon-btn icon-48 valign">navigate_next</span>
    </div>
    <div class="relative w-full max-w-5xl mx-auto my-4 p-0">
        <pdf-viewer
        [ngStyle]="{ height: pdfViewerHeight + 10 + 'px' }"
        [src]="urlScore" 
        [page]="currentScorePage" 
        [render-text]='false' 
        [show-all]="false" 
        [external-link-target]="'none'" 
        (after-load-complete)="setScorePageNumber($event);"
        [original-size]="false"
        [zoom-scale]="'page-width'"
        [autoresize]="true"
        (page-rendered)="pageRendered($event)">
      </pdf-viewer>
    </div>
    <div *ngIf="isEditor" class="w-full flex justify-center">
      <div class="ch-btn ch-btn-outline ch-btn-sec" (click)="confirmDeleteScore();">
        <div class="flex justify-center items-center gap-2">
          <div class="font-boldbody">Usuń nuty</div>
          <div class="icon-24">delete</div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="scoreUpload.inProgress" class="flex w-full justify-center items-center" >
      <ch-progress class="w-full mx-16" [percent]="scoreUpload.progress"></ch-progress>
  </div>
  <div *ngIf="isEditor && !haveScore && !scoreUpload.inProgress" class="w-full flex justify-center">
    <label for="scoreUpload" class="ch-btn ch-btn-outline ch-btn-sec">
      <div class="flex justify-center items-center gap-2">
        <div>Prześlij nuty</div>
        <div class="icon-24" >cloud_upload</div>
      </div>
    </label>
    <input class="hidden" id="scoreUpload" type="file" (change)="uploadScoreFile($event)">
  </div>
  <ng-container #confirmationModalContainer></ng-container>
</div>
