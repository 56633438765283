export class Track {
    id: number;
    ownerId: string;
    pieceId: number;
    name: string;
    offset: number;
    trimLeft: number;
    trimRight: number;
    volumeDb: number;
    solo: boolean;
    mute: boolean;
    meterValue: number;
    ordinalNumber: number;
    fileSize: number;
    //PLAYER PART
    url: any;           //for player only
    channel: any;       //for player only
    meter: any;         //for player only
    limiter: any;       //for player only
    player: any;        //for player only
    loadingAudio: boolean;          //for player only
    titleEditing: boolean = false;  //for player only
}