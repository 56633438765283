export const environment = {
  production: false,
  apiUrl: 'https://test.choiry.io/api',
  authUrl: 'https://auth.test.choiry.io/api/choiry',
  loginUrl: 'https://test.choiry.io/login',
  domain: 'https://test.choiry.io/',
  socialFacebook: 'https://www.facebook.com/people/Choiryio/100095156522946/',
  socialInstagram: 'https://www.instagram.com/choiry.io/',
  socialYoutube: 'https://www.youtube.com/@Choiry-to6yg',
  socialX: 'https://twitter.com/Choiry_io',
};
