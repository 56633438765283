import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';


@Component({
  selector: 'app-piece',
  templateUrl: './piece.component.html'
})
export class PieceComponent implements OnInit {
  userAccount: Account;
  id: number;
  linkKey: string | undefined;

    constructor(private route: ActivatedRoute,
                private accountService: AccountService) {
                  this.accountService.account.subscribe(x => this.userAccount = x);
                 }

    ngOnInit() {
      this.route.params.subscribe(params => {
        this.id = +params['id']; // (+) converts string 'id' to a number
        this.linkKey = params['key'];
      });
    }
}