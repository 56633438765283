<div>
  <app-navbar></app-navbar>
  <!-- ALERT --><div id="main-alert" class="fixed w-full top-[80px] z-[70]"><alert></alert></div>
  <div id="main-content" [ngClass]="toggleFullScreen?'':'main-content'" class="w-full mt-[80px] relative overflow-x-hidden">
    <div class="absolute z-[-5] overflow-hidden top-0 bottom-0 left-0 right-0">
      <div class="absolute ch-bg-blur w-[700px] h-[700px] left-[45%] top-[80px]"></div>
      <div class="absolute ch-bg-blur w-[990px] h-[990px] right-[45%] top-[500px]"></div>
      <div class="absolute ch-bg-blur w-[1100px] h-[1100px] left-[45%] top-[1200px]"></div>
      <div class="absolute ch-bg-blur w-[990px] h-[990px] right-[60%] top-[2000px]"></div>
      <div class="absolute ch-bg-blur w-[1500px] h-[1500px] left-[40%] top-[2700px]"></div>
      <div class="absolute ch-bg-blur w-[990px] h-[990px] right-[50%] top-[4200px]"></div>
      <div class="absolute ch-bg-blur w-[700px] h-[700px] left-[70%] top-[5000px]"></div>
    </div>
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
  <app-cookies-warn></app-cookies-warn>
  <app-piano></app-piano>
  <app-metronome></app-metronome>
</div>