import { User } from '.';

export class Article{
    id: number;
    commentBlockId: number;
    authorId: string;
    creationDate: number;
    title: string;
    tags: string;
    body: string;
    description: string;
    categoryId: string;
    published: boolean;
    author: User;
}