import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@environments/environment';
import { Piece } from '@app/_models';
import { map, Observable } from 'rxjs';
import { AccountService } from '@app/authserver/_services';

@Injectable({ providedIn: 'root' })
export class PieceService {
    constructor(private http: HttpClient, private accountService: AccountService) { }

    getUserOwnedAll() {
        return this.http.get<Piece[]>(`${environment.apiUrl}/piece/owned/all`);
    }

    getUserSharedAll() {
        return this.http.get<Piece[]>(`${environment.apiUrl}/piece/shared/all`);
    }

    getUserGroupSharedAll(id: number) {
        return this.http.get<Piece[]>(`${environment.apiUrl}/piece/shared/group/${id}`);
    }

    getById(id: number,linkKey: string) {
        if(linkKey===undefined)
            return this.http.get<Piece>(`${environment.apiUrl}/piece/${id}`);
        else
            return this.http.get<Piece>(`${environment.apiUrl}/piece/link/${id}/${linkKey}`);
    }

    create(title: String): Observable<any> {
        return this.http.post(`${environment.apiUrl}/piece/create`, title);
    }

    update(piece: Piece) {
        var update = new Piece();
        update.id = piece.id;
        update.notes = piece.notes;
        update.haveScore = piece.haveScore;
        update.sharePublicEnabled = piece.sharePublicEnabled;
        update.tempo = piece.tempo;
        update.title = piece.title;
        update.metronome = !piece.metronomeChannel.mute;
        return this.http.post(`${environment.apiUrl}/piece/update`, update).pipe(map(() => {this.accountService.getSelfAccountFromApi().subscribe()}));
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/piece/delete/${id}`).pipe(map(() => {this.accountService.getSelfAccountFromApi().subscribe()}));
    }

    downloadScore(id: number,linkKey: string): Observable<Blob> {
        if(linkKey===undefined)
            return this.http.get(`${environment.apiUrl}/piece/score/download/${id}`, { responseType: 'blob' });
        else
            return this.http.get(`${environment.apiUrl}/piece/score/download/link/${id}/${linkKey}`, { responseType: 'blob' });
    }

    deleteScore(id: number) {
        return this.http.delete(`${environment.apiUrl}/piece/score/delete/${id}`).pipe(map(() => {this.accountService.getSelfAccountFromApi().subscribe()}));
    }
}