import { Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthServerService } from '../_services';

@Component({ 
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.less'],
  encapsulation: ViewEncapsulation.None })
export class StatementComponent implements OnInit, OnDestroy {
    sub: any;
    body: string;
    constructor(private route: ActivatedRoute,
                private authServerService: AuthServerService) { }

    ngOnInit() {
      this.sub=this.route.params.subscribe(params => {
          this.authServerService.reqGetStatement(params['name']).subscribe(x => this.body = x.string);
        })
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
      }
}