import { Component, ElementRef, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { PlaylistService, AlertService, ValidationService } from '@app/_services';
import { Playlist } from '@app/_models';
import { first } from 'rxjs/operators';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Account } from '@app/authserver/_models';
import { AccountService } from '@app/authserver/_services';
import { ConfirmModalComponent } from '@app/modals/confirmmodal/confirmmodal.component';


@Component({
  selector: 'app-listplaylist',
  templateUrl: './listplaylist.component.html',
})
export class ListPlaylistComponent implements OnInit {
  @ViewChild('confirmationModalContainer', {read: ViewContainerRef}) confirmationModalContainer : ViewContainerRef;
    userAccount: Account;
    filter: string;
    playlists: Playlist[];

    playlistCreation = false;


  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private alertService: AlertService,
      private playlistService: PlaylistService,
      private accountService: AccountService,
      public validationService: ValidationService
      ) {
        this.accountService.account.subscribe(x => this.userAccount = x);
      }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.filter = params['filter'] || 'owned';
      
      if(this.filter === 'owned'){
        this.playlistService.getUserOwnedAll().pipe(first()).subscribe(x => { this.playlists = x; });
      } else if(this.filter === 'shared'){
        this.playlistService.getUserSharedAll().pipe(first()).subscribe(x => { this.playlists = x; });
      }
    });
  }

  createPlaylist(){  
    this.playlistCreation=false;
    var title: string = (<HTMLInputElement>document.getElementById("new-playlist-title")).value;
    if(this.validationService.validateName(title)){
      this.playlistService.create(title).subscribe(x => {
        this.accountService.getSelfAccountFromApi().subscribe(() =>{
          this.playlistService.getById(x.id,undefined).subscribe(y => {
            this.playlists.push(y);
          });
        });
      });
    }
  }

  confirmDeletePlaylist(playlist: Playlist){
    this.confirmationModalContainer.clear();
    const componentRef = this.confirmationModalContainer.createComponent(ConfirmModalComponent);
    componentRef.instance.confirmType = 'playlist-delete';
    componentRef.instance.title = 'Czy na pewno chcesz usunąć playlistę '+playlist.title+'?';
    componentRef.instance.action.subscribe(data => {
      if(data==='confirm'){
        this.playlistService.delete(playlist.id)
         .pipe(first())
         .subscribe(
             data => {
                this.playlists.forEach((p,index)=>{
                if(p.id==playlist.id) {
                  this.playlists.splice(index,1);
                  this.accountService.getSelfAccountFromApi();
                  this.alertService.success("Usunięto playlistę "+playlist.title)
                }
              });
             },
             error => {
                 this.alertService.error(error);
             });
      } else if (data ==='cancel'){
        //do nothing
      }
      componentRef.destroy();
    });
  }

  setFilter(filter: string){
    var queryParams: Params = { filter: filter };
    this.router.navigate([], {relativeTo: this.route, queryParams: queryParams, queryParamsHandling: 'merge', });
  }

}
