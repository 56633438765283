<div class="center-container">
    <div class="relative mx-auto w-full md:max-w-[612px] rounded-xl px-[24px] py-[32px] md:px-[106px] md:py-[64px] flex flex-col gap-[96px] items-center overflow-hidden" style="box-shadow: 0px 30px 60px 0px rgba(114, 116, 212, 0.08);">
        <svg class="absolute z-[-5] left-[11px] top-[203px] md:left-[149px] md:top-[247px] w-[530px] h-[420px] md:w-[530px] md:h-[454px]" viewBox="0 0 612 491" fill="none" xmlns="http://www.w3.org/2000/svg">
            <use href="/assets/images/plain_svg/symbol.svg#symbol"/>
        </svg>
        
        <h1 *ngIf="!spd">Ładowanie</h1>
        <div *ngIf="spd" class="flex flex-col gap-[40px] md:gap-[56px] items-center text-center">
            <h4 *ngIf="spd.purchaseStatus=='CANCELED_NEW' || spd.purchaseStatus=='CANCELED_TIMEOUT' || spd.purchaseStatus=='CANCELED_ERROR'">Zamówienie zostało anulowane.</h4>
            <h4 *ngIf="spd.purchaseStatus=='REALIZATION'">Trwa realizacja zamówienia. Proszę czekać</h4>
            <h4 *ngIf="spd.purchaseStatus=='SUCCESS'">Zamówienie zostało poprawnie zrealizowane</h4>
            <h4 *ngIf="spd.purchaseStatus=='NEW'">Podsumowanie zamówienia</h4>
            <h4 *ngIf="spd.purchaseStatus=='PAYMENT'">Pozostań na tej stronie! Oczekujemy na potwierdzenie płatności...</h4>
            <div *ngIf="spd.purchaseStatus=='NEW' || spd.purchaseStatus=='PAYMENT'">
                <div class="flex flex-col w-full gap-2 text-left">
                    <p class="font-label text-ch-grey-500">Realizując to zamówienie przedłużysz subskrypcję konta użytkownika obecnie zalogowanego.</p>
                    <div class="flex gap-8"><div class="flex-1">Numer zamówienia</div><div class="flex-1">{{spd.orderId}}</div></div>
                    <div class="flex gap-8"><div class="flex-1">Identyfikator konta</div><div class="flex-1">{{spd.userId}}</div></div>
                    <div class="flex gap-8"><div class="flex-1">Nazwa produktu</div><div class="flex-1">{{spd.productName}}</div></div>
                    <div class="flex gap-8"><div class="flex-1">Identyfikator produktu</div><div class="flex-1">{{spd.productId}}</div></div>
                    <div class="flex gap-8"><div class="flex-1">Podatek VAT</div><div class="flex-1">{{spd.productTax}}</div></div>
                    <div class="flex gap-8"><div class="flex-1">Cena netto</div><div class="flex-1">{{spd.productPriceNetto/100}}</div></div>
                    <div class="flex gap-8"><div class="flex-1">Cena brutto</div><div class="flex-1">{{spd.productPriceBrutto/100}}</div></div>
                    <div class="flex gap-8"><div class="flex-1">Waluta</div><div class="flex-1">{{spd.currency}}</div></div>
                    <div class="flex gap-8"><div class="flex-1">Email kontaktowy</div><div class="flex-1">{{spd.contactEmail}}</div></div>
                    <div class="py-2 font-title">Dane do faktury</div>
                    <div *ngIf="spd.naturalPerson" class="flex gap-8"><div class="flex-1">Imie</div><div class="flex-1">{{spd.firstName}}</div></div>
                    <div *ngIf="spd.naturalPerson" class="flex gap-8"><div class="flex-1">Nazwisko</div><div class="flex-1">{{spd.lastName}}</div></div>
                    <div *ngIf="spd.company" class="flex gap-8"><div class="flex-1">Nazwa firmy</div><div class="flex-1">{{spd.companyName}}</div></div>
                    <div *ngIf="spd.company" class="flex gap-8"><div class="flex-1">NIP</div><div class="flex-1">{{spd.taxID}}</div></div>
                    <div class="flex gap-8"><div class="flex-1">Adres</div><div class="flex-1">{{spd.street1}} {{spd.street2}}/{{spd.street3}}</div></div>
                    <div class="flex gap-8"><div class="flex-1">Miasto</div><div class="flex-1">{{spd.city}}</div></div>
                    <div class="flex gap-8"><div class="flex-1">Kod pocztowy</div><div class="flex-1">{{spd.postCode}}</div></div>
                </div>
            </div>
            <div *ngIf="spd.purchaseStatus=='NEW'" class="flex flex-col gap-4">
                <div class="flex gap-4">
                    <div class="ch-btn ch-btn-outline ch-btn-sec" (click)="cancel()">Anuluj Zamówienie</div>
                    <div class="ch-btn ch-btn-filled ch-btn-sec" (click)="beginPayment()">Zapłać <i class="fa fa-money" style="margin: 9px;"></i></div>
                </div>
                <div id="payment-methods"><img src="/assets/images/popular_payment_methods.png" width="500px"/></div>
            </div>
            <div *ngIf="spd.purchaseStatus=='PAYMENT'" class="flex flex-col gap-4">
                <span class="icon icon-48 animate-spin">progress_activity</span>
                <div id="payment-methods"><img src="/assets/images/popular_payment_methods.png" width="500px"/></div>
            </div>
        </div>
    </div>
</div>