<div *ngIf="!userAccount" class="text-center center-container pt-6">
    <div *ngIf="fsm==='form' || fsm==='pending'">
        <h4>Wyślij link do resetu hasła</h4>
        <div class="w-40 inline-block"><label for="email">Adres Email</label></div><div class="inline-block"><input type="text" class="choiry-input w-60" id="email"/></div><br>
        <div *ngIf="fsm==='form'" class="ch-btn ch-btn-filled ch-btn-sec my-4 mx-auto" (click)="sendPasswordResetLink()">Wyślij link</div>
        <div *ngIf="fsm==='pending'" class="ch-btn ch-btn-filled ch-btn-sec mx-auto my-4 cursor-disable" disabled>Wyślij link <span class="icon animate-spin">progress_activity</span></div>
        <div *ngIf="showInvalidEmail" class="text-error font-bodybold m-2">Podaj poprawny adres email!</div>
    </div>
    <div *ngIf="fsm==='success'" >
        <div class="font-title m-2">Link do resetu hasła został wysłany na adres email!</div>
    </div>
    <div *ngIf="fsm==='error'">
        <div class="font-title m-2">Wystąpił problem z naszymi serwerami. Proszę spróbować później.</div>
    </div>
</div>