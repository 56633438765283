<div (click)="showOptions=true" (clickOutside)="showOptions=false" class="relative cursor-pointer w-[237px] h-12 px-4 rounded-[5px] border border-ch-blue-100 flex justify-between items-center bg-white">
    <div *ngIf="showOptions" class="absolute bg-white rounded-b-[5px] top-12 left-0 right-0">
        <div *ngFor="let option of options; last as isLast" [ngClass]="isLast ? 'rounded-b-[5px]' : 'border-b-[1px]'" class="flex items-center px-4 h-12 border-ch-grey-100 text-ch-grey-600 hover:bg-ch-blue-50" (click)="select(option)">
            {{option.name}}
        </div>
    </div>
    <div class="flex gap-2 items-center text-ch-blue-500">
        <div class="icon icon-24">filter_list</div>
        <div class="font-boldbody">{{ selected?selected.name:'Filtruj'}}</div>
    </div>
    <div class="icon icon-24 text-ch-blue-500">expand_more</div>
</div>