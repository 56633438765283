<div class="relative w-10 h-10" (click)="showMenu=true" (clickOutside)="showMenu=false">
    <div class="border-primary rounded-full border-2 p-[2px] cursor-pointer">
        <avatar type="user-avatar" [size]="'small'" [avatarUrl]="userAccount.avatarUrl" [userId]="userAccount.userId" [firstName]="userAccount.firstName" [lastName]="userAccount.lastName"></avatar>
    </div>
    <div *ngIf="showMenu" class="w-[332px] rounded-xl flex flex-col gap-6 py-6 bg-white shadow-sm absolute top-10 right-0">
        <div class="px-8 flex flex-col gap-[10px]">
            <div class="font-title">{{userAccount.firstName}} {{userAccount.lastName}}</div>
            <div class="font-body text-ch-grey-400">{{userAccount.email}}</div>
        </div>
        <div class="flex flex-col border-t-[1px] border-ch-grey-50">
            <div class="font-body">
                <a class="px-8 py-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/list/playlist" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">playlist_play</div><div routerLinkActive="text-primary">Playlisty</div></a>
                <a class="px-8 py-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/list/piece" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">library_music</div><div routerLinkActive="text-primary">Utwory</div></a>
                <a class="px-8 py-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/list/usergroup" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">group</div><div routerLinkActive="text-primary">Grupy</div></a>
                <a class="px-8 py-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/list/sharing" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">share</div><div routerLinkActive="text-primary">Udostępnienia</div></a>
            </div>
            <div class="font-body">
                <a class="px-8 py-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/excercises/intervalrecognition" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">headphones</div><div routerLinkActive="text-primary">Rozpoznawanie interwałów</div></a>
                <a class="px-8 py-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/excercises/rythmicdictation" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="!text-primary">music_note</div><div routerLinkActive="text-primary">Dyktando rytmiczne</div></a>
            </div>
            <div class="font-body">
                <a class="px-8 py-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/subscription" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="text-primary">payments</div><div routerLinkActive="!text-primary">Płatności</div></a>
                <a class="px-8 py-4 w-full flex gap-4 hover:bg-ch-blue-50" routerLink="/settings" routerLinkActive="bg-ch-blue-50 font-boldbody"><div class="icon icon-24 text-ch-blue-100" routerLinkActive="text-primary">settings</div><div routerLinkActive="!text-primary">Ustawienia</div></a>
            </div>

        </div>
        <div class="px-6"><div class="ch-btn ch-btn-outline ch-btn-pri !w-full" (click)="logout()">Wyloguj się</div></div>
    </div>
</div>