<!-- MENU -->
<div id="main-header" class="fixed w-full top-0 z-[500] bg-white" [ngClass]="scrollTop ? 'shadow-none' : 'shadow-lg'">
  <div class="mx-auto flex h-20 py-4 justify-center items-center lg:px-24 md:px-10 px-4" >
      <a class="flex-none w-[141px]" routerLink="/">
          <img class="w-[141px]" src="/assets/images/choiry/logo-choiry-secondary.svg"/>
      </a>
      <div class="flex-1"></div>
      <div class="flex gap-4">
        <!-- NOT LOGGED -->
        <div *ngIf="!userAccount" class="hidden md:flex flex-none gap-4 justify-center items-center">
            <a class="ch-btn ch-btn-outline ch-btn-sec" (click)="login()">Zaloguj się</a>
            <a class="ch-btn ch-btn-filled ch-btn-sec" [routerLink]="['/register']">ZAREJESTRUJ SIĘ</a>
        </div>
        <div *ngIf="!userAccount" class="flex-none w-12 h-12 cursor-pointer md:hidden">
            <span class="icon-48 icon-btn" (click)="openModal('mobilemenu')">menu</span>
        </div>
        <!-- LOGGED IN -->
        <div *ngIf="userAccount">
          <app-menunotification></app-menunotification>
        </div>
        <div *ngIf="userAccount">
          <app-menuuseraccount></app-menuuseraccount>
        </div>
      </div>
  </div>
</div>
<mobilemenu-modal id="mobilemenu" ></mobilemenu-modal>