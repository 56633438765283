import { Component, OnInit } from '@angular/core';
import { AlertService, ValidationService } from '@app/_services';
import { Account, LoginForm } from '../_models';
import { AccountService, AuthServerService } from '../_services';
import { environment } from '@environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
    userAccount: Account;
    lf: LoginForm;
  
    constructor(
        private alertService: AlertService,
        private accountService: AccountService,
        public validationService: ValidationService,
        private authServerService: AuthServerService,
        private router: Router) { 
          this.accountService.account.subscribe(x => this.userAccount = x);
          this.lf = new LoginForm;
          this.lf.clientId = "choiry-portal";
          this.lf.redirectUri = environment.domain+'redirect/native';
          this.lf.responseType = 'code';
          this.lf.scope = 'profile';
      }

    ngOnInit() {
      if(this.userAccount)
        this.router.navigate(['/']);
    }

    authorize(){
        if(localStorage.getItem("loginState") == null )
          this.accountService.registerLoginState()
        this.lf.state = localStorage.getItem("loginState");
        this.lf.email = (<HTMLInputElement>document.getElementById("login-email-input")).value;
        this.lf.password = (<HTMLInputElement>document.getElementById("login-password-input")).value;
        this.authServerService.reqAuthorize(this.lf).subscribe(
          response => { location.href=response.redirect;},
          error => { console.log(error); this.alertService.error("Logowanie nie powiodło się. Sprawdź wprowadzony email i hasło.",{keepAfterRouteChange:false}); }
        );
    }

    login(idp : string){
      if(localStorage.getItem("loginState") == null )
        this.accountService.registerLoginState()
      this.authServerService.reqIdpLogin(idp).subscribe(response => { 
        const parser = new URL(response.redirect);
        parser.searchParams.set("state", localStorage.getItem("loginState"));
        location.href=parser.href; });
    }
}