<div *ngIf="cantLoad">
<h1>Zasób do którego się odwołujesz nie istnieje lub nie masz uprawnień by go załadować.</h1>
</div>

<div *ngIf="piece">
<div (mousemove)="trimDragTrackUpdate($event)" (mouseup)="trimDragTrackMouseUp($event)">
  <!--HEADER CONTROLLER-->
  <div class="bg-primary text-white p-2 rounded">
    <!--DESKTOP/NORMAL SCREEN-->
    <div class="lg:flex h-16 items-center hidden">
      <div class="flex-none w-56">
        <span *ngIf="!isPlaying()"(click)="play()" class="icon-btn icon-48" >play_circle</span>
        <span *ngIf="isPlaying()" (click)="pause()" class="icon-btn icon-48">pause</span>
        <span (click)="replay()" class="icon-btn icon-48">skip_previous</span>
        <span [ngClass]="isLoopToggled ? 'icon-btn-toggled' : ''" (click)="isLoopToggled = !isLoopToggled; rescheduleTransport();" class="icon-btn icon-48" >repeat</span>
      </div>
      <div class="flex-1">
        <div [hidden]="titleEditing" class="cursor-pointer" (click)="changePieceTitle('piece-title-input')"><h3 class="p-0">{{piece.title}}</h3></div>
        <div [hidden]="!titleEditing" class="relative">
          <input class="choiry-input mx-4" type="text" (input)="validationService.updateName('piece-title-input');" (blur)="changePieceTitle('piece-title-input')" (keyup.enter)="blurInput($event)" id="piece-title-input" value="{{piece.title}}"/>
          <div class="choiry-input-feedback" id="validation-feedback-piece-title-input"></div>
        </div>
      </div>
      <div class="flex-none flex items-center relative">
        <span (click)="showNotes = !showNotes" class="icon-btn icon-48" [innerHTML]="showNotes?'arrow_drop_up':'arrow_drop_down'"></span>
        <div class="w-16">
          <div [hidden]="metronomeEditing" class="text-right font-mono" [ngClass]="isEditor ? 'cursor-pointer' : ''" (click)="changeBPM('metronome-tempo')">{{piece.tempo}}BPM</div>
          <div [hidden]="!metronomeEditing"><input type="text" class="choiry-input" style="width: 40px;" (input)="validationService.updateBPM('metronome-tempo');" (blur)="changeBPM('metronome-tempo')" (keyup.enter)="blurInput($event)" id="metronome-tempo" value="{{piece.tempo}}"/></div>
        </div>
        <div class="choiry-input-feedback" id="validation-feedback-metronome-tempo"></div>
        <span (click)="piece.metronome = !piece.metronome; changeMetronome();" [ngClass]="piece.metronome ? 'icon-btn-toggled' : ''" class="icon-btn icon-48">timer</span>
        <span *ngIf="isEditor" (click)="openSharingModal('piece-sharing');" class="icon-btn icon-48">share</span>
      </div>
      <div class="flex-none w-12 h-12">
        <avatar type="user-avatar" [avatarUrl]="piece.owner.avatarUrl" [userId]="piece.ownerId" [firstName]="piece.owner.firstName" [lastName]="piece.owner.lastName" [showCreationTooltip]="true" [tooltipDate]="piece.creationDate"></avatar>
      </div>
    </div>
    <!--MOBILE SMALL SCREEN-->
    <div class="flex lg:hidden items-center">
      <div class="flex-1" >
        <div class="flex">
          <div class="flex-none w-32">
            <span *ngIf="!isPlaying()"(click)="play()" class="icon-btn icon-24" >play_arrow</span>
            <span *ngIf="isPlaying()" (click)="pause()" class="icon-btn icon-24">pause</span>
            <span (click)="replay()" class="icon-btn icon-24">skip_previous</span>
            <span [ngClass]="isLoopToggled ? 'icon-btn-toggled' : ''" (click)="isLoopToggled = !isLoopToggled; rescheduleTransport();" class="icon-btn icon-24" >repeat</span>
          </div>
          <div class="flex-1"></div>
          <div class="flex-none flex w-24 items-center relative">
            <div  class="inline-block w-10">
              <div [hidden]="metronomeEditing" class="text-right font-label" [ngClass]="isEditor ? 'cursor-pointer' : ''" (click)="changeBPM('metronome-tempo-small')">{{piece.tempo}}BPM</div>
              <div [hidden]="!metronomeEditing"><input class="choiry-input" style="width: 40px;" type="text" (input)="validationService.updateBPM('metronome-tempo-small');" (blur)="changeBPM('metronome-tempo-small')" (keyup.enter)="blurInput($event)" id="metronome-tempo-small" value="{{piece.tempo}}"/></div>
            </div>

            <div class="choiry-input-feedback" id="validation-feedback-metronome-tempo-small"></div>
            <span (click)="piece.metronome = !piece.metronome; changeMetronome();" [ngClass]="piece.metronome ? 'icon-btn-toggled' : ''" class="icon-btn icon-24">timer</span>
            <span *ngIf="isEditor" [ngClass]="isSubscriptionActive ? '' : 'icon-btn-disabled mx-2'" (click)="openSharingModal('piece-sharing');" class="icon-btn icon-24">share</span>
          </div>
        </div>
      </div>
      <div class="flex-none w-9 h-9">
        <avatar type="user-avatar" [avatarUrl]="piece.owner.avatarUrl" [userId]="piece.ownerId" [firstName]="piece.owner.firstName" [lastName]="piece.owner.lastName" [showCreationTooltip]="true" [tooltipDate]="piece.creationDate" [size]="'small'"></avatar>
      </div>
    </div>

    <div class="w-full h-16 lg:hidden">
        <span [hidden]="titleEditing"><h5 class="cursor-pointer inline-block" (click)="changePieceTitle('piece-title-input-small')">{{piece.title}}</h5><span (click)="showNotes = !showNotes" class="icon-btn icon-24" [innerHTML]="showNotes?'arrow_drop_up':'arrow_drop_down'"></span></span>
        <div [hidden]="!titleEditing" class="relative">
          <input class="choiry-input w-full" type="text" (input)="validationService.updateName('piece-title-input-small')" (blur)="changePieceTitle('piece-title-input-small')" (keyup.enter)="blurInput($event)" id="piece-title-input-small" value="{{piece.title}}"/>
          <div class="choiry-input-feedback" id="validation-feedback-piece-title-input-small"></div>
        </div>
    </div>
    <!--UNIVERSAL SCREEN-->
    <div *ngIf="showNotes" class="p-2">
      <div [hidden]="notesEditing" class="min-h-[8em] font-mono whitespace-pre-wrap"[ngClass]="isEditor ? 'cursor-pointer' : ''" (click)="changePieceNotes()">{{piece.notes || 'There are no notes.'}}</div>
      <div [hidden]="!notesEditing"><textarea class="w-full h-64 bg-ch-blue-50 text-ch-grey-900 p-4 rounded-lg" (blur)="changePieceNotes()" (keyup.enter)="blurInput($event)" id="piece-notes-input">{{piece.notes || ''}}</textarea></div>
    </div>
    <div *ngIf="!showNotes && piece.notes != null && piece.notes.length>0" class="cursor-pointer">
      <div (click)="showNotes = !showNotes" class="font-mono">{{ (piece.notes.length>16)? (piece.notes | slice:0:16)+'...':(piece.notes) }}</div>
    </div>
  </div>
  <!--TRACK CONTROLL-->
  <div class="piece-editor-container flex">
    <div class="piece-editor-controls flex-none">
      <div class="w-full h-8 bg-ch-grey-900"></div>
      <div *ngFor="let track of sortedTracks()" class="h-24 relative">
        <div *ngIf="!track.loadingAudio" class="track-control px-1 py-2">
          <div [ngStyle]="{'width': track.meterValue + '%'}" class="bg-secondary h-1 absolute top-0 left-0"></div>
          <div class="flex" >
            <div class="flex-none w-14 h-8">
              <span (click)="track.channel.solo = !track.channel.solo; updateTrack(track);" [ngClass]="track.channel.solo ? 'text-green-700' : ''" class="icon-24 icon-btn">person</span>
              <span (click)="track.channel.mute = !track.channel.mute; updateTrack(track);" [ngClass]="track.channel.mute ? 'text-red-700' : ''" class="icon-24 icon-btn"[innerHTML]="track.channel.mute?'volume_off':'volume_up'"></span>
            </div>
            <div [hidden]="track.titleEditing" [ngClass]="isEditor ? 'cursor-pointer' : ''" (click)="changeTrackTitle(track)" class="flex-1 text-center"><b>{{track.name}}</b></div>
            <div [hidden]="!track.titleEditing" class="flex-1">
              <input class="choiry-input" (input)="validationService.updateTrackLabel('track-title-input-'+track.id)" type="text" (blur)="changeTrackTitle(track)" (keyup.enter)="blurInput($event)" id="track-title-input-{{track.id}}" value="{{track.name}}" style="width: 128px;"/>
            </div>
            <div class="w3-text w3-tag w3-round-large" id="validation-feedback-track-title-input-{{track.id}}" style="position:absolute; left: 0px; top: 50px; z-index: 3"></div>
          </div>
          <div class="flex">
            <div class="flex-none h-8">
              <span *ngIf="isEditor" (click)="deleteTrack(track)" class="icon-btn icon-24 text-red-700">delete</span>
              <span *ngIf="isEditor" (click)="downloadTrackFile(track)" class="icon-btn icon-24">download</span>
              <span *ngIf="isEditor" (click)="moveTrackUp(track)" class="icon-btn icon-24">keyboard_arrow_up</span>
              <span *ngIf="isEditor" (click)="moveTrackDown(track)" class="icon-btn icon-24">keyboard_arrow_down</span>
            </div>
          </div>
          <input class="w-full vol-slider" type="range" #volumeselector (input)="updateTrackVolume(track,volumeselector.value);" (change)="submitTrackVolume(track,volumeselector.value)" min="-48" max="12" value="{{track.volumeDb}}">
        </div>
        <div *ngIf="track.loadingAudio" class="track-loading text-center"><span class="py-4 icon-48 animate-spin">progress_activity</span></div>
      </div>
      <div *ngFor="let trackUpload of trackUploads"></div>
      <div *ngIf="isEditor" class="track-control p-1 relative">
        <div *ngIf="isEditor" [ngStyle]="{'width': micMeterValue + '%'}" class="bg-red-700 h-1 absolute top-0 left-0"></div>
        <label for="fileUpload"><div class="track-control-btn-half font-boldbody">Upload Track <span class="icon-24 my-2">cloud_upload</span></div></label>
        <!--[disabled]="trackUpload.inProgress"--><input class="hidden" id="fileUpload" type="file" (change)="uploadTrackFile($event)">
        <div *ngIf="isRecording" class="text-red-700 track-control-btn-half font-boldbody" (click)="recordTrack();">Stop Recording <span class="icon-24 blink my-2">stop_circle</span></div>
        <div *ngIf="!isRecording" class="track-control-btn-half font-boldbody" (click)="recordTrack();">Record Track <span class="icon-24 my-2">mic</span></div>
      </div>
    </div>
    <!--TIMELINE-->
    <div id="piece-editor-timeline-window" class="flex-1 relative">
      <div id="piece-editor-timeline-scroll" (wheel)="onScroll($event)" (resize)="updateGrid()">
        <canvas id="piece-editor-timeline-scroll-grid" [ngStyle]="{'display': piece.metronome ? 'block' : 'none'}" ></canvas>
        <canvas id="piece-editor-timeline-scroll-head"></canvas>
        <div id="piece-editor-ruller" class="clickable" (mousemove)="seek($event)" (mousedown)="seek($event)"></div>
        <div *ngFor="let track of sortedTracks()" class="w-24">
          <div *ngIf="!track.loadingAudio" class="w-full">
            <div class="track-timeline">
              <div id="track-waveform-{{track.id}}" class="track-waveform">
                <div class="track-waveform-left-trim" (mousedown)="trimTrackLeft($event,track);"></div>
                <div class="track-waveform-content" (mousedown)="dragTrack($event,track);" id="track-waveform-content-{{track.id}}" ></div>
                <div class="track-waveform-right-trim" (mousedown)="trimTrackRight($event,track);"></div>
              </div>
            </div>
          </div>
          <div *ngIf="track.loadingAudio" class="track-loading text-center"><span class="py-4 icon-48 animate-spin">progress_activity</span></div>
        </div>
        <div *ngIf="isEditor">
          <div *ngFor="let trackUpload of trackUploads" class="track-timeline">
            <div *ngIf="trackUpload.done || trackUpload.inProgress"><b>{{ trackUpload.file.name }} - {{ fileUploadService.humanFileSize(trackUpload.file.size) }}</b></div>
            <div *ngIf="trackUpload.inProgress" class="bg-blue-200" style="width: 500px;"><div class="bg-blue-700 h-8" [ngStyle]="{'width': trackUpload.progress + '%'}"></div></div>
          </div>
        </div>
        <div class="w-full">
          <div class="track-timeline"></div>
        </div>
      </div>
      <div class="absolute bottom-0 right-0 h-12 bg-gray-600 bg-opacity-40 rounded-lg p-1 text-white text-sm">Ctrl + kółko aby przybliżyć<br>Shift + kółko aby przewinąć</div>
    </div>
  </div>
</div>
<div class="flex w-full items-center gap-1 py-2"><div class="flex-1"></div><div class="flex-none icon-24">visibility</div><div class="flex-none font-body">{{piece.views}}</div></div>
<!--SCORE-->
<div class="relative">
  <div *ngIf="piece.haveScore && piece.urlScore && !hideScore" class="w-full mb-24">
    <div *ngIf="currentScorePage>1" id="prev-score-page-btn" (click)="currentScorePage = currentScorePage - 1"><span class="icon-btn icon-48 valign">navigate_before</span></div>
    <div *ngIf="currentScorePage<scorePages" id="next-score-page-btn" (click)="currentScorePage = currentScorePage + 1"><span class="icon-btn icon-48 valign">navigate_next</span></div>
    <div class="w-full max-w-5xl mx-auto my-4 p-0 min-h-[256px] md:min-h-[512px] lg:min-h-[768px]" >
        <pdf-viewer 
        [src]="piece.urlScore" 
        [page]="currentScorePage" 
        [original-size]="false" 
        [render-text]='false' 
        [fit-to-page]='true'
        [show-all]="false" 
        [external-link-target]="'none'" 
        [autoresize]="true" 
        (after-load-complete)="setScorePageNumber($event);" 
        style="display: block;">
      </pdf-viewer>
    </div>
    <div class="flex justify-center items-center"><div *ngIf="isEditor" class="flex-none ch-btn ch-btn-outline ch-btn-sec text-center" (click)="deleteScore();">Usuń nuty <span class="icon-24">delete</span></div></div>
  </div>
  <div *ngIf="scoreUpload.done || scoreUpload.inProgress"><b>{{ scoreUpload.file.name }} - {{ fileUploadService.humanFileSize(scoreUpload.file.size) }}</b></div>
  <div *ngIf="scoreUpload.inProgress" class="bg-secondary w-full" ><div class="bg-primary h-8" [ngStyle]="{'width': scoreUpload.progress + '%'}"></div></div>
  <div class="flex justify-center items-center"><div *ngIf="!piece.haveScore"><label for="scoreUpload"><div *ngIf="isEditor" class="ch-btn ch-btn-filled ch-btn-sec flex-none text-center" >Prześlij nuty <span class="icon-24" >cloud_upload</span></div></label><input class="hidden" id="scoreUpload" type="file" (change)="uploadScoreFile($event)"></div></div>
</div>

<div *ngIf="userAccount && !hideComments">
  <app-commentblock [commentBlockId]="piece.commentBlockId"></app-commentblock>
</div>
<sharing-modal *ngIf="isEditor" id="piece-sharing" objectId="{{piece.id}}" type="piece" title="Udostępnij utwór" [isPublic]="piece.sharePublicEnabled" (onPublicShare)="piece.sharePublicEnabled = $event; updatePiece();" ></sharing-modal>
</div>