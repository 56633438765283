import { Injectable, OnInit } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, first, mergeMap, switchMap, take } from 'rxjs/operators';
import { AccountService } from '@app/authserver/_services';
import { Account } from '@app/authserver/_models/account';
import { environment } from '@environments/environment';

@Injectable()
export class CustomInterceptor implements HttpInterceptor {
    currentUser: Account;
    isRefreshing: boolean;
    private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    
    constructor(private accountService: AccountService) {
          accountService.account.subscribe(a => {this.currentUser=a;});
         }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let tokens;
        if(localStorage.getItem('tokens')!= null){
            tokens = JSON.parse(localStorage.getItem('tokens'));
        }
        const isLoggedIn = tokens != undefined && tokens != null && tokens.access_token != null;
        const isApiUrl = (request.url.startsWith(environment.apiUrl) || request.url.startsWith(environment.authUrl))
        && !request.url.includes("oauth2/refresh");

        if(isLoggedIn && isApiUrl)
            request = request.clone({setHeaders: { Authorization: `Bearer ${tokens.access_token}` } });

        return next.handle(request).pipe(
            catchError((error) => {
                if ( error instanceof HttpErrorResponse && isLoggedIn && isApiUrl &&error.status === 401 ) { 
                    return this.handle401Error(request, next); 
                }
        
                return throwError(() => error);
            })
        );

    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          this.refreshTokenSubject.next(null)
    
        return this.accountService.refresh().pipe(
            switchMap(() => {
                this.isRefreshing = false;
                //update header
                if(localStorage.getItem('tokens')!= null){
                    let tokens = JSON.parse(localStorage.getItem('tokens'));
                    this.refreshTokenSubject.next(tokens.access_token)
                    request = request.clone({setHeaders: { Authorization: `Bearer ${tokens.access_token}` } });
                }
                return next.handle(request);
            }),
            catchError((error) => {
                this.isRefreshing = false;
                if (error.status == '403') {
                    this.accountService.logout();
                }

                return throwError(() => error);
            })
        );
        }
        
        return this.refreshTokenSubject.pipe(
            filter(token => token !== null),
            take(1),
            switchMap((token) => next.handle(request.clone({setHeaders: { Authorization: `Bearer ${token}` } })))
          );
      }

}