<div class="flex gap-6">
  <app-menu *ngIf="userAccount"></app-menu>
    <div class="flex-1 overflow-hidden">
      <div class="bg-white pb-[56px] flex flex-col rounded-b-xl">
        <div class="h-16 bg-ch-yellow-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
          <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1"><div class="icon icon-24">arrow_left_alt</div></div>
          <div class="font-boldbody">Rozpoznawanie interwałów {{selectedLesson ? selectedLesson.lessonName: ''}}</div>
          <div class="min-w-8 h-8 flex">
            <div *ngIf="fsm=='ready'" class="text-primary icon-btn icon-32" (click)="start()">play_arrow</div>    
            <div *ngIf="fsm=='answer'" class="text-primary icon-btn icon-32" (click)="playInterval()">repeat</div>    
            <div *ngIf="fsm=='answer'" class="text-primary icon-btn icon-32" (click)="stop()">stop</div>    
          </div>
        </div>
        <div class="flex">
          <div class="w-72 bg-ch-blue-50">
            <div class="w-full flex">
              <div class="choiry-v-radio-l flex-1" [ngClass]="lessonTypeFilter=='melodic' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="updateLessonFilter('melodic');">Melodyczne</div>
              <div class="choiry-v-radio-r flex-1" [ngClass]="lessonTypeFilter=='harmonic' ? 'choiry-v-radio-active' : 'choiry-v-radio'" (click)="updateLessonFilter('harmonic');">Harmoniczne</div>
            </div>
            <ng-container *ngFor="let lesson of lessonsFiltered">
              <div [ngClass]="lesson==selectedLesson ? 'font-boldbody bg-ch-blue-100' : ''" class="flex w-full items-center pl-4 pr-2 text-small py-1 gap-1 cursor-pointer hover:bg-ch-yellow-50" 
              (click)="selectLesson(lesson)">
                <div class="flex-1">{{lesson.lessonName}}</div>
                <div *ngIf="userAccount" class="font-label">{{lesson.bestScore || 'X'}} / 100</div>
                <div *ngIf="lesson.difficulty==1" class="icon-24">signal_cellular_alt_1_bar</div>
                <div *ngIf="lesson.difficulty==2" class="icon-24">signal_cellular_alt_2_bar</div>
                <div *ngIf="lesson.difficulty==3" class="icon-24">signal_cellular_alt</div>
              </div>
            </ng-container>
            <div [ngClass]="selectedLesson==null ? 'font-boldbody bg-ch-blue-100' : ''" class="flex w-full items-center text-small py-1 gap-1 cursor-pointer hover:bg-ch-yellow-50 pl-4 pr-2" 
            (click)="selectLesson(null)">
              <div class="flex-1">Lekcja Własna</div>
            </div>
          </div>
          <div class="flex-1">
            <div class="text-lg w-full text-center my-8">Poprawne odpowiedzi: {{correctAnswers}}</div>
            <div class="w-80 h-32 my-8 mx-auto"><score-render #score [width]="320" [drawGrid]="true"></score-render></div>
            <div class="w-full h-16 text-center text-2xl">
              <div *ngIf="fsm=='feedback-right'" class="text-success-content font-bold">Poprawnie</div>
              <div *ngIf="fsm=='feedback-wrong'" class="text-error-content font-bold">Źle</div>
              <div *ngIf="fsm=='ready' && selectedLesson != null" class="w-full">Wybierz lekcję i kliknij play</div>
              <div *ngIf="fsm=='ready' && selectedLesson == null" class="w-full">Wybierz interwały które chcesz rozpoznawać i kliknij play</div>
              <div *ngIf="fsm=='answer'" class="w-full">Kliknij na interwał który usłyszałeś</div>
              <div *ngIf="fsm=='lesson-result'" class="w-full">Twój wynik to {{correctAnswers}}</div>
            </div>
            <div class="grid grid-cols-8 justify-items-center w-full my-8">
              <div>
                <div class="interval-btn" id="interval-1" (click)="intervalClick(1)">2m</div>
                <div class="interval-btn" id="interval-2" (click)="intervalClick(2)">2M</div>
              </div>
              <div>
                <div class="interval-btn" id="interval-3" (click)="intervalClick(3)">3m</div>
                <div class="interval-btn" id="interval-4" (click)="intervalClick(4)">3M</div>
              </div>
              <div>
                <div class="interval-btn my-8" id="interval-5" (click)="intervalClick(5)">4</div>
              </div>
              <div>
                <div class="interval-btn my-8" id="interval-6" (click)="intervalClick(6)">Tri</div>
              </div>
              <div>
                <div class="interval-btn my-8" id="interval-7" (click)="intervalClick(7)">5</div>
              </div>
              <div>
                <div class="interval-btn" id="interval-8" (click)="intervalClick(8)">6m</div>
                <div class="interval-btn" id="interval-9" (click)="intervalClick(9)">6M</div>
              </div>
              <div>
                <div class="interval-btn" id="interval-10" (click)="intervalClick(10)">7m</div>
                <div class="interval-btn" id="interval-11" (click)="intervalClick(11)">7M</div>
              </div>
              <div>
                <div class="interval-btn my-8" id="interval-12" (click)="intervalClick(12)">8</div>
              </div>
            </div>
            <div class="px-8"><ch-progress [percent]="lessonProgress" [description]="'Pytanie '+lessonProgress+ ' z '+lessonLength" class="w-full"></ch-progress></div>
          </div>
      </div>
    </div>
  </div>
</div>