<div class="flex gap-6">
  <app-menu *ngIf="userAccount"></app-menu>
    <div class="flex-1 overflow-hidden">
      <div class="bg-white pb-[56px] flex flex-col rounded-b-xl">
        <div class="h-16 bg-ch-yellow-50 rounded-t-xl w-full flex py-4 px-8 items-center justify-between">
          <div class="rounded-full w-8 h-8 border-ch-grey-700 border-[1px] p-1"><div class="icon icon-24">arrow_left_alt</div></div>
          <div class="font-boldbody">Czytanie nut {{selectedLesson ? selectedLesson.lessonName: ''}}</div>
          <div class="min-w-8 h-8 flex">
            <div *ngIf="fsm=='ready'" class="text-primary icon-btn icon-32" (click)="start()">play_arrow</div>    
            <div *ngIf="fsm=='show-task'" class="text-primary icon-btn icon-32" (click)="stop()">stop</div>    
          </div>
        </div>
        <div class="flex">
          <div class="w-72 bg-ch-blue-50">
            <ng-container *ngFor="let lesson of lessons">
              <div [ngClass]="lesson==selectedLesson ? 'font-boldbody bg-ch-blue-100' : ''" class="flex w-full items-center pl-4 pr-2 text-small py-1 gap-1 cursor-pointer hover:bg-ch-yellow-50" 
              (click)="selectLesson(lesson)">
                <div class="flex-1">{{lesson.lessonName}}</div>
                <div *ngIf="userAccount" class="font-label">{{lesson.bestScore || 'X'}} / 100</div>
                <div *ngIf="lesson.difficulty==1" class="icon-24">signal_cellular_alt_1_bar</div>
                <div *ngIf="lesson.difficulty==2" class="icon-24">signal_cellular_alt_2_bar</div>
                <div *ngIf="lesson.difficulty==3" class="icon-24">signal_cellular_alt</div>
              </div>
            </ng-container>
          </div>
          <div class="flex-1">
            <div class="text-lg w-full text-center my-8">Poprawne odpowiedzi: {{correctAnswers}}</div>
            <div class="w-80 h-32 my-8 mx-auto"><score-render #score [width]="320" [drawGrid]="true"></score-render></div>
            <div class="w-full h-16 text-center text-2xl">
              <div *ngIf="fsm=='feedback-right'" class="text-success-content font-bold">Poprawnie</div>
              <div *ngIf="fsm=='feedback-wrong'" class="text-error-content font-bold">Źle</div>
              <div *ngIf="fsm=='ready'" class="w-full">Wybierz lekcję i kliknij play</div>
              <div *ngIf="fsm=='answer'" class="w-full">Kliknij na nazwę dźwięku</div>
              <div *ngIf="fsm=='lesson-result'" class="w-full">Twój wynik to {{correctAnswers}}</div>
            </div>
            <div *ngIf="lessonMode=='notes'" class="my-8 h-40 flex justify-center">
                <div class="h-full relative w-16 border rounded-b-md border-ch-grey-900 bg-ch-blue-100 hover:bg-blue-200 cursor-pointer" (click)="noteClick(60)">
                  <div class="absolute bottom-0 w-16 h-12 text-center font-title noselect">C</div>
                </div>
                <div class="h-full relative w-0">
                  <div class="absolute w-12 h-[50%] top-0 left-[-32px] bg-ch-grey-900 rounded-b-lg hover:bg-gray-800 cursor-pointer z-[10]" (click)="noteClick(61)">
                    <div class="absolute bottom-0 w-12 h-12 text-center font-label text-white noselect">cis/des</div>
                  </div>
                </div>
                <div class="h-full relative w-16 border rounded-b-md border-ch-grey-900 bg-ch-blue-100 hover:bg-blue-200 cursor-pointer" (click)="noteClick(62)">
                  <div class="absolute bottom-0 w-16 h-12 text-center font-title noselect">D</div>
                </div>
                <div class="h-full relative w-0">
                  <div class="absolute w-12 h-[50%] top-0 left-[-16px] bg-ch-grey-900 rounded-b-lg hover:bg-gray-800 cursor-pointer z-[10]" (click)="noteClick(63)">
                    <div class="absolute bottom-0 w-12 h-12 text-center font-label text-white noselect">dis/es</div>
                  </div>
                </div>
                <div class="h-full relative w-16 border rounded-b-md border-ch-grey-900 bg-ch-blue-100 hover:bg-blue-200 cursor-pointer" (click)="noteClick(64)">
                  <div class="absolute bottom-0 w-16 h-12 text-center font-title noselect">E</div>
                </div>
                <div class="h-full relative w-16 border rounded-b-md border-ch-grey-900 bg-ch-blue-100 hover:bg-blue-200 cursor-pointer" (click)="noteClick(65)">
                  <div class="absolute bottom-0 w-16 h-12 text-center font-title noselect">F</div>
                </div>
                <div class="h-full relative w-0">
                  <div class="absolute w-12 h-[50%] top-0 left-[-32px] bg-ch-grey-900 rounded-b-lg hover:bg-gray-800 cursor-pointer z-[10]" (click)="noteClick(66)">
                    <div class="absolute bottom-0 w-12 h-12 text-center font-label text-white noselect">fig/ges</div>
                  </div>
                </div>
                <div class="h-full relative w-16 border rounded-b-md border-ch-grey-900 bg-ch-blue-100 hover:bg-blue-200 cursor-pointer" (click)="noteClick(67)">
                  <div class="absolute bottom-0 w-16 h-12 text-center font-title noselect">G</div>
                </div>
                <div class="h-full relative w-0">
                  <div class="absolute w-12 h-[50%] top-0 left-[-24px] bg-ch-grey-900 rounded-b-lg hover:bg-gray-800 cursor-pointer z-[10]" (click)="noteClick(68)">
                    <div class="absolute bottom-0 w-12 h-12 text-center font-label text-white noselect">gis/as</div>
                  </div>
                </div>
                <div class="h-full relative w-16 border rounded-b-md border-ch-grey-900 bg-ch-blue-100 hover:bg-blue-200 cursor-pointer" (click)="noteClick(69)">
                  <div class="absolute bottom-0 w-16 h-12 text-center font-title noselect">A</div>
                </div>
                <div class="h-full relative w-0">
                  <div class="absolute w-12 h-[50%] top-0 left-[-16px] bg-ch-grey-900 rounded-b-lg hover:bg-gray-800 cursor-pointer z-[10]" (click)="noteClick(70)">
                    <div class="absolute bottom-0 w-12 h-12 text-center font-label text-white noselect">ais/b</div>
                  </div>
                </div>
                <div class="h-full relative w-16 border rounded-b-md border-ch-grey-900 bg-ch-blue-100 hover:bg-blue-200 cursor-pointer" (click)="noteClick(71)">
                  <div class="absolute bottom-0 w-16 h-12 text-center font-title noselect">H</div>
                </div>
            </div>
            <div *ngIf="lessonMode=='keys'" class="grid grid-cols-8 justify-items-center w-full my-8">
              <div><div class="answer-btn" (click)="keyClick(0)">C/a</div></div>
              <div><div class="answer-btn" (click)="keyClick(1)">G/e</div></div>
              <div><div class="answer-btn" (click)="keyClick(2)">D/h</div></div>
              <div><div class="answer-btn" (click)="keyClick(3)">A/fis</div></div>
              <div><div class="answer-btn" (click)="keyClick(4)">E/cis</div></div>
              <div><div class="answer-btn" (click)="keyClick(5)">H/gis</div></div>
              <div><div class="answer-btn" (click)="keyClick(6)">Ges/es<br>Fis/dis</div></div>
              <div><div class="answer-btn" (click)="keyClick(7)">Des/b</div></div>
              <div><div class="answer-btn" (click)="keyClick(8)">As/f</div></div>
              <div><div class="answer-btn" (click)="keyClick(9)">Es/c</div></div>
              <div><div class="answer-btn" (click)="keyClick(0)">B/g</div></div>
              <div><div class="answer-btn" (click)="keyClick(1)">F/d</div></div>
            </div>
            <div class="px-8"><ch-progress [percent]="lessonProgress" [description]="'Pytanie '+lessonProgress+ ' z '+lessonLength" class="w-full"></ch-progress></div>
          </div>
      </div>
    </div>
  </div>
</div>