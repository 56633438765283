import { User } from './user';

export class Comment{
    id: number;
    commentBlockId: number;
    authorId: string;
    creationDate: number;
    content: string;
    responseToCommentId: number;
    author: User;
    timestr: string;
}