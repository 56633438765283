import { CommonModule } from '@angular/common';
import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService, AuthServerService } from '@app/authserver/_services';


@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'app-callback',
  templateUrl: './redirect.component.html'
})
export class RedirectComponent implements OnInit{
    public errorMessage: string = "";
    idp: string = "";
    code: string = "";
    state: string = "";
    constructor(private route: ActivatedRoute, private authServerService: AuthServerService, private accountService: AccountService){}

    ngOnInit(){
        this.route.params.subscribe(params => {
            this.idp = params['idp'];
            if(this.code.length>0)
                this.accountService.session(this.code,this.idp,this.state)
          });
        this.route.queryParams.subscribe(params => {
            if(params.error && params.error != "")
                this.errorMessage=params.error;
            else {
                this.code = params.code;
                this.state = params.state;
                if(this.idp.length>0)
                    this.accountService.session(this.code,this.idp,this.state)
            }
        });
    }

}
